import { Field } from "components/form/Field";
import { Fieldset, FieldsetContainer } from "components/form/Form";
import { EditComponentProps } from "../ItemEditModal";

export function VDSMaterialEdit({ item }: EditComponentProps<"vds-material">) {
  return (
    <>
      <FieldsetContainer>
        <Fieldset>
          {!item && (
            <Field
              area="vds-material"
              prop="ItemID"
              value=""
              title="ID"
              type="text"
              style={{ width: 120 }}
              disabled={!!item}
            />
          )}
          <Field
            area="vds-material"
            prop="Description"
            value={item ? item.Description : ""}
            title="Description"
            type="text"
            uppercase
          />
        </Fieldset>
      </FieldsetContainer>
    </>
  );
}
