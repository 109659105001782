import { AdminRoles, UserRoles } from "app/userConfig";
import axios, { AxiosError } from "axios";
import { useQuery } from "react-query";
import { getApiUrl } from "./queryUtil";

export interface UserInfo {
  UserNo: number;
  FullName: string;
  Role: UserRoles;
  AdminRole: AdminRoles;
  DocSpaceAdmin: "Y" | "N";
  PCSAdmin: "Y" | "N";
  UserID: string;
}

type UserCapabilities = {
  Capability: string;
  Role: UserRoles;
  AdminRole: AdminRoles;
  Description: string;
  Comment: string;
};

export interface UserQueryResult {
  getUserCapabilities: UserCapabilities[];
  getUserInfo: UserInfo[];
}

export const noUserInfo: UserInfo = {
  UserNo: 0,
  FullName: "",
  Role: "N",
  AdminRole: "A",
  DocSpaceAdmin: "N",
  PCSAdmin: "N",
  UserID: "",
};

interface UserError {
  exceptionMessage: string;
}

export type Capabilities = string[];

export type UserData = {
  userInfo: UserInfo;
  userCaps: Capabilities;
};

export default function useUser(props?: { disabled?: boolean }) {
  return useQuery<UserData, AxiosError<UserError>>(
    ["codelists", "users", "current"],
    async () => {
      const { data } = await axios.get<UserQueryResult>(getApiUrl("users"));
      return {
        userInfo: data.getUserInfo[0],
        userCaps: data.getUserCapabilities.map((cap) => cap.Capability),
      };
    },
    {
      retry: false,
      enabled: !props || !props.disabled,
    }
  );
}
