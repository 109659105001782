import { SaveVDSSubsegmentProperties } from "../types";
import axios, { AxiosResponse } from "axios";
import { useMutation, useQueryClient } from "react-query";
import { getSheetApiUrl } from "../../../queries/queryUtil";
import { StatusResponse } from "components/StatusModal";
import { SheetStatus } from "features/sheets/config/statusConfig";

type VDSSubsegmentCommands =
  | "properties"
  | "affected-vds"
  | "subsegment-substitution";

export type AffectedVDSByPropertiesChangeItem = {
  VDS: string;
  Revision: string;
  Status: string;
  RevDate: string;
  LastUpdateBy: string;
  ValveTypeID: number;
  RatingClassID: number;
  MaterialGroupID: number;
  EndConnectionID: number;
  VDSSizeID: number;
  BoreID: number;
  SpecialReqID: number;
};

export type SubsegmentSubstitution = {
  SubsegmentID: string;
  Revision: string;
  Status: SheetStatus;
  SubsegmentName: string;
};

type VDSSubsegmentCommandReturn<T extends VDSSubsegmentCommands> =
  T extends "properties"
    ? StatusResponse
    : T extends "affected-vds"
    ? { getVDSAffected: AffectedVDSByPropertiesChangeItem[] }
    : T extends "subsegment-substitution"
    ? { getSubstituteSubsegments: SubsegmentSubstitution[] }
    : never;

export default function useVDSSubsegmentPropertiesCommand<
  T extends VDSSubsegmentCommands
>({ command }: { command: T }) {
  const queryClient = useQueryClient();

  async function vdsSubsegmentPropertiesCommand({
    name,
    revision,
    content,
  }: {
    name: string;
    revision: string;
    content: SaveVDSSubsegmentProperties;
  }): Promise<AxiosResponse<VDSSubsegmentCommandReturn<T>>> {
    return await axios(
      getSheetApiUrl({
        sheetType: "vds-subsegments",
        name,
        revision,
        postfix: command,
      }),
      {
        method: "put",
        data: JSON.stringify(content),
      }
    );
  }

  return useMutation(vdsSubsegmentPropertiesCommand, {
    onSuccess: (data, variables, context) => {
      if (command === "properties") {
        queryClient.invalidateQueries([
          "sheet",
          "vds-subsegments",
          variables.name,
          variables.revision,
        ]);
        queryClient.invalidateQueries(["sheets", "vds-subsegments"]);
      }
    },
    onError: (error, variables, context) => {
      console.error(`error ${context}`, error, variables);
    },
    onSettled: (data, error, variables, context) => {},
  });
}
