import React, { useContext, useState, useLayoutEffect } from "react";
import { useMemoOne as useMemo } from "use-memo-one";
import { ModalWindow } from "components/ModalWindow";
import useIssues, { IssuePipeElementsList } from "queries/useIssues";
import { RevisionMark } from "components/Components";
import { Button } from "@equinor/eds-core-react";
import { IssueSheetTypes } from "../../sheets/config/sheetConfig";
import { useAppSelector } from "app/hooks";
import { selectMainPlant } from "features/plant/plantSlice";
import Table, { ControlHeaderContainer } from "components/table/Table";
import { areArraysOfObjectsIdentical } from "utils/util";
import useConfirm from "components/confirm/useConfirm";
import useIssueSetSheets, {
  issueSetSheetNames,
} from "queries/useIssueSetSheets";
import StatusModal from "components/StatusModal";
import useIssueSetPipeElements from "queries/useIssueSetPipeElements";
import { EditIssueContext } from "../EditIssueContext";
import {
  NativeSelectAutoWidth,
  DeltaIcon,
  IssueSheetRevisionMarkWithPreviewButtons,
  IssueSheetRowMenu,
  isIssueSetSheetType,
  EditIssuePipeElementsContent,
  EditIssueSheetContent,
} from "../EditIssue";
import { defaultUnsavedMessage } from "components/confirm/ConfirmContextModal";

export function useIssueSheets({
  issueSheetType,
  revision,
  disabled,
}: {
  issueSheetType: IssueSheetTypes;
  revision: string;
  disabled: boolean;
}): {
  issueSheetsContent: React.ReactNode;
  checkIfDirty: () => Promise<boolean>;
} {
  const plant = useAppSelector(selectMainPlant);
  const {
    editSheet,
    editSheetOpen,
    setEditSheetOpen,
    itemsClassNames,
    issue,
    isEditable,
  } = useContext(EditIssueContext);
  const { data, status } = useIssues({
    plant,
    request: issueSheetType,
    revision,
    disabled,
  });
  const { isConfirmed } = useConfirm();
  const {
    mutate: issueSetSheets,
    status: issueSetSheetsStatus,
    error: issueSetSheetsError,
    reset: issueSetSheetsReset,
  } = useIssueSetSheets();
  const {
    mutate: issueSetPipeElements,
    status: issueSetPipeElementsStatus,
    error: issueSetPipeElementsError,
    reset: issueSetPipeElementsReset,
  } = useIssueSetPipeElements();

  const itemId =
    issueSheetType === "pipe-elements"
      ? "ElementID"
      : issueSheetType.toUpperCase();

  const itemTitle = issueSheetType === "pipe-elements" ? "ID" : itemId;

  const [groupFilter, setGroupFilter] = useState("");

  // This is the array where values of Status W are modified.
  const [items, setItems] = useState([] as unknown as typeof data);

  useLayoutEffect(() => {
    setItems(data ? JSON.parse(JSON.stringify(data)) : []);
  }, [data]);

  const displayItems = useMemo(() => {
    if (items && issueSheetType === "pipe-elements" && groupFilter) {
      const _items = items as IssuePipeElementsList;
      return _items.filter((e) => e.ElementGroup === groupFilter);
    } else {
      return items ? items : [];
    }
  }, [items, groupFilter, issueSheetType]);

  const controlHeader = useMemo(() => {
    if (items && issueSheetType === "pipe-elements" && status === "success") {
      const _items = items as IssuePipeElementsList;
      return _items.length ? (
        <ControlHeaderContainer>
          <NativeSelectAutoWidth
            id=""
            label="Element Group"
            onChange={(e) => setGroupFilter(e.target.value)}
            value={groupFilter}
          >
            <option value="">- All -</option>
            {_items
              .reduce(
                (a: string[], c) =>
                  a.includes(c.ElementGroup) ? a : a.concat(c.ElementGroup),
                []
              )
              .map((group: string) => (
                <option key={group}>{group}</option>
              ))}
          </NativeSelectAutoWidth>
        </ControlHeaderContainer>
      ) : (
        <></>
      );
    } else {
      return undefined;
    }
  }, [items, issueSheetType, status, groupFilter]);

  return {
    issueSheetsContent: (
      <>
        <Table
          status={status}
          items={displayItems}
          itemsClassNames={itemsClassNames}
          columns={[
            { key: "Delta", title: "", Component: DeltaIcon, width: 28 },
            {
              key: itemId,
              title: itemTitle,
              width:
                issueSheetType === "pipe-elements"
                  ? 40
                  : issueSheetType === "mds"
                  ? 105
                  : 80,
            },
            ...([
              "pcs",
              "sc",
              "vsm",
              "eds",
              "vds",
              "vsk",
              "mds",
              "esk",
            ].includes(issueSheetType)
              ? [
                  {
                    key: "Revision",
                    title: "Rev.",
                    Component: IssueSheetRevisionMarkWithPreviewButtons,
                    type: "with-context" as "with-context",
                    componentProps: {
                      issueSheetType,
                    },
                    width: 100,
                  },
                  { key: "RevDate", title: "Rev. Date", width: 100 },
                  { key: "Status", title: "Status", width: 70 },
                  {
                    key: "OfficialRevision",
                    title: "Official Rev.",
                    Component: IssueSheetRevisionMarkWithPreviewButtons,
                    type: "with-context" as "with-context",
                    componentProps: {
                      issueSheetType,
                      revisionOverride: "OfficialRevision",
                    },
                    width: 100,
                  },
                ]
              : []),
            ...(issueSheetType === "mds"
              ? [
                  {
                    key: "Area",
                    title: "Area",
                    width: 180,
                  },
                ]
              : []),
            ...(issueSheetType === "pipe-elements"
              ? [
                  {
                    key: "Revision",
                    title: "Rev.",
                    Component: RevisionMark,
                    width: 45,
                  },
                  { key: "RevDate", title: "Rev. Date", width: 70 },
                  { key: "Status", title: "Status", width: 60 },
                  {
                    key: "DimensionStandard",
                    title: "Dim. Standard",
                    width: "10%",
                  },
                  { key: "ProductForm", title: "Type", width: "10%" },
                  {
                    key: "MaterialGrade",
                    title: "Material Name",
                    width: "10%",
                  },
                  { key: "MDS", title: "MDS", width: 105 },
                  {
                    key: "MDSRevision",
                    title: "MDS Rev.",
                    Component: IssueSheetRevisionMarkWithPreviewButtons,
                    width: 70,
                    type: "with-context" as "with-context",
                    componentProps: {
                      issueSheetType: "mds",
                    },
                  },
                  { key: "Area", title: "Area", width: 100 },
                ]
              : []),
          ]}
          selectionMode={false}
          itemIdProp={itemId}
          infoline={true}
          totalCount={data && data.length}
          resizable={true}
          sortable={true}
          fillRest={true}
          groupBy={
            issueSheetType === "pipe-elements" ? "ElementGroup" : undefined
          }
          controlHeader={controlHeader}
          RowMenu={IssueSheetRowMenu}
          contextData={{ issueSheetType, revision, itemId, itemTitle }}
          footer={
            <>
              {issue && issue.Status === "W" && isEditable ? (
                isIssueSetSheetType(issueSheetType) ? (
                  <Button
                    onClick={() =>
                      issueSetSheets({
                        command: issueSheetType,
                        // @ts-ignore
                        sheets: items
                          ? items.map((i) => ({
                              [issueSetSheetNames[issueSheetType]]:
                                // @ts-ignore
                                i[issueSetSheetNames[issueSheetType]],
                              Revision: i.Revision,
                            }))
                          : [],
                        revision,
                        plant,
                      })
                    }
                    disabled={areArraysOfObjectsIdentical(
                      data ? data : [],
                      items ? items : []
                    )}
                  >
                    Update
                  </Button>
                ) : (
                  issueSheetType === "pipe-elements" && (
                    <Button
                      onClick={() =>
                        issueSetPipeElements({
                          method: "put",
                          pipeelements: items
                            ? items.map((e) => ({
                                // @ts-ignore
                                ElementID: e.ElementID,
                                Revision: e.Revision,
                              }))
                            : [],
                          revision,
                          plant,
                        })
                      }
                      disabled={areArraysOfObjectsIdentical(
                        data ? data : [],
                        items ? items : []
                      )}
                    >
                      Update
                    </Button>
                  )
                )
              ) : (
                <></>
              )}
            </>
          }
        />
        {editSheetOpen && (
          <ModalWindow
            closeModal={() => setEditSheetOpen(false)}
            isOpen={editSheetOpen}
            title={`Change ${issueSheetType.toUpperCase()} ${
              editSheet.name
            } Revision`}
            layer="secondary"
          >
            {issueSheetType === "pipe-elements" ? (
              <EditIssuePipeElementsContent
                // @ts-ignore
                items={items ? items : []}
                setItems={setItems}
              />
            ) : (
              <EditIssueSheetContent
                // @ts-ignore
                items={items ? items : []}
                setItems={setItems}
                issueSheetType={issueSheetType}
                itemId={itemId}
              />
            )}
          </ModalWindow>
        )}
        <StatusModal
          error={issueSetSheetsError}
          status={issueSetSheetsStatus}
          onSettledClose={issueSetSheetsReset}
        />
        <StatusModal
          error={issueSetPipeElementsError}
          status={issueSetPipeElementsStatus}
          onSettledClose={issueSetPipeElementsReset}
        />
      </>
    ),
    checkIfDirty: async () => {
      return !areArraysOfObjectsIdentical(data ? data : [], items ? items : [])
        ? await isConfirmed(defaultUnsavedMessage)
        : true;
    },
  };
}
