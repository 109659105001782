type RawAppConfig = {
  restApiUrl: string;
  env: string;
  modules: string;
  appName: string;
  solutionName: string;
  customerName: string;
  loginClientId: string;
  loginAuthority: string;
  loginScopes: string;
  tinyApiKey: string;
  disableLiveMonitor: string;
  enableDebugLog: string;
};

declare global {
  interface Window {
    rawAppConfig: RawAppConfig;
  }
}

const rawAppConfig = window.rawAppConfig;

export type Config = {
  branding: {
    appName: string;
    solutionName: string;
    customerName: string;
  };
  properties: {
    env: "development" | "test" | "production" | "unknown env" | string;
    buildNumber: string;
    branchName: string;
  };
  restApiBaseUrl: string;
  login: {
    clientId: string;
    authority: string;
    scopes: string[];
  };
  modules: Modules[] | string[];
  liveMonitor: boolean;
  tinyApiKey: string;
  extraHeaders?: { [index: string]: string };
  debugLog?: boolean;
  statusSuccessSnackbar?: boolean;
};

export type Modules = "reports" | "sheets" | "admin";

function constTryCatch<T extends unknown>(valueFn: () => T, catchFn: () => T) {
  try {
    return valueFn();
  } catch (e) {
    return catchFn();
  }
}

const appEnvConfig: Config = {
  branding: {
    appName: rawAppConfig.appName || "PipeSpec Portal",
    solutionName: rawAppConfig.solutionName || "PipeSpec",
    customerName: rawAppConfig.customerName || "",
  },
  properties: {
    env: rawAppConfig.env || "unknown env",
    buildNumber: process.env.REACT_APP_BUILDNUMBER || "unknown build",
    branchName: process.env.REACT_APP_BRANCH_NAME || "unknown branch",
  },
  restApiBaseUrl:
    rawAppConfig.restApiUrl?.slice(-1) === "/"
      ? rawAppConfig.restApiUrl
      : rawAppConfig.restApiUrl + "/" || "",
  login: {
    clientId: rawAppConfig.loginClientId || "",
    authority: rawAppConfig.loginAuthority || "",
    scopes: constTryCatch(
      () => rawAppConfig.loginScopes?.split(" ") || [],
      () => []
    ),
  },
  modules: constTryCatch(
    () => rawAppConfig.modules?.split(" ") || ["reports"],
    () => ["reports"]
  ),
  liveMonitor: rawAppConfig.disableLiveMonitor ? false : true,
  debugLog: rawAppConfig.enableDebugLog ? true : false,
  tinyApiKey:
    rawAppConfig.tinyApiKey ||
    "fwhwst3m86jk0dzbdiay3zckeb92e8av5iyjvywsuzxwpxhc",
  statusSuccessSnackbar: true,
};

const config = {
  commonLibPlantCodesInUrl: false,
  useAPIFilters: true,
  checkForUpdateInterval: 1000 * 60 * 5,

  ...appEnvConfig,
};

export default config;
