import {
  Button,
  Checkbox,
  EdsProvider,
  Label,
  Search,
  Typography,
} from "@equinor/eds-core-react";
import {
  ControlButtonContainer,
  FlexColumnContainer,
  FlexColumnElement,
  LinearLoader,
} from "components/Components";
import { Field } from "components/form/Field";
import {
  Fieldset,
  FieldsetContainer,
  FormOptionsContainer,
  FormRow,
  FormRowBlock,
} from "components/form/Form";
import {
  ModalWindow,
  ModalWindowButtonContainer,
} from "components/ModalWindow";
import Table, {
  ControlHeaderContainer,
  SmallTableContainer,
} from "components/table/Table";
import { useTableSelect } from "components/table/useTableSelect";
import useOperators from "queries/useOperators";
import usePlants from "queries/usePlants";
import React, { useEffect, useRef, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useMemoOne as useMemo } from "use-memo-one";
import { filterItemsByName } from "utils/filterItemsByName";
import { EditComponentProps } from "../ItemEditModal";
import { AllUsers } from "./usersConfig";
import { UserRoles, userPower, userRoles } from "app/userConfig";
import { useUserConfig } from "UserConfigContext";
import { FieldContext } from "components/form/FieldContext";

function compileOptions({
  options,
  value,
}: {
  options: { [key in string]: string };
  value?: string;
}) {
  return [
    ...Object.keys(options).map((e) => ({
      id: e,
      option: options[e],
    })),
    ...(!value || Object.keys(options).includes(value)
      ? []
      : [
          {
            id: value,
            option: `[${value}]`,
          },
        ]),
  ];
}

function UserPlants({ item }: { item?: AllUsers }) {
  const didMount = useRef(false);
  const [plants, setPlants] = useState(item ? item.Plants : []);
  const { selection, selectionMode, selectionDispatch } = useTableSelect({
    selectionMode: "multi",
  });
  const {
    selection: newPlantSelection,
    selectionMode: newPlantSelectionMode,
    selectionDispatch: newPlantSelectionDispatch,
  } = useTableSelect({
    selectionMode: "multi",
  });
  const [showAdd, setShowAdd] = useState(false);
  const closeModal = () => setShowAdd(false);
  const { data, status, error } = usePlants();
  const [nameFilter, setNameFilter] = useState("");
  const { register, setValue } = useFormContext();

  useEffect(() => {
    setValue(
      "plants",
      JSON.stringify(plants.map((e) => ({ PlantID: e.PlantID }))),
      { shouldDirty: didMount.current }
    );
    didMount.current = true;
  }, [plants, setValue]);

  const items = useMemo(
    () =>
      filterItemsByName({
        items: data ? data : [],
        nameFilter,
        nameProperty: ["ShortDescription", "LongDescription", "OperatorName"],
      }),
    [data, nameFilter]
  );

  return (
    <>
      <input type="hidden" defaultValue="" {...register("plants")} />
      <Label label="User Plant Access" />
      <SmallTableContainer>
        <Table
          items={plants}
          itemIdProp="PlantID"
          columns={[
            { title: "Plant ID", key: "PlantID", width: 60 },
            { title: "Short Desc.", key: "ShortDescription", width: "100%" },
          ]}
          selectionMode={selectionMode}
          density="compact"
          fullRowSelect={true}
          selectionDispatch={selectionDispatch}
          selection={selection}
          controlHeader={
            <ControlButtonContainer>
              <Button variant="outlined" onClick={() => setShowAdd(true)}>
                Add new
              </Button>
              <Button
                variant="outlined"
                color="danger"
                disabled={selection.length === 0}
                onClick={() =>
                  setPlants(
                    plants.filter((e) => !selection.includes(String(e.PlantID)))
                  )
                }
              >
                Remove selected
              </Button>
            </ControlButtonContainer>
          }
        />
      </SmallTableContainer>
      <ModalWindow
        title="Add new User Plants"
        closeModal={closeModal}
        isOpen={showAdd}
      >
        <SmallTableContainer style={{ width: 700, maxWidth: "90vw" }}>
          <Table
            items={items}
            disabledItems={plants.map((e) => String(e.PlantID))}
            status={status}
            error={error}
            itemIdProp="PlantID"
            selectionMode={newPlantSelectionMode}
            selection={newPlantSelection}
            selectionDispatch={newPlantSelectionDispatch}
            density="compact"
            fullRowSelect={true}
            columns={[
              { title: "Plant ID", key: "PlantID", width: 60 },
              { title: "Short Desc.", key: "ShortDescription", width: "20%" },
              { title: "Long Desc.", key: "LongDescription", width: "40%" },
              { title: "Operator", key: "OperatorName", width: "20%" },
              { title: "Area", key: "Area", width: "20%" },
            ]}
            controlHeader={
              <ControlHeaderContainer>
                <EdsProvider density="comfortable">
                  <Search
                    value={nameFilter}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setNameFilter(e.target.value)
                    }
                    placeholder="Plant search"
                    style={{ width: 250 }}
                  />
                </EdsProvider>
              </ControlHeaderContainer>
            }
          />
        </SmallTableContainer>
        <ModalWindowButtonContainer>
          <Button
            disabled={newPlantSelection.length === 0}
            onClick={() => {
              setPlants([
                ...plants,
                ...newPlantSelection.map((selection) => {
                  const newPlant = data?.find(
                    (e) => String(e.PlantID) === selection
                  );
                  if (!newPlant) {
                    throw new Error("No plant.");
                  }
                  return {
                    PlantID: newPlant.PlantID,
                    ShortDescription: newPlant.ShortDescription,
                  };
                }),
              ]);
              newPlantSelectionDispatch({ type: "reset", payload: [] });
              closeModal();
            }}
          >
            <span>
              Add Plant
              <span
                style={{
                  visibility:
                    newPlantSelection.length === 1 ? "hidden" : "visible",
                }}
              >
                s
              </span>
            </span>
          </Button>
          <Button variant="outlined" onClick={closeModal}>
            Cancel
          </Button>
        </ModalWindowButtonContainer>
      </ModalWindow>
    </>
  );
}

export function UsersEdit({
  item,
  setEditLoading,
}: EditComponentProps<"users">) {
  const didMount = useRef(false);
  const { register, watch, setValue } = useFormContext();
  const role = watch("Role");
  const docSpace = watch("DocumentSpaceAdmin");
  const [pipe, setPipe] = useState(false);
  const [valve, setValve] = useState(false);
  const { data: operatorsData, status: operatorsStatus } = useOperators();
  const { userInfo } = useUserConfig();

  const isReadonly = useMemo(
    () => item && userPower[item.Role] >= userPower[userInfo.Role],
    [item, userInfo]
  );

  useEffect(() => {
    if (setEditLoading) {
      if (operatorsStatus === "success") {
        setEditLoading(false);
      } else {
        setEditLoading(true);
      }
    }
  }, [operatorsStatus, setEditLoading]);

  useEffect(() => {
    if (role) {
      setValue(
        "AdminRole",
        role !== "A"
          ? "A"
          : pipe && valve
          ? "X"
          : pipe
          ? "P"
          : valve
          ? "V"
          : "",
        { shouldDirty: didMount.current }
      );
      didMount.current = true;
    }
  }, [role, pipe, valve, setValue]);

  useEffect(() => {
    if (item && (item.AdminRole === "X" || item.AdminRole === "P")) {
      setPipe(true);
    }
    if (item && (item.AdminRole === "X" || item.AdminRole === "V")) {
      setValve(true);
    }
  }, [item]);

  const roleOptions = useMemo(
    () =>
      compileOptions({
        options: Object.fromEntries(
          Object.keys(userRoles)
            .filter(
              (e) =>
                userInfo.Role === "X" ||
                userPower[userInfo.Role] > userPower[e as UserRoles]
            )
            .map((e) => [e, userRoles[e as UserRoles]])
        ),
        value: item && item.Role,
      }),
    [item, userInfo.Role]
  );

  return (
    <>
      <FieldContext.Provider
        value={{
          disabled: isReadonly,
          slim: true,
        }}
      >
        <FlexColumnContainer>
          <FlexColumnElement>
            <FieldsetContainer>
              <Fieldset>
                <Field
                  area="users"
                  type="text"
                  value={item ? item.UserID : ""}
                  prop="UserID"
                  title="Username"
                  required
                  uppercase
                />
                <Field
                  area="users"
                  type="text"
                  value={item ? item.FullName : ""}
                  prop="FullName"
                  title="Full Name"
                />
                <Field
                  area="users"
                  type="text"
                  value={item ? item.Email : ""}
                  prop="Email"
                  title="Email"
                  required
                />
                <Field
                  area="users"
                  type="option"
                  value={item ? item.Role : "U"}
                  prop="Role"
                  title="Role"
                  optionsWithIds={roleOptions}
                  disableNotSetOption={true}
                />
                <Field
                  area="users"
                  type="text"
                  value={item && item.RoleEndDate ? item.RoleEndDate : ""}
                  prop="RoleEndDate"
                  title="Role End Date"
                />
                <FormRowBlock>
                  <Label label="Admin Role" />
                  <FormOptionsContainer>
                    <input
                      type="hidden"
                      defaultValue=""
                      {...register("AdminRole")}
                    />
                    <div>
                      <Checkbox
                        id="Pipe"
                        label="Pipe"
                        checked={pipe}
                        value={pipe ? 1 : 0}
                        onChange={(e) => {
                          setPipe(!Number(e.target.value));
                        }}
                        disabled={role !== "A"}
                      />
                    </div>
                    <div>
                      <Checkbox
                        id="Valve"
                        label="Valve"
                        checked={valve}
                        value={valve ? 1 : 0}
                        onChange={(e) => {
                          setValve(!Number(e.target.value));
                        }}
                        disabled={role !== "A"}
                      />
                    </div>
                  </FormOptionsContainer>
                </FormRowBlock>
                <Label label="PCS Admin" />
                <Field
                  area="users"
                  style={{ marginTop: 0 }}
                  type="checkbox"
                  value={item ? item.PCSAdmin : "N"}
                  prop="PCSAdmin"
                  title={'Set "Ready for issue"'}
                />
              </Fieldset>
            </FieldsetContainer>
          </FlexColumnElement>
          <FlexColumnElement>
            <FormRowBlock>
              <Label label="Document Space" />
              <Field
                area="users"
                style={{ marginTop: 0, marginBottom: 4 }}
                type="checkbox"
                value={item ? item.DocSpaceAdmin : "N"}
                prop="DocumentSpaceAdmin"
                title="Access"
                disabled={role === "A" || role === "X"}
              />
              {operatorsStatus === "success" ? (
                <Field
                  style={{ marginTop: 0 }}
                  type="option"
                  value={
                    item
                      ? item.OperatorID
                      : operatorsData
                      ? operatorsData[0].OperatorID
                      : 0
                  }
                  prop="OperatorID"
                  title="Operator"
                  disabled={docSpace === false || role === "A" || role === "X"}
                  optionsWithIds={
                    operatorsData
                      ? operatorsData.map((e) => ({
                          id: e.OperatorID,
                          option: e.OperatorName,
                        }))
                      : []
                  }
                />
              ) : operatorsStatus === "loading" ? (
                <FormRow>
                  <div style={{ height: 40, width: 300 }}>
                    <LinearLoader label="Loading Operators..." />
                  </div>
                </FormRow>
              ) : (
                <Typography color="danger">Error loading Operators.</Typography>
              )}
            </FormRowBlock>
            <FormRowBlock>
              {role === "U" && <UserPlants item={item} />}
            </FormRowBlock>
          </FlexColumnElement>
        </FlexColumnContainer>
      </FieldContext.Provider>
    </>
  );
}
