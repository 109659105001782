import { Icon, Menu } from "@equinor/eds-core-react";
import {
  arrow_drop_down,
  arrow_drop_up,
  assignment,
  code,
  delete_forever,
  format_indent_increase,
  language,
  more_horizontal,
} from "@equinor/eds-icons";
import { ContextMenu } from "components/ContextMenu";
import { getApiUrl } from "queries/queryUtil";
import { useMemo } from "react";
import { CreateNewMenu } from "./CreateNewMenu";
import { DocumentSpaceContextData } from "./DocumentSpace";
import { DocumentSpaceItem } from "./queries/useDocumentSpace";
import { protectedFolderOIDs } from "./useDirectoryTree";
import { MoveToPositionModal, useSort } from "./useSort";

export const FolderRowMenu = ({
  item,
  deleteItem,
  setNewObjectTargetOID,
  setCreateObjectOpen,
  setEditItem,
  setEditOpen,
  setCreateDocumentType,
  folderData,
  mutateObjectSort,
  mutateObjectSortStatus,
  mutateVisibleOnWeb,
}: {
  item: DocumentSpaceItem;
} & DocumentSpaceContextData) => {
  const validDocumentTypes = useMemo(
    () => item?.ValidDocumentTypes.split(",") || [],
    [item]
  );

  const neighbors = useMemo(
    () => folderData?.filter((e) => e.ParentOID === item.ParentOID)!,
    [folderData, item.ParentOID]
  );

  const { moveUp, moveDown, moveToPositionProps, setShowMoveToPosition } =
    useSort({
      neighbors,
      item,
      oid: item.ParentOID,
      mutateObjectSort,
      mutateObjectSortStatus,
    });

  const hasEdit = !(
    protectedFolderOIDs.includes(item.OID) || item.DocumentType === "N"
  );

  return (
    <>
      <ContextMenu buttonContent={<Icon data={more_horizontal} />}>
        {hasEdit && (
          <Menu.Section>
            <Menu.Item
              onClick={(e) => {
                e.stopPropagation();
                setEditItem(item.OID);
                setEditOpen(true);
              }}
            >
              <Icon data={assignment} size={16} /> Folder properties
            </Menu.Item>
          </Menu.Section>
        )}
        <Menu.Section title="Create a new object below">
          <CreateNewMenu
            selection={[String(item.OID)]}
            validDocumentTypes={validDocumentTypes}
            setCreateDocumentType={setCreateDocumentType}
            setCreateObjectOpen={setCreateObjectOpen}
            setNewObjectTargetOID={setNewObjectTargetOID}
            nested={true}
            hide={true}
          />
        </Menu.Section>
        {!protectedFolderOIDs.includes(item.OID) && (
          <Menu.Section>
            <Menu.Item onClick={() => deleteItem(item)}>
              <Icon data={delete_forever} size={16} /> Delete
            </Menu.Item>
          </Menu.Section>
        )}
        {!protectedFolderOIDs.includes(item.OID) && (
          <Menu.Section>
            <Menu.Item
              disabled={!moveUp}
              onClick={(e) => {
                e.stopPropagation();
                moveUp && moveUp();
              }}
            >
              <Icon data={arrow_drop_up} size={16} /> Move up
            </Menu.Item>
            <Menu.Item
              disabled={!moveDown}
              onClick={(e) => {
                e.stopPropagation();
                moveDown && moveDown();
              }}
            >
              <Icon data={arrow_drop_down} size={16} /> Move down
            </Menu.Item>
            <Menu.Item
              onClick={(e) => {
                e.stopPropagation();
                setShowMoveToPosition(true);
              }}
            >
              <Icon data={format_indent_increase} size={16} /> Move to position
            </Menu.Item>
          </Menu.Section>
        )}
        {!(
          protectedFolderOIDs.includes(item.OID) || item.DocumentType === "N"
        ) && (
          <Menu.Section>
            <Menu.Item
              onClick={() => {
                mutateVisibleOnWeb({ oid: item.OID });
              }}
            >
              <Icon data={language} size={16} /> Set visible on web (cascade)
            </Menu.Item>
          </Menu.Section>
        )}
        <Menu.Section>
          <Menu.Item
            onClick={(e) => {
              e.stopPropagation();
              window.open(
                getApiUrl(`document-space/${item.OID}/properties`),
                "_blank"
              );
            }}
          >
            <Icon data={code} size={16} /> View JSON
          </Menu.Item>
        </Menu.Section>
      </ContextMenu>
      <MoveToPositionModal {...moveToPositionProps} />
    </>
  );
};
