import { ValveTypeCodeContainer } from "components/Components";
import { MultiselectFieldFromCommaSeparated } from "components/form/EditComponents";
import {
  CodelistSpecification,
  Codelists,
  codelistProperties,
  useCodelist,
} from "queries/useCodelist";
import { useEffect, useMemo } from "react";
import { QueryStatus } from "react-query";
import styled from "styled-components";
import { VDSSubsegment, VDSTextBlock } from "../types";
import { useFormContext } from "react-hook-form";

const valveTypeOptionTitle = (
  valveType: CodelistSpecification["valve-types"]
) => (
  <>
    <ValveTypeCodeContainer>{valveType.VDSCode}</ValveTypeCodeContainer>{" "}
    {valveType.Description}
  </>
);

export function VDSValidator<T extends Codelists>({
  codelist,
  prop,
  value,
  statusesDispatch,
  titleFunc,
  isRefetching,
  width,
  height,
  disabled,
  disabledTooltip,
}: {
  codelist: T;
  prop: string;
  value: string | undefined;
  statusesDispatch: React.Dispatch<QueryStatusAction>;
  titleFunc?: (arg0: CodelistSpecification[T]) => React.ReactFragment;
  isRefetching?: boolean;
  width?: number;
  height?: number;
  disabled?: boolean;
  disabledTooltip?: boolean;
}) {
  const {
    data: codelistData,
    status,
    error,
  } = useCodelist({
    codelist,
  });

  const { idProp, nameProp, titleSingular } = codelistProperties[codelist];

  useEffect(() => {
    statusesDispatch({
      type: "set",
      payload: { [codelist]: status },
    });
  }, [status, statusesDispatch, codelist]);

  const options = useMemo(
    () =>
      codelistData?.map((e) => ({
        key: String(e[idProp as keyof typeof e]),
        title: titleFunc
          ? titleFunc(e)
          : (e[nameProp as keyof typeof e] as string),
      })),
    [codelistData, idProp, nameProp, titleFunc]
  );

  return (
    <MultiselectFieldFromCommaSeparated
      options={options}
      title={titleSingular ?? ""}
      prop={prop}
      value={value ?? ""}
      noneSelectedString="All"
      noneSelectedValue=""
      status={status}
      error={error}
      isRefetching={isRefetching}
      width={width}
      height={height}
      disabled={disabled}
      disabledTooltip={disabledTooltip}
    />
  );
}

export function VDSValidators({
  data,
  statusesDispatch,
  isRefetching,
  autoSize,
  fullHeight,
  preProp = "",
  isEdit,
  flattenFields = (fields) => Object.keys(fields),
}: {
  data:
    | VDSTextBlock
    | VDSSubsegment["getVDSSubsegmentProperties"][0]
    | undefined;
  statusesDispatch: React.Dispatch<QueryStatusAction>;
  isRefetching?: boolean;
  autoSize?: boolean;
  fullHeight?: number;
  preProp?: string;
  isEdit?: boolean;
  flattenFields?: (fields: { [index: string]: string | Object }) => string[];
}) {
  const height = Math.max(fullHeight ?? 0, 500);
  const { formState } = useFormContext();
  const { dirtyFields: originalDirtyFields } = formState;
  const properties = [
    "ValveTypeProperty",
    "RatingClassProperty",
    "MaterialGroupProperty",
    "EndConnectionProperty",
    "SpecialRequirementProperty",
    "BoreProperty",
    "VDSSizeProperty",
  ];
  const dirtyFields = flattenFields(originalDirtyFields);
  const isDirty = properties.some((property) => dirtyFields.includes(property));
  const canOnlyEditOneAtATime = false;

  return (
    <>
      <VDSValidator
        codelist="valve-types"
        statusesDispatch={statusesDispatch}
        prop={preProp + "ValveTypeProperty"}
        value={data?.ValveTypeProperty}
        titleFunc={valveTypeOptionTitle}
        isRefetching={isRefetching}
        width={autoSize ? 309 : undefined}
        height={autoSize ? (height / 8) * 3 : undefined}
        disabled={
          canOnlyEditOneAtATime &&
          isEdit &&
          isDirty &&
          !dirtyFields.includes("ValveTypeProperty")
        }
        disabledTooltip
      />
      <VDSValidator
        codelist="rating-classes"
        statusesDispatch={statusesDispatch}
        prop={preProp + "RatingClassProperty"}
        value={data?.RatingClassProperty}
        isRefetching={isRefetching}
        width={autoSize ? 309 : undefined}
        height={autoSize ? (height / 8) * 3 : undefined}
        disabled={
          canOnlyEditOneAtATime &&
          isEdit &&
          isDirty &&
          !dirtyFields.includes("RatingClassProperty")
        }
        disabledTooltip
      />
      <VDSValidator
        codelist="material-groups"
        statusesDispatch={statusesDispatch}
        prop={preProp + "MaterialGroupProperty"}
        value={data?.MaterialGroupProperty}
        isRefetching={isRefetching}
        width={autoSize ? 309 : undefined}
        height={autoSize ? (height / 8) * 3 : undefined}
        disabled={
          canOnlyEditOneAtATime &&
          isEdit &&
          isDirty &&
          !dirtyFields.includes("MaterialGroupProperty")
        }
        disabledTooltip
      />
      <VDSValidator
        codelist="end-connections"
        statusesDispatch={statusesDispatch}
        prop={preProp + "EndConnectionProperty"}
        value={data?.EndConnectionProperty}
        isRefetching={isRefetching}
        width={autoSize ? 309 : undefined}
        height={autoSize ? (height / 8) * 3 : undefined}
        disabled={
          canOnlyEditOneAtATime &&
          isEdit &&
          isDirty &&
          !dirtyFields.includes("EndConnectionProperty")
        }
        disabledTooltip
      />
      <VDSValidator
        codelist="special-requirements"
        statusesDispatch={statusesDispatch}
        prop={preProp + "SpecialRequirementProperty"}
        value={data?.SpecialRequirementProperty}
        isRefetching={isRefetching}
        width={autoSize ? 200 : undefined}
        height={autoSize ? (height / 8) * 2 : undefined}
        disabled={
          canOnlyEditOneAtATime &&
          isEdit &&
          isDirty &&
          !dirtyFields.includes("SpecialRequirementProperty")
        }
        disabledTooltip
      />
      <VDSValidator
        codelist="bores"
        statusesDispatch={statusesDispatch}
        prop={preProp + "BoreProperty"}
        value={data?.BoreProperty}
        isRefetching={isRefetching}
        width={autoSize ? 200 : undefined}
        height={autoSize ? (height / 8) * 2 : undefined}
        disabled={
          canOnlyEditOneAtATime &&
          isEdit &&
          isDirty &&
          !dirtyFields.includes("BoreProperty")
        }
        disabledTooltip
      />
      <VDSValidator
        codelist="vds-sizes"
        statusesDispatch={statusesDispatch}
        prop={preProp + "VDSSizeProperty"}
        value={data?.VDSSizeProperty}
        isRefetching={isRefetching}
        width={autoSize ? 200 : undefined}
        height={autoSize ? (height / 8) * 2 : undefined}
        disabled={
          canOnlyEditOneAtATime &&
          isEdit &&
          isDirty &&
          !dirtyFields.includes("VDSSizeProperty")
        }
        disabledTooltip
      />
    </>
  );
}

export const PropertiesColumns = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-content: flex-start;
  gap: 30px;
`;

export type QueryStatusCollection = { [index: string]: QueryStatus };

export type QueryStatusAction = { type: "set"; payload: QueryStatusCollection };

export function statusesReducer(
  state: QueryStatusCollection,
  action: QueryStatusAction
) {
  return { ...state, ...action.payload };
}
