import { Button, Icon, Typography } from "@equinor/eds-core-react";
import {
  arrow_drop_down,
  arrow_drop_up,
  delete_forever,
} from "@equinor/eds-icons";
import { FlexContainer } from "components/Components";
import useConfirm from "components/confirm/useConfirm";
import { CenterInput } from "components/form/EditComponents";
import Table, {
  ColumnsProps,
  TableCellContentNoClamp,
  TableItem,
} from "components/table/Table";
import {
  VDSItemSections,
  VDSSubsegmentListSectionProperties,
} from "features/sheets/types";
import { getItemID } from "features/sheets/util";
import { CodelistSpecification } from "queries/useCodelist";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import {
  UseFieldArrayRemove,
  UseFieldArraySwap,
  useFieldArray,
  useFormContext,
} from "react-hook-form";
import { CommonObjectContent } from "utils/filterItemsByProps";
import { TextBlockSelector } from "./TextBlockSelector";
import { FieldContext } from "components/form/FieldContext";

function TableRevisionInput({
  item,
  prop,
  listProperty,
}: {
  item: TableItem<CommonObjectContent>;
  prop: string;
  listProperty: string;
}) {
  const [value, setValue] = useState(item[prop]);
  const { clear } = useContext(FieldContext);
  return (
    <CenterInput
      title=""
      style={{ width: 40, margin: "8px 0" }}
      maxLength={2}
      value={clear ? "" : value}
      onChange={(e) => {
        setValue(e.target.value.toUpperCase());
      }}
      validate={{ twoLetters: (v: string) => /^[A-Z0-9]{0,2}$/.test(v) }}
      prop={listProperty + "." + item._absoluteIndex + "." + prop}
      type="text"
    />
  );
}

function DeleteButton({
  item,
  remove,
}: {
  item: TableItem<CommonObjectContent>;
  remove: UseFieldArrayRemove;
}) {
  const { isConfirmed } = useConfirm();
  return (
    <Button
      onClick={async () => {
        (await isConfirmed(
          `Are you sure you want to remove ${item.textBlock}?`,
          { buttonColor: "danger" }
        )) && remove(item._absoluteIndex);
      }}
      variant="ghost_icon"
    >
      <Icon data={delete_forever} />
    </Button>
  );
}

function MoveUpButton({
  item,
  swap,
}: {
  item: TableItem<CommonObjectContent>;
  swap: UseFieldArraySwap;
}) {
  return (
    <Button
      onClick={async () => {
        swap(item._absoluteIndex, item._absoluteIndex - 1);
      }}
      disabled={item._groupIndex <= 0}
      variant="ghost_icon"
      style={{ visibility: item._groupIndex <= 0 ? "hidden" : "visible" }}
    >
      <Icon data={arrow_drop_up} />
    </Button>
  );
}

function MoveDownButton({
  item,
  swap,
}: {
  item: TableItem<CommonObjectContent>;
  swap: UseFieldArraySwap;
}) {
  return (
    <Button
      onClick={async () => {
        swap(item._absoluteIndex, item._absoluteIndex + 1);
      }}
      variant="ghost_icon"
      disabled={item._groupIndex >= item._groupLength - 1}
      style={{
        visibility:
          item._groupIndex >= item._groupLength - 1 ? "hidden" : "visible",
      }}
    >
      <Icon data={arrow_drop_down} />
    </Button>
  );
}

export function HiddenInputElement({
  item,
  prop,
  listProperty,
}: {
  item: any;
  prop: string;
  listProperty: string;
}) {
  const { register, getValues } = useFormContext();
  const fieldId = `${listProperty}.${item._absoluteIndex}.${prop}`;
  const value = getValues(fieldId);
  return (
    <>
      <input
        type="hidden"
        defaultValue={item[prop] ?? ""}
        {...register(fieldId)}
      />
      <TableCellContentNoClamp>{value}</TableCellContentNoClamp>
    </>
  );
}

type ListSections = "D" | "M" | "R";

const sectionsConfig: {
  [index: string]: {
    title: string;
    listProperty: VDSSubsegmentListSectionProperties;
    itemId: string;
    itemRevision: string;
    columns: ColumnsProps[];
  };
} = {
  D: {
    title: "Design",
    listProperty: "getVDSSubsegmentDesign",
    itemId: "DesignItemID",
    itemRevision: "DesignItemRevision",
    columns: [
      {
        key: "DesignItemRevision",
        title: "Rev.",
        type: "with-context",
        Component: TableRevisionInput,
        componentProps: {
          prop: "DesignItemRevision",
        },
        width: 50,
      },
      {
        key: "DesignDescription",
        title: "Description",
        width: "100%",
        type: "with-context",
        Component: HiddenInputElement,
        componentProps: { prop: "DesignDescription" },
      },
      { key: "textBlock", title: "Text Block ID", width: 100 },
    ],
  },
  M: {
    title: "Material",
    listProperty: "getVDSSubsegmentMaterial",
    itemId: "MaterialItemID",
    itemRevision: "MaterialItemRevision",
    columns: [
      {
        key: "MaterialItemRevision",
        title: "Rev.",
        type: "with-context",
        Component: TableRevisionInput,
        componentProps: { prop: "MaterialItemRevision" },
        width: 50,
      },
      {
        key: "MaterialSubItemText",
        title: "Sub Item Text",
        type: "with-context",
        Component: HiddenInputElement,
        componentProps: { prop: "MaterialSubItemText" },
      },
      {
        key: "MaterialName",
        title: "Material",
        width: "40%",
        type: "with-context",
        Component: HiddenInputElement,
        componentProps: { prop: "MaterialName" },
      },
      {
        key: "MaterialMDS",
        title: "MDS",
        minWidth: 105,
        type: "with-context",
        Component: HiddenInputElement,
        componentProps: { prop: "MaterialMDS" },
      },
      {
        key: "MaterialDescription",
        title: "Description",
        width: "60%",
        type: "with-context",
        Component: HiddenInputElement,
        componentProps: { prop: "MaterialDescription" },
      },
      { key: "textBlock", title: "Text Block ID", width: 100 },
    ],
  },
  R: {
    title: "Miscellaneous Requirements",
    listProperty: "getVDSSubsegmentMiscReq",
    itemId: "MiscReqItemID",
    itemRevision: "MiscReqItemRevision",
    columns: [
      {
        key: "MiscReqItemRevision",
        title: "Rev.",
        type: "with-context",
        Component: TableRevisionInput,
        componentProps: { prop: "MiscReqItemRevision" },
        width: 50,
      },
      {
        key: "MiscReqVSK",
        title: "VSK Ref.",
        longTitle: "VSK Reference",
        type: "with-context",
        Component: HiddenInputElement,
        componentProps: { prop: "MiscReqVSK" },
      },
      {
        key: "MiscReqDescription",
        title: "Description",
        width: "100%",
        type: "with-context",
        Component: HiddenInputElement,
        componentProps: { prop: "MiscReqDescription" },
      },
      { key: "textBlock", title: "Text Block ID", width: 100 },
    ],
  },
};

function ListSectionHiddenComponent({
  item,
  listProperty,
  itemSection,
}: {
  item: TableItem<CommonObjectContent>;
  listProperty: string;
  itemSection: VDSItemSections;
}) {
  const { register } = useFormContext();
  const preProp = `${listProperty}.${item._absoluteIndex}.`;
  return (
    <>
      <input
        type="hidden"
        value={item.TextBlockID}
        {...register(preProp + "TextBlockID")}
      />
      <input
        type="hidden"
        value={item.TextBlockRevision}
        {...register(preProp + "TextBlockRevision")}
      />
      {itemSection === "D" && (
        <>
          <input
            type="hidden"
            value={item.DesignItemID}
            {...register(preProp + "DesignItemID")}
          />
          <input
            type="hidden"
            value={item.DesignItemText}
            {...register(preProp + "DesignItemText")}
          />
          <input
            type="hidden"
            value={item.DesignSubItemID}
            {...register(preProp + "DesignSubItemID")}
          />
        </>
      )}
      {itemSection === "M" && (
        <>
          <input
            type="hidden"
            value={item.MaterialItemID}
            {...register(preProp + "MaterialItemID")}
          />
          <input
            type="hidden"
            value={item.MaterialItemText}
            {...register(preProp + "MaterialItemText")}
          />
          <input
            type="hidden"
            value={item.MaterialSubItemID}
            {...register(preProp + "MaterialSubItemID")}
          />
        </>
      )}
      {itemSection === "R" && (
        <>
          <input
            type="hidden"
            value={item.MiscReqItemID}
            {...register(preProp + "MiscReqItemID")}
          />
          <input
            type="hidden"
            value={item.MiscReqItemText}
            {...register(preProp + "MiscReqItemText")}
          />
          <input
            type="hidden"
            value={item.MiscReqSubItemID}
            {...register(preProp + "MiscReqSubItemID")}
          />
        </>
      )}
    </>
  );
}

export function ListSection<T extends ListSections>({
  section,
  config,
  itemSection,
  setItemSection,
  itemId,
  setItemId,
}: {
  section: T;
  config: CodelistSpecification["vds-subsegment-groups-config"][];
  itemSection: VDSItemSections;
  setItemSection: React.Dispatch<VDSItemSections>;
  itemId: number;
  setItemId: React.Dispatch<number>;
}) {
  const sectionConfig = sectionsConfig[section];
  const { listProperty, title } = sectionConfig;

  const itemTextProp =
    section === "D"
      ? "DesignItemText"
      : section === "M"
      ? "MaterialItemText"
      : "MiscReqItemText";
  const itemIdProp =
    section === "D"
      ? "DesignItemID"
      : section === "M"
      ? "MaterialItemID"
      : "MiscReqItemID";

  const { control } = useFormContext();
  const { fields, swap, remove, insert } = useFieldArray({
    control,
    name: listProperty,
  });

  // Group Names are extracted from the Subsegment itself instead of the configuration
  // as – however unlikely – the configuration might have been changed
  // since the Subsegment has been saved.
  const groupNames = useMemo(
    () =>
      fields.reduce(
        (a, c) => ({
          ...a,
          ...(c[itemIdProp as keyof typeof c] in a ||
          c[itemTextProp as keyof typeof c] === ""
            ? {}
            : {
                [c[itemIdProp as keyof typeof c]]:
                  c[itemTextProp as keyof typeof c],
              }),
        }),
        {} as { [index: string]: string }
      ),
    [itemIdProp, itemTextProp, fields]
  );

  // The group names might be lost when rearringing the fields.
  // That's the reason that they are stored in a ref.
  const savedGroupNames = useRef<{ [index: string]: string }>({});

  useEffect(() => {
    // Save it when it gets populated.
    if (
      Object.keys(savedGroupNames.current).length === 0 &&
      Object.keys(groupNames).length > 0
    ) {
      savedGroupNames.current = groupNames;
    }
  }, [groupNames]);

  const tableItems = useMemo(
    () =>
      fields.map((e) => ({
        ...e,
        // Finally, either it's in the saved (for originals), or the calculated (for those added later).
        groupTitle: { ...savedGroupNames.current, ...groupNames }[
          e[itemIdProp as keyof typeof e]
        ],
        // @ts-ignore
        textBlock: getItemID(e.TextBlockID, e.TextBlockRevision),
      })),
    [fields, groupNames, itemIdProp]
  );

  const [selectorOpen, setSelectorOpen] = useState(false);

  const disabledItems = useMemo(
    () => fields.map((e) => e["TextBlockID" as keyof typeof e]),
    [fields]
  );

  return (
    <div>
      <FlexContainer
        style={{ marginBottom: 12, marginTop: 6, alignItems: "flex-end" }}
      >
        <Typography variant="h4" style={{ margin: 0 }}>
          {sectionConfig.title} Section
        </Typography>
        <div style={{ marginLeft: "auto" }}>
          <Button
            variant="outlined"
            disabled={config.length === 0}
            onClick={() => {
              setSelectorOpen(true);
              if (
                !config.map((e) => e.ItemID).includes(itemId) ||
                itemSection !== section
              ) {
                setItemId(config[0].ItemID);
              }
              setItemSection(section);
            }}
          >
            Add a {sectionConfig.title} Text Block
          </Button>
        </div>
      </FlexContainer>
      <Table
        relativePositioning
        nonVirtual
        density="compact"
        groupTitle="groupTitle"
        groupBy={sectionConfig.itemId}
        groupIdProp={sectionConfig.itemId}
        items={tableItems}
        columns={sectionConfig.columns}
        itemIdProp="textBlock"
        Commands={[MoveUpButton, MoveDownButton, DeleteButton]}
        noItemsMessage={
          <div style={{ width: "100%", textAlign: "left" }}>No items.</div>
        }
        contextData={{
          swap,
          remove,
          listProperty,
          itemSection: section,
        }}
        RowHiddenComponent={ListSectionHiddenComponent}
      />
      {selectorOpen && (
        <TextBlockSelector
          isOpen={selectorOpen}
          setIsOpen={setSelectorOpen}
          itemSection={itemSection}
          setItemSection={setItemSection}
          itemId={itemId}
          setItemId={setItemId}
          itemIdProp={itemIdProp}
          groupsConfig={config}
          insert={insert}
          disabledItems={disabledItems}
          fields={fields}
          title={title}
        />
      )}
    </div>
  );
}
