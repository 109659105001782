import {
  assignment,
  build_wrench,
  comment,
  folder,
  grid_layers,
  layers,
  pipe_support,
  shuffle,
} from "@equinor/eds-icons";
import { Access, hasAccess } from "Access";
import { useUserConfig } from "UserConfigContext";
import { Category, MenuItem } from "components/MainMenuComponents";
import { useMemo } from "react";
import { useAppSelector } from "../../app/hooks";
import { getPlantURLID } from "../plant/PlantSelector";
import { selectMainPlant } from "../plant/plantSlice";
import config from "config/config";

export function SheetMenu() {
  const plant = useAppSelector(selectMainPlant);
  const plantURLID = useMemo(() => getPlantURLID(plant), [plant]);
  const plantPrefix = `/plant/${plantURLID}`;
  const { caps, userInfo } = useUserConfig();
  return (
    <>
      <Access cap="sheets" module="sheets" hide>
        <Access cap={["sheets_eds", "sheets_vds"]} hide>
          <Category title={`${config.branding.solutionName} Data Sheets`}>
            <MenuItem title="EDS" to="/eds" icon={folder} cap="sheets_eds" />
            <MenuItem
              title="VDS"
              to="/vds"
              icon={folder}
              cap="sheets_vds"
              hideSubmenu={
                !hasAccess({
                  cap: [
                    "sheets_vds_replace_subsegment",
                    "sheets_vds_update_content",
                  ],
                  caps,
                  userInfo,
                })
              }
            >
              <MenuItem
                title="Replace Subsegment"
                to="/vds/replace-subsegment/"
                icon={build_wrench}
                cap="sheets_vds_replace_subsegment"
              />
              <MenuItem
                title="Update Content"
                to="/vds/update-content/"
                icon={build_wrench}
                cap="sheets_vds_update_content"
              />
            </MenuItem>
            <MenuItem
              title="Unstructured VDS"
              to="/vds-unstructured"
              icon={folder}
              cap="sheets_vds"
            ></MenuItem>
          </Category>
        </Access>
        <Category title="Plant Data Sheets">
          <MenuItem
            title="General"
            to={`${plantPrefix}/general/`}
            icon={folder}
            disabled={!plantURLID}
          />
          <MenuItem
            title="PCS"
            to={`${plantPrefix}/pcs/`}
            icon={folder}
            disabled={!plantURLID}
          >
            <MenuItem
              title="References"
              to={`${plantPrefix}/pcs/references/`}
              icon={shuffle}
              disabled={!plantURLID}
            />
            <MenuItem
              title="Standard Notes"
              to={`${plantPrefix}/pcs/standard-notes/`}
              icon={comment}
              disabled={!plantURLID}
            />
            <MenuItem
              title="Pipe Elements"
              to={`${plantPrefix}/pcs/pipe-elements/`}
              icon={pipe_support}
              disabled={!plantURLID}
            />
            <MenuItem
              title="Properties"
              to={`${plantPrefix}/pcs/properties/`}
              icon={assignment}
              disabled={!plantURLID}
            />
            <MenuItem
              title="Article Stack"
              to={`${plantPrefix}/pcs/article-stack/`}
              icon={layers}
              disabled={!plantURLID}
            />
          </MenuItem>
          <MenuItem
            title="SC"
            to={`${plantPrefix}/sc/`}
            icon={folder}
            disabled={!plantURLID}
          />
          <MenuItem
            title="VSM"
            to={`${plantPrefix}/vsm/`}
            icon={folder}
            disabled={!plantURLID}
          />
          <MenuItem
            title="Issues"
            to={`${plantPrefix}/issues/`}
            icon={grid_layers}
            disabled={!plantURLID}
          />
        </Category>
      </Access>
      <Access cap="sheets" module="sheets" hide>
        <Access cap={["vds_subsegment", "vds_textblock"]} hide>
          <Category title="VDS Components">
            <MenuItem
              title="VDS Subsegments"
              to="/vds-subsegments"
              icon={folder}
              cap="vds_subsegment"
            />

            <MenuItem
              title="VDS Text Blocks"
              to="/vds-textblocks"
              icon={folder}
              cap="vds_textblock"
            />
          </Category>
        </Access>
      </Access>
    </>
  );
}
