import {
  Button,
  Checkbox,
  Table as EDSTable,
  EdsProvider,
  Icon,
  IconProps,
  Input,
  Label,
  Radio,
  TextField,
  Tooltip,
} from "@equinor/eds-core-react";
import { comment, comment_chat, copy, paste } from "@equinor/eds-icons";
import { ModalWindow } from "components/ModalWindow";
import Table, { SmallTableContainer } from "components/table/Table";
import { useTableSelect } from "components/table/useTableSelect";
import { useCodelist } from "queries/useCodelist";
import React, {
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { useFormContext } from "react-hook-form";
import { QueryStatus } from "react-query";
import styled from "styled-components";
import { useMemoOne as useMemo } from "use-memo-one";
import { createId } from "utils/util";
import useStandardNotes, {
  NoteSections,
} from "../../features/sheets/hooks/useStandardNotes";
import {
  FlexContainer,
  FlexElement,
  FlexFormContainer,
  RevisionMark,
} from "../Components";
import { ModalButtonContainer, ModalContentContainer } from "../Modal";
import { Field } from "./Field";
import { FieldContext } from "./FieldContext";
import { FormRow, FormRowBlock } from "./Form";

export type Pagebreak = "P" | "N";

export const RevCont = styled.div`
  align-self: flex-end;
  min-width: 30px;
  padding-bottom: 6px;
  padding-right: 1em;
`;

export const InpCont = styled.div`
  min-width: 50%;
  flex-grow: 1;
`;

export const NoteCont = styled.div`
  margin-left: auto;
  padding-left: 1em;
`;

export const SeriesContainer = styled.div`
  display: flex;
  > div {
    margin: 0 4px;
    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
  }
`;

function NoteModal({
  note,
  noteProp,
  noteEditorOpen,
  setNoteEditorOpen,
  title,
  section,
}: {
  note: string;
  noteProp: string;
  noteEditorOpen: boolean;
  setNoteEditorOpen: Function;
  title?: string;
  section: NoteSections;
}) {
  const noteArr = useMemo(() => (note ? note.split(",") : []), [note]);
  const { setValue } = useFormContext();
  const { standardNotesContent, selection } = useStandardNotes({
    noteSelectionMode: "multi",
    section,
    defaultSelection: noteArr,
  });

  const closeModal = () => {
    setNoteEditorOpen(false);
  };

  return (
    <ModalWindow isOpen={noteEditorOpen} closeModal={closeModal} title={title}>
      <ModalContentContainer
        style={{
          marginTop: 0,
          marginBottom: 0,
          minWidth: 1000,
        }}
      >
        {standardNotesContent}
        <ModalButtonContainer>
          <EdsProvider density="comfortable">
            <Button
              onClick={() => {
                setValue(noteProp, selection.join(), { shouldDirty: true });
                setNoteEditorOpen(false);
              }}
            >
              Set
            </Button>
            <Button variant="outlined" onClick={() => setNoteEditorOpen(false)}>
              Close
            </Button>
          </EdsProvider>
        </ModalButtonContainer>
      </ModalContentContainer>
    </ModalWindow>
  );
}
function NoteButtonContent({
  noteProp,
  title,
  size,
  section,
}: {
  noteProp: string;
  title?: string;
  size?: IconProps["size"];
  section: NoteSections;
}) {
  const { getValues } = useFormContext();
  const [noteEditorOpen, setNoteEditorOpen] = useState(false);
  const currentNote = getValues(noteProp);
  const { data } = useCodelist({
    codelist: "pcs-standard-notes",
    extraParams: { notefilter: section },
  });

  return (
    <>
      <Button
        variant="ghost_icon"
        onClick={() => setNoteEditorOpen(true)}
        title={
          !currentNote
            ? "No notes."
            : currentNote
                .split(",")
                .map((noteId: string) => {
                  const noteElement = data?.find(
                    (note: any) => note.NoteID === Number(noteId)
                  );
                  return `${noteId}: ${
                    noteElement ? noteElement.NoteText : "(text?)"
                  }`;
                })
                .join("\n")
        }
      >
        <Icon
          data={currentNote && currentNote !== "0" ? comment_chat : comment}
          size={size}
        />
      </Button>
      {noteEditorOpen && (
        <NoteModal
          note={currentNote}
          noteProp={noteProp}
          title={title}
          noteEditorOpen={noteEditorOpen}
          setNoteEditorOpen={setNoteEditorOpen}
          section={section}
        />
      )}
    </>
  );
}

export function NoteButton({
  noteProp,
  note,
  title,
  size,
  section,
}: {
  noteProp: string;
  note?: string;
  title?: string;
  size?: IconProps["size"];
  section: NoteSections;
}) {
  const { register, setValue } = useFormContext();

  useLayoutEffect(() => {
    setValue(noteProp, note ? note : "");
  }, [noteProp, note, setValue]);

  return (
    <>
      <input type="hidden" {...register(noteProp)} />
      <NoteButtonContent
        noteProp={noteProp}
        title={title}
        size={size}
        section={section}
      />
    </>
  );
}

export function Note({
  noteProp,
  title,
  note,
}: {
  noteProp: string;
  title?: string;
  note?: string;
}) {
  return (
    <FlexContainer style={{ justifyContent: "flex-end" }}>
      <FlexElement>
        <Label label={`${title ? title : "Note"}`} style={{ marginRight: 2 }} />
      </FlexElement>
      <FlexElement>
        <NoteButton
          noteProp={noteProp}
          title={title}
          note={note}
          section="PCS"
        />
      </FlexElement>
    </FlexContainer>
  );
}

export function Matrix({
  props,
  values,
  titles,
  maxWidth,
  min,
  max,
  revMarks,
  changeIndicators,
}: {
  props: string[][];
  values: (string | undefined)[][];
  titles: string[];
  maxWidth: number;
  min: number[][];
  max: number[][];
  revMarks?: string[];
  changeIndicators?: boolean[][];
}) {
  const verticalProps = props[0].map((_, colIndex) =>
    props.map((row) => row[colIndex])
  );
  const { register, formState, setValue, trigger } = useFormContext();
  const { disabled } = useContext(FieldContext);

  const container = useRef<HTMLDivElement | null>(null);

  function getValuesToInsert(clip: string) {
    const valueRows = clip.split(/\r?\n/);
    const valueCols = valueRows[0].split(/\t/);
    return valueCols
      .map((_, i) => valueRows.map((e) => e.split(/\t/)[i] ?? ""))
      .flat()
      .filter((e) => !!e);
  }

  useEffect(() => {
    const inputs =
      container.current && container.current.querySelectorAll("input");

    const handlePaste = (e: ClipboardEvent) => {
      if (inputs) {
        const clip = e.clipboardData
          ? e.clipboardData.getData("text").trim()
          : "";
        const valuesToInsert = getValuesToInsert(clip);
        if (valuesToInsert.length > 1) {
          e.preventDefault();
          inputs.forEach((el, i) => {
            // This only inserts / overwrites if there's a value there.
            valuesToInsert[i] &&
              setValue(el.name, valuesToInsert[i], { shouldDirty: true });
          });
          inputs[valuesToInsert.length - 1]?.focus();
          // Revalidate all.
          trigger();
        }
      }
    };

    inputs &&
      inputs.forEach((e) => {
        e.addEventListener("paste", handlePaste);
      });
    return () => {
      inputs &&
        inputs.forEach((e) => {
          e.removeEventListener("paste", handlePaste);
        });
    };
    // On init.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FormRow>
      <FlexContainer vertical>
        <FlexElement
          style={{
            alignSelf: "flex-start",
            paddingTop: 1,
            width: "calc(100% - 4px)",
          }}
        >
          <FlexContainer style={{ alignItems: "flex-end" }}>
            {revMarks && (
              <FlexElement>
                <RevCont />
              </FlexElement>
            )}
            <FlexElement>
              <Label label={titles.join(" / ")} />
            </FlexElement>
            <FlexElement style={{ marginLeft: "auto" }}>
              <div style={{ display: "flex", gap: 8, marginBottom: 3 }}>
                <Button
                  title="Copy Matrix"
                  variant="ghost_icon"
                  onClick={() => {
                    const inputs =
                      container.current &&
                      container.current.querySelectorAll("input");
                    if (inputs) {
                      const inputNames = Array.from(inputs).map((e) => e.name);
                      navigator.clipboard.writeText(
                        props
                          .map((row) =>
                            row
                              .map((cell) =>
                                inputNames.includes(cell)
                                  ? inputs[inputNames.indexOf(cell)].value
                                  : ""
                              )
                              .join("\t")
                          )
                          .join("\n")
                      );
                    }
                  }}
                >
                  <Icon data={copy} size={16}></Icon>
                </Button>
                {!disabled && (
                  <Button
                    title="Paste Matrix"
                    variant="ghost_icon"
                    onClick={() => {
                      navigator.clipboard.readText().then((clip) => {
                        const valuesToInsert = getValuesToInsert(clip.trim());
                        const inputs =
                          container.current &&
                          container.current.querySelectorAll("input");
                        inputs &&
                          inputs.forEach((el, i) => {
                            // This overwrites everything.
                            setValue(el.name, valuesToInsert[i] ?? "", {
                              shouldDirty: true,
                            });
                          });
                        // Revalidate all.
                        trigger();
                      });
                    }}
                  >
                    <Icon data={paste} size={16}></Icon>
                  </Button>
                )}
              </div>
            </FlexElement>
          </FlexContainer>
        </FlexElement>
        <FlexContainer style={{ flexWrap: "nowrap" }}>
          {revMarks && (
            <FlexContainer
              vertical
              style={{
                alignSelf: "stretch",
                alignContent: "flex-end",
              }}
            >
              {revMarks.map((revMark, i) => (
                <RevCont
                  key={i}
                  style={{
                    flexGrow: 1,
                    alignContent: "flex-end",
                    display: "flex",
                    alignItems: "flex-end",
                    marginBottom: 4,
                  }}
                >
                  <RevisionMark>{revMark}</RevisionMark>
                </RevCont>
              ))}
            </FlexContainer>
          )}
          <InpCont ref={container}>
            <FlexContainer
              style={{
                flexWrap: "nowrap",
                maxWidth,
                margin: "-4px 0 0 -4px",
              }}
            >
              {verticalProps.map((propCol, col) => (
                <FlexContainer vertical key={col}>
                  {propCol.map((prop, row) => (
                    <FlexElement style={{ margin: 4 }} key={row}>
                      <Tooltip
                        title={`${
                          changeIndicators && changeIndicators[row][col]
                            ? "Value changed since last revision."
                            : ""
                        }${
                          formState.errors.hasOwnProperty(prop)
                            ? `Value must be a number between ${min[row][col]} and ${max[row][col]}`
                            : ""
                        }`}
                        placement={row === 0 ? "top" : "bottom"}
                      >
                        <Input
                          defaultValue={values[row][col]}
                          style={{ minWidth: 55 }}
                          {...register(prop, {
                            validate: (v) => !isNaN(Number(v)),
                            min: min[row][col],
                            max: max[row][col],
                          })}
                          className={
                            changeIndicators && changeIndicators[row][col]
                              ? "value-changed"
                              : ""
                          }
                          variant={
                            formState.errors.hasOwnProperty(prop)
                              ? "error"
                              : undefined
                          }
                          disabled={disabled}
                          title={disabled ? values[row][col] : ""}
                        />
                      </Tooltip>
                    </FlexElement>
                  ))}
                </FlexContainer>
              ))}
            </FlexContainer>
          </InpCont>
        </FlexContainer>
      </FlexContainer>
    </FormRow>
  );
}

export function TextInputRow({
  label,
  value,
  setValue,
  width,
}: {
  label: string;
  value: string;
  setValue: React.Dispatch<string>;
  width?: number | string;
}) {
  const id = createId(label);
  return (
    <FormRow style={{ display: "block" }}>
      <TextField
        label={label}
        id={id}
        value={value}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          setValue(e.target.value)
        }
        style={{ width: width ? width : 300 }}
      />
    </FormRow>
  );
}

export function PagebreakFormElement({
  pagebreak,
  setPagebreak,
}: {
  pagebreak: Pagebreak;
  setPagebreak: React.Dispatch<Pagebreak>;
}) {
  return (
    <FormRowBlock>
      <Label label="Page Break" />
      <FlexFormContainer>
        <FlexElement>
          <Radio
            name="pagebreak"
            label="No"
            checked={pagebreak === "N"}
            onChange={() => setPagebreak("N")}
          />
        </FlexElement>
        <FlexElement>
          <Radio
            name="pagebreak"
            label="Yes"
            checked={pagebreak === "P"}
            onChange={() => setPagebreak("P")}
          />
        </FlexElement>
      </FlexFormContainer>
    </FormRowBlock>
  );
}

export function MultiselectFieldFromCommaSeparated({
  prop,
  value,
  title,
  options,
  status,
  error,
  noneSelectedValue,
  noneSelectedString,
  showTableHeader,
  additionalColumns,
  titleTitle,
  isRefetching,
  width,
  height,
  selectedFirst,
  disabled,
  disabledTooltip,
}: {
  prop: string;
  value: string;
  title: string;
  options?: {
    key: string;
    title: string | React.ReactFragment;
    [index: string]: string | React.ReactFragment;
  }[];
  status?: QueryStatus;
  error?: Error | null;
  noneSelectedValue?: string;
  noneSelectedString?: string;
  showTableHeader?: boolean;
  additionalColumns?: { key: string; title: string }[];
  titleTitle?: string;
  isRefetching?: boolean;
  width?: number;
  height?: number;
  selectedFirst?: boolean;
  disabled?: boolean;
  disabledTooltip?: boolean;
}) {
  const valueAsArray = useMemo(
    () => value.split(",").filter((e) => !!e),
    [value]
  );

  const { selectionDispatch, selection } = useTableSelect({
    selectionMode: "multi",
    selection: valueAsArray,
  });

  // This makes sure that the selection is always updated when the value changes as an extra precaution.
  // In case isRefetching is provided to this component, the selection will be updated – even to its original value –
  // after the field's data is refetched, thus providing an updated value even if data inconsistenciy or hidden update failure occur.
  useEffect(() => {
    if (isRefetching === false) {
      selectionDispatch({ type: "select", payload: valueAsArray });
    }
  }, [selectionDispatch, isRefetching, valueAsArray]);

  const [noneSelectedEngaged, setNoneSelectedEngaged] = useState(
    noneSelectedValue === value
  );

  const { register, setValue } = useFormContext();

  useEffect(() => {
    setValue(
      prop,
      !noneSelectedEngaged && selection.length === 0
        ? "-" // Some value is needed for the dirtyness to kick in. This couldn't be submitted anyway.
        : noneSelectedEngaged
        ? ""
        : selection.join(","),
      {
        // mark as dirty when the selection has changed
        // and even when just the "noneSelected" element is unclicked
        shouldDirty:
          value !== selection.join(",") ||
          (typeof noneSelectedValue !== "undefined" &&
            !noneSelectedEngaged &&
            selection.length === 0),
      }
    );
  }, [
    noneSelectedEngaged,
    noneSelectedValue,
    prop,
    selection,
    setValue,
    value,
  ]);

  const columns = useMemo(
    () => [
      {
        key: "title",
        title: titleTitle ?? "",
        width: "100%",
      },
      ...(additionalColumns ? additionalColumns : []),
    ],
    [additionalColumns, titleTitle]
  );

  return (
    <Tooltip
      title={
        disabled && disabledTooltip
          ? "Only one property can be changed at a time. Save changes to enable editing this field again."
          : ""
      }
      placement="top"
    >
      <div
        style={{
          width: width ?? 300,
          height: height ?? 300,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <>
          <div>
            <Label label={title} />
            <input type="hidden" {...register(prop)} />
          </div>
          <SmallTableContainer
            style={{
              minHeight: 0,
            }}
          >
            <Table
              density="compact"
              items={options || []}
              itemIdProp="key"
              status={status}
              error={error}
              columns={columns}
              selectionMode="multi"
              selection={selection}
              selectionDispatch={selectionDispatch}
              fullRowSelect={true}
              noFilteredItemMessage=""
              selectedFirst={selectedFirst ?? true}
              nonVirtual={true}
              disabledItems={
                noneSelectedEngaged ? options?.map((e) => e.key) : undefined
              }
              disableHeader={
                typeof noneSelectedValue !== "undefined" && !showTableHeader
              }
              disableSelectAll={true}
              disableAllItems={disabled || isRefetching}
              aboveHeaderRow={
                <EDSTable.Row>
                  <EDSTable.Cell
                    colSpan={
                      2 + (additionalColumns ? additionalColumns.length : 0)
                    }
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        width: "100%",
                      }}
                    >
                      <Checkbox
                        disabled={disabled || isRefetching}
                        checked={noneSelectedEngaged}
                        label={noneSelectedString}
                        onChange={() => {
                          if (noneSelectedEngaged) {
                            setNoneSelectedEngaged(false);
                          } else {
                            selectionDispatch({ type: "reset" });
                            setNoneSelectedEngaged(true);
                          }
                        }}
                      />
                    </div>
                  </EDSTable.Cell>
                </EDSTable.Row>
              }
            />
          </SmallTableContainer>
        </>
      </div>
    </Tooltip>
  );
}

export const CenterInput = styled(Field)`
  input {
    text-align: center;
  }
  // Hide adornment
  input + div {
    display: none;
  }
`;
