import AppDisplay from "AppDisplay";
import { AppOut } from "AppOut";
import { GlobalStyle } from "GlobalStyle";
import { StatusBar } from "StatusBar";
import { UserAccessGate } from "UserConfigContext";
import config from "config/config";
import React, { useEffect, useMemo, useState } from "react";
import { useLocation, withRouter } from "react-router-dom";
import { UpdateContext, useCheckForAppUpdate } from "useCheckForUpdate";

type DocumentTitleContextType = {
  setDocumentTitle: React.Dispatch<string>;
  setCategoryTitle: React.Dispatch<string>;
  pathname: string;
};

export const DocumentTitleContext = React.createContext(
  {} as DocumentTitleContextType
);

function App() {
  const location = useLocation();
  const { pathname } = location;
  const locationParts = useMemo(
    () => location.pathname.split("/"),
    [location.pathname]
  );
  const out = locationParts[1] === "out";

  const [documentTitle, setDocumentTitle] = useState<string>("");
  const [categoryTitle, setCategoryTitle] = useState<string>("");

  useEffect(() => {
    if (locationParts.filter((e) => e !== "").length === 0) {
      setDocumentTitle("");
      setCategoryTitle("");
    }
  }, [locationParts]);

  useEffect(() => {
    document.title = `${categoryTitle ? `${categoryTitle}: ` : ""}${
      documentTitle ? documentTitle + " - " : ""
    }${config.branding.appName} ${
      config.branding.customerName ? `for ${config.branding.customerName}` : ""
    } (${config.properties.env})`;
  }, [categoryTitle, documentTitle]);

  const { hasUpdateAvailable } = useCheckForAppUpdate();

  return (
    <div data-testid="App">
      <DocumentTitleContext.Provider
        value={{ setDocumentTitle, setCategoryTitle, pathname }}
      >
        <UpdateContext.Provider value={{ hasUpdateAvailable }}>
          <UserAccessGate>{out ? <AppOut /> : <AppDisplay />}</UserAccessGate>
          <StatusBar out={out} />
          <GlobalStyle />
        </UpdateContext.Provider>
      </DocumentTitleContext.Provider>
    </div>
  );
}

export default withRouter(App);
