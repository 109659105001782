import { Icon, Menu } from "@equinor/eds-core-react";
import {
  assignment,
  border_color,
  check_circle_outlined,
  code,
  copy,
  delete_forever,
  edit,
  external_link,
  file,
  file_copy,
  file_description,
  label,
  label_off,
  more_vertical,
  skip_next,
  view_list,
  visibility,
} from "@equinor/eds-icons";
import { useUserConfig } from "UserConfigContext";
import { getSheetApiUrl } from "queries/queryUtil";
import { CopyVDSQuery } from "features/sheets/queries/useCopyVDS";
import { NewSheetRevisions } from "features/sheets/queries/useNewSheetRevisions";
import { SetSheetStatusMultiple } from "features/sheets/queries/useSetSheetStatusMultiple";
import {
  SheetFilter,
  useSheetList,
} from "features/sheets/queries/useSheetList";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { setPaneMode, setPaneSheet } from "uiSlice";
import { useAppDispatch } from "../../app/hooks";
import { ContextMenu } from "../../components/ContextMenu";
import { PlantProps } from "../plant/types";
import { SheetProps } from "./Sheets";
import {
  editSheet,
  getEditSearchUrl,
  previewInNewWindow,
  previewInPane,
} from "./commands";
import {
  SheetTypes,
  nameProperties,
  sheetProperties,
} from "./config/sheetConfig";
import { SheetStatus } from "./config/statusConfig";
import { NewEDSOperation } from "./modals/eds/CreateEDS";
import { cannotBeRevised } from "./util";
import { IsConfirmed } from "components/confirm/useConfirm";
import { hasAccess } from "Access";
import { SetSheetStatus } from "./queries/useSetSheetStatus";
import { AttempSetVDSSubsegmentExpiredFunction } from "./modals/vds-subsegments/useAttemptSetVDSSubsegmentStatusExpired";

export default function ItemMenu({
  plant,
  item,
  sheetType,
  isCopyModal,
  deleteSingle,
  newSheetRevision,
  newSheetRevisions,
  copyVDS,
  setSheetStatus,
  setMultipleSheetStatus,
  sheetFilter,
  hasName,
  sheetTypeName,
  nameProperty,
  mainPlant,
  deleteSheets,
  isConfirmed,
  setWantToSetStatus,
  setSelectedSheet,
  setNewEDSOperation,
  setSourceEDSName,
  setSourceEDSRevision,
  setShowCopy,
  attempSetVDSSubsegmentExpired,
}: {
  plant: PlantProps;
  item: any;
  sheetType: SheetTypes;
  isCopyModal: boolean;
  deleteSingle: Function;
  newSheetRevision: Function;
  newSheetRevisions: NewSheetRevisions["mutate"];
  copyVDS: CopyVDSQuery["mutate"];
  setSheetStatus: SetSheetStatus["mutate"];
  setMultipleSheetStatus: SetSheetStatusMultiple["mutate"];
  sheetFilter: SheetFilter;
  hasName: boolean;
  sheetTypeName: string;
  nameProperty: string;
  mainPlant: PlantProps;
  deleteSheets: Function;
  isConfirmed: IsConfirmed;
  setWantToSetStatus: React.Dispatch<string>;
  setSelectedSheet: React.Dispatch<SheetProps>;
  setNewEDSOperation: React.Dispatch<NewEDSOperation>;
  setSourceEDSName: React.Dispatch<string>;
  setSourceEDSRevision: React.Dispatch<string>;
  setShowCopy: React.Dispatch<boolean>;
  attempSetVDSSubsegmentExpired: AttempSetVDSSubsegmentExpiredFunction;
}) {
  const { data } = useSheetList({
    plant,
    sheetType,
    sheetFilter,
    disabled: true,
  });

  const dispatch = useAppDispatch();
  const history = useHistory();
  const { previewInEdit, apiNoIndividualDatasheet } =
    sheetProperties[sheetType];

  const { caps, previewBaseUrl, userInfo } = useUserConfig();

  const previewInPaneMenuItem = previewInEdit ? (
    <></>
  ) : (
    <Menu.Item
      key="mi-previewinpane"
      onClick={() => {
        previewInPane({
          location: isCopyModal ? "copyModal" : "main",
          dispatch,
          plant,
          sheetType,
          item,
        });
      }}
    >
      <Icon size={16} data={visibility} />
      View in pane
    </Menu.Item>
  );

  const previewMenuItem = previewInEdit ? (
    <></>
  ) : (
    <Menu.Item
      key="mi-preview"
      onClick={() => {
        previewInNewWindow({ item, sheetType, previewBaseUrl, plant });
      }}
    >
      <Icon size={16} data={external_link} />
      View in new window
    </Menu.Item>
  );

  const viewJsonItem = (
    <Menu.Item
      key="mi-json"
      onClick={() =>
        window.open(
          getSheetApiUrl({
            plant,
            sheetType,
            name: item[nameProperty],
            revision: item.Revision,
          })
        )
      }
    >
      <Icon size={16} data={code} />
      View JSON
    </Menu.Item>
  );

  const validationLogMenuItem =
    sheetType === "pcs" ? (
      <Menu.Section>
        <Menu.Item
          onClick={() => {
            dispatch(
              setPaneSheet({
                location: isCopyModal ? "copyModal" : "main",
                sheet: {
                  plant,
                  sheetType,
                  name: item.PCS,
                  revision: item.Revision,
                },
              })
            );
            dispatch(
              setPaneMode({
                location: isCopyModal ? "copyModal" : "main",
                mode: "validation-log",
              })
            );
          }}
        >
          <Icon size={16} data={check_circle_outlined} />
          Validation log
        </Menu.Item>
      </Menu.Section>
    ) : null;

  const textBlockListMenuItem =
    sheetType === "vds" ? (
      <Menu.Section>
        <Menu.Item
          onClick={() => {
            dispatch(
              setPaneSheet({
                location: "main",
                sheet: {
                  sheetType,
                  name: item.VDS,
                  revision: item.Revision,
                },
              })
            );
            dispatch(
              setPaneMode({
                location: "main",
                mode: "text-block-list",
              })
            );
          }}
        >
          <Icon size={16} data={view_list} />
          Text Block List
        </Menu.Item>
      </Menu.Section>
    ) : null;

  const statusSetterItems = [];

  async function setStatus(status: SheetStatus) {
    const name = item[nameProperties[sheetType]];
    setWantToSetStatus(status);
    setSelectedSheet({
      name,
      revision: item.Revision,
    });
  }

  if (
    sheetType === "vds-textblocks" &&
    (item.Status === "W" || item.Status === "E")
  ) {
    statusSetterItems.push(
      <Menu.Item
        key="mi-status-o"
        onClick={async () => {
          (await isConfirmed(
            <>
              Are you sure you want to set {sheetProperties[sheetType].name}{" "}
              {item[nameProperty]} rev. {item.Revision} to Official?
            </>
          )) &&
            setMultipleSheetStatus({
              sheetType: "vds-textblocks",
              status: "O",
              sheets: [{ name: item[nameProperty], revision: item.Revision }],
            });
        }}
      >
        <Icon size={16} data={label} /> Set Official (O)
      </Menu.Item>
    );
  }

  if (
    sheetType === "vds" &&
    item.Status === "W" &&
    hasAccess({ caps, userInfo, cap: "sheets_vds_set_o_e" })
  ) {
    statusSetterItems.push(
      <Menu.Item
        key="mi-status-o"
        onClick={async () => {
          (await isConfirmed(
            <>
              Are you sure you want to set {sheetProperties[sheetType].name}{" "}
              {item[nameProperty]} rev. {item.Revision} to Official (O)?
            </>
          )) &&
            setMultipleSheetStatus({
              sheetType,
              status: "O",
              async: true,
              taskDescription: "Setting VDS to Official",
              postfix: "status-official",
              sheets: [{ name: item[nameProperty], revision: item.Revision }],
            });
        }}
      >
        <Icon size={16} data={label} /> Set Official (O)
      </Menu.Item>
    );
  }

  if (sheetType === "vds-subsegments" && item.Status === "W") {
    statusSetterItems.push(
      <Menu.Item
        key="mi-status-o"
        onClick={async () => {
          (await isConfirmed(
            <>
              Are you sure you want to set {sheetProperties[sheetType].name}{" "}
              {item[nameProperty]} rev. {item.Revision} to Official (O)?
            </>
          )) &&
            setMultipleSheetStatus({
              sheetType,
              status: "O",
              async: true,
              taskDescription: "Setting VDS Subsegment to Official",
              sheets: [{ name: item[nameProperty], revision: item.Revision }],
            });
        }}
      >
        <Icon size={16} data={label} /> Set Official (O)
      </Menu.Item>
    );
  }

  if (
    sheetType === "vds" &&
    item.Status === "O" &&
    hasAccess({ caps, userInfo, cap: "sheets_vds_set_o_e" })
  ) {
    statusSetterItems.push(
      <Menu.Item
        key="mi-status-o"
        onClick={async () => {
          (await isConfirmed(
            <>
              Are you sure you want to set {sheetProperties[sheetType].name}{" "}
              {item[nameProperty]} rev. {item.Revision} to Expired (E)?
            </>
          )) &&
            setMultipleSheetStatus({
              sheetType,
              status: "E",
              async: true,
              taskDescription: "Setting VDS to Expired",
              postfix: "status-expired",
              sheets: [{ name: item[nameProperty], revision: item.Revision }],
            });
        }}
      >
        <Icon size={16} data={label} /> Set Expired (E)
      </Menu.Item>
    );
  }

  if (["vds-textblocks"].includes(sheetType) && item.Status === "O") {
    statusSetterItems.push(
      <Menu.Item
        key="mi-status-e"
        onClick={async () => {
          (await isConfirmed(
            <>
              Are you sure you want to set {sheetProperties[sheetType].name}{" "}
              {item[nameProperty]} rev. {item.Revision} to Expired?
            </>
          )) &&
            setMultipleSheetStatus({
              sheetType: "vds-textblocks",
              status: "E",
              sheets: [{ name: item[nameProperty], revision: item.Revision }],
            });
        }}
      >
        <Icon size={16} data={label} /> Set Expired (E)
      </Menu.Item>
    );
  }

  if (sheetType === "vds-subsegments" && item.Status === "O") {
    statusSetterItems.push(
      <Menu.Item
        key="mi-status-e"
        onClick={async () => {
          if (
            await isConfirmed(
              <>
                Are you sure you want to set {sheetProperties[sheetType].name}{" "}
                {item[nameProperty]} rev. {item.Revision} to Expired?
              </>
            )
          ) {
            attempSetVDSSubsegmentExpired({
              subsegmentId: item[nameProperty],
              subsegmentRevision: item.Revision,
            });
          }
        }}
      >
        <Icon size={16} data={label} /> Set Expired (E)
      </Menu.Item>
    );
  }

  if (
    ["vsm", "sc", "eds"].includes(sheetType) &&
    item.Status === "W" &&
    (sheetType !== "eds" || caps.includes("sheets_eds_set_o_e"))
  ) {
    statusSetterItems.push(
      <Menu.Item key="mi-status-o" onClick={() => setStatus("O")}>
        <Icon size={16} data={label} /> Set Official (O)
      </Menu.Item>
    );
  }

  if (["general", "pcs"].includes(sheetType) && item.Status === "W") {
    statusSetterItems.push(
      <Menu.Item
        key="mi-status-i"
        onClick={() =>
          item.Revision.length > 1 ? setStatus("S") : setStatus("I")
        }
      >
        <Icon size={16} data={label} />
        {item.Revision.length > 1
          ? "Lock sub revision"
          : "Set Ready for Issue (I)"}
      </Menu.Item>
    );
  }

  if (
    ((["sc", "vsm", "eds"].includes(sheetType) && item.Status === "O") ||
      (sheetType === "pcs" && (item.Status === "O" || item.Status === "I"))) &&
    (sheetType !== "eds" || caps.includes("sheets_eds_set_o_e"))
  ) {
    statusSetterItems.push(
      <Menu.Item key="mi-status-e" onClick={() => setStatus("E")}>
        <Icon size={16} data={label_off} />
        Set Expired (E)
      </Menu.Item>
    );
  }

  const { pathname } = useLocation();

  if (isCopyModal) {
    return (
      <ContextMenu buttonContent={<Icon data={more_vertical}></Icon>}>
        {previewInPaneMenuItem}
        {previewMenuItem}
        {viewJsonItem}
        {validationLogMenuItem}
      </ContextMenu>
    );
  }

  const baseHref = document.getElementById("basehreftag")?.getAttribute("href");

  return (
    <ContextMenu buttonContent={<Icon data={more_vertical}></Icon>}>
      {sheetType !== "vds-unstructured" && (
        <Menu.Item
          key="mi-edit"
          disabled={
            !(
              item.Status === "W" ||
              (item.Status === "I" && sheetType === "general") ||
              previewInEdit
            )
          }
          onClick={() => {
            editSheet({
              history,
              sheetType,
              item,
            });
          }}
        >
          {item.Status !== "W" && previewInEdit ? (
            <>
              <Icon size={16} data={file} />
              View
            </>
          ) : (
            <>
              <Icon size={16} data={edit} />
              Edit
            </>
          )}
        </Menu.Item>
      )}
      {sheetType !== "vds-unstructured" && (
        <Menu.Item
          key="mi-edit-new"
          disabled={
            !(
              item.Status === "W" ||
              (item.Status === "I" && sheetType === "general") ||
              previewInEdit
            )
          }
          onClick={() => {
            window.open(
              "//" +
                window.location.host +
                baseHref +
                pathname.substring(1) +
                getEditSearchUrl({ item, sheetType }),
              "_blank"
            );
          }}
        >
          {item.Status !== "W" && previewInEdit ? (
            <>
              <Icon size={16} data={file_copy} />
              View in new window
            </>
          ) : (
            <>
              <Icon size={16} data={border_color} />
              Edit in new window
            </>
          )}
        </Menu.Item>
      )}
      {sheetType !== "vds-unstructured" && (
        <Menu.Item
          key="mi-properties"
          onClick={() => {
            editSheet({
              history,
              sheetType,
              item,
              tab: "properties",
            });
          }}
        >
          <Icon size={16} data={assignment} />
          Properties
        </Menu.Item>
      )}
      {sheetType === "eds" && (
        <Menu.Item
          key="mi-copy"
          disabled={item.Status === "R"}
          onClick={() => {
            setNewEDSOperation("Copy");
            setSourceEDSName(item.EDS);
            setSourceEDSRevision(item.Revision);
          }}
        >
          <Icon size={16} data={copy} />
          Copy
        </Menu.Item>
      )}
      {sheetType === "vds-textblocks" && (
        <Menu.Item
          key="mi-copy"
          disabled={item.Status === "R"}
          onClick={() => {
            setSelectedSheet({
              name: item.TextBlockID,
              revision: item.Revision,
            });
            setShowCopy(true);
          }}
        >
          <Icon size={16} data={copy} />
          Copy
        </Menu.Item>
      )}
      {sheetType === "vds-subsegments" && (
        <Menu.Item
          key="mi-copy"
          disabled={item.Status === "R"}
          onClick={() => {
            setSelectedSheet({
              name: item.SubsegmentID,
              revision: item.Revision,
            });
            setShowCopy(true);
          }}
        >
          <Icon size={16} data={copy} />
          Copy
        </Menu.Item>
      )}
      {sheetType !== "vds-unstructured" && (
        <Menu.Item
          key="mi-delete"
          disabled={item.Status !== "W"}
          onClick={() =>
            deleteSingle({
              sheetName: item[nameProperties[sheetType]],
              sheetRevision: item.Revision,
              hasName,
              sheetTypeName,
              nameProperty,
              mainPlant,
              deleteSheets,
              sheetType,
              isConfirmed,
            })
          }
        >
          <Icon size={16} data={delete_forever} />
          Delete
        </Menu.Item>
      )}
      {sheetType !== "vds-unstructured" && (
        <Menu.Item
          key="mi-newrev"
          disabled={cannotBeRevised(item, data && data.sheets, sheetType)}
          onClick={async () => {
            if (
              await isConfirmed(
                `Are you sure you want to create a new ${
                  sheetType === "pcs" && item.Status === "I" ? "sub " : ""
                } revision for ${sheetTypeName} ${item[nameProperty]}?`
              )
            ) {
              sheetType === "vds-textblocks" ||
              sheetType === "vds-subsegments" ||
              sheetType === "vds"
                ? newSheetRevisions({
                    plant,
                    sheetType,
                    sheets: [
                      {
                        [nameProperty]: item[nameProperty],
                        Revision: item.Revision,
                      },
                    ],
                    command: "revision",
                  })
                : newSheetRevision({
                    plant,
                    sheetType,
                    name: item[nameProperties[sheetType]],
                    revision: item.Revision,
                  });
            }
          }}
        >
          <Icon size={16} data={skip_next} />
          {sheetType === "pcs" && item.Status === "I"
            ? "New sub revision"
            : "New revision"}
        </Menu.Item>
      )}
      {sheetType === "vds" && (
        <Menu.Item
          disabled={item.Status !== "O" && item.Status !== "W"}
          onClick={() =>
            copyVDS({
              name: item.VDS,
              revision: item.Revision,
            })
          }
        >
          <Icon size={16} data={copy} />
          Copy
        </Menu.Item>
      )}
      {sheetType === "vds-textblocks" && (
        <Menu.Item
          disabled={item.Status !== "O"}
          onClick={() => {
            editSheet({
              history,
              sheetType,
              item,
              tab: "description",
            });
          }}
        >
          <Icon size={16} data={file_description} />
          Update VDS Description
        </Menu.Item>
      )}
      {statusSetterItems.length > 0 && (
        <Menu.Section>{statusSetterItems}</Menu.Section>
      )}
      {(!previewInEdit || !apiNoIndividualDatasheet) && (
        <Menu.Section>
          {!previewInEdit ? previewInPaneMenuItem : <></>}
          {!previewInEdit ? previewMenuItem : <></>}
          {!apiNoIndividualDatasheet ? viewJsonItem : <></>}
        </Menu.Section>
      )}
      {validationLogMenuItem}
      {textBlockListMenuItem}
    </ContextMenu>
  );
}
