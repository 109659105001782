import { SheetTypes } from "./config/sheetConfig";
import { createSlice } from "@reduxjs/toolkit";
import type { RootState } from "../../app/store";

interface SheetState {
  isCopyModalOpen: boolean;
  newRevision: string;
  mutationStatuses: any[];
  modalMutationStatuses: any[];
  sheetType: SheetTypes;
  pipeElementFilter: number;
  noteFilter: number;
  autoValidation: boolean;
  subsegmentRefFilter: number;
  subsegmentRefFilterRev: string;
}

const initialState: SheetState = {
  isCopyModalOpen: false,
  newRevision: "0",
  mutationStatuses: [],
  modalMutationStatuses: [],
  sheetType: "general",
  pipeElementFilter: 0,
  noteFilter: 0,
  autoValidation: false,
  subsegmentRefFilter: 0,
  subsegmentRefFilterRev: "",
};

export const sheetSlice = createSlice({
  name: "sheet",
  initialState,
  reducers: {
    showSheetCopyModal: (state) => {
      state.isCopyModalOpen = true;
    },
    hideSheetCopyModal: (state) => {
      state.isCopyModalOpen = false;
    },
    setNewRevision: (state, action) => {
      state.newRevision = action.payload;
    },
    setMutationStatuses: (state, action) => {
      state.mutationStatuses = action.payload;
    },
    setModalMutationStatuses: (state, action) => {
      state.modalMutationStatuses = action.payload;
    },
    setSheetType: (state, action) => {
      state.sheetType = action.payload;
    },
    setPipeElementFilter: (state, action) => {
      state.pipeElementFilter = action.payload;
    },
    setNoteFilter: (state, action) => {
      state.noteFilter = action.payload;
    },
    setAutoValidation: (state, action) => {
      state.autoValidation = action.payload;
    },
    setSubsegmentRefFilter: (state, action) => {
      state.subsegmentRefFilter = action.payload;
    },
    setSubsegmentRefFilterRev: (state, action) => {
      state.subsegmentRefFilterRev = action.payload;
    },
  },
});

export const {
  showSheetCopyModal,
  hideSheetCopyModal,
  setNewRevision,
  setMutationStatuses,
  setModalMutationStatuses,
  setSheetType,
  setPipeElementFilter,
  setNoteFilter,
  setAutoValidation,
  setSubsegmentRefFilter,
  setSubsegmentRefFilterRev,
} = sheetSlice.actions;

export const getSheetState = (state: RootState) => state.sheet;

export default sheetSlice.reducer;
