import {
  Autocomplete,
  Checkbox,
  Input,
  Label,
  NativeSelect,
  Radio,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from "@equinor/eds-core-react";
import {
  ItemAdminAreas,
  ItemAdminSaveProps,
} from "features/admin/itemAdminConfig";
import { ListAdminAreas, ListAdminProps } from "features/admin/listAdminConfig";
import { SheetTypeData } from "features/sheets/types";
import React, {
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Controller, useFormContext } from "react-hook-form";
import { QueryStatus } from "react-query";
import { createId } from "utils/util";
import { PreviewWithInputContainer } from "../../features/sheets/SheetPane";
import { FlexElement, LinearLoader, RevisionMark } from "../Components";
import {
  InpCont,
  Note,
  NoteCont,
  RevCont,
  SeriesContainer,
} from "./EditComponents";
import { FieldContext } from "./FieldContext";
import { FormOptionsContainer, FormRow } from "./Form";

export type OptionsWithIds = { id: string | number; option: string }[];

export type Features = "sheets" | "admin";

export function Field<
  T extends ItemAdminAreas | ListAdminAreas,
  F extends Features,
  S extends keyof SheetTypeData
>({
  prop,
  title = "",
  type,
  value,
  options,
  optionsWithIds,
  meta,
  revMark,
  note,
  noteProp,
  noteTitle,
  isLoading,
  isError,
  status,
  maxWidth,
  rows,
  rowsMax,
  doNotAddNonExisting,
  nonExistingText,
  afterInputContent,
  style,
  maxLength,
  min,
  max,
  disabled,
  required,
  validate,
  disableNotSetOption,
  notSetValue,
  valueAsNumber,
  onChange,
  className,
  autoFocus,
  notSetText,
  uppercase,
}: {
  feature?: F;
  sheetType?: S;
  area?: T;
  prop: F extends "sheets"
    ? keyof SheetTypeData[S]
    : T extends ItemAdminAreas
    ? keyof ItemAdminSaveProps<T, "put"> | keyof ItemAdminSaveProps<T, "post">
    : T extends ListAdminAreas
    ? keyof ListAdminProps<T>
    : string | string[];
  title?: string;
  type:
    | "text"
    | "option"
    | "series"
    | "matrix"
    | "checkbox"
    | "switch"
    | "radio"
    | "autocomplete"
    | "hidden";
  value: string | number | string[] | number[];
  options?: string[];
  optionsWithIds?: OptionsWithIds;
  meta?: string;
  revMark?: string;
  note?: string;
  noteProp?: string;
  noteTitle?: string;
  isLoading?: boolean;
  isError?: boolean;
  status?: QueryStatus;
  maxWidth?: number;
  rows?: number;
  rowsMax?: number;
  doNotAddNonExisting?: boolean;
  nonExistingText?: string;
  afterInputContent?: React.ReactFragment;
  style?: React.CSSProperties;
  maxLength?: number;
  min?: number;
  max?: number;
  disabled?: boolean;
  required?: boolean | string;
  validate?: { [index: string]: (arg0: string) => boolean | string };
  disableNotSetOption?: boolean;
  notSetValue?: string | number;
  valueAsNumber?: boolean;
  onChange?: ((event: any) => void) | undefined;
  className?: string;
  autoFocus?: boolean;
  notSetText?: string;
  uppercase?: boolean;
}) {
  const { register, formState, setValue, control } = useFormContext();
  required = required === true ? "Cannot be blank." : required;
  const { hasRev, disabled: contextDisabled } = useContext(FieldContext);
  const currentDisabled = contextDisabled || disabled;
  const displayOptions = useMemo(
    () =>
      options
        ? options.map((e) => ({ id: e, option: e }))
        : optionsWithIds
        ? optionsWithIds
        : [],
    [options, optionsWithIds]
  );

  // This makes sure that the Field is updated when the value changes.
  // The server-provided value will be shown in the Field after an update, even if the value differs from the input.
  useEffect(() => {
    setValue(
      String(prop),
      type === "checkbox" || type === "switch" ? value === "Y" : value
    );
  }, [prop, setValue, value, options, optionsWithIds, type]);

  const inputRef = useRef<HTMLInputElement>();

  const [cursorPosition, setCursorPosition] = useState(0);

  useLayoutEffect(() => {}, [cursorPosition]);

  return (
    (type === "hidden" && (
      <input type="hidden" {...register(String(prop))} />
    )) || (
      <FormRow style={style}>
        {hasRev && (
          <RevCont>
            {typeof revMark !== "undefined" && (
              <RevisionMark>{revMark}</RevisionMark>
            )}
          </RevCont>
        )}
        <InpCont>
          {isLoading || status === "loading" ? (
            <div style={{ minHeight: 52, maxWidth: 300 }}>
              <LinearLoader label="Loading..." />
            </div>
          ) : isError || status === "error" ? (
            <div
              style={{ minHeight: 52, display: "flex", alignItems: "center" }}
            >
              <Typography color="danger">Error loading {title}.</Typography>
            </div>
          ) : (
            <>
              <PreviewWithInputContainer>
                {(typeof value === "string" || typeof value === "number") &&
                typeof prop === "string"
                  ? (type === "text" && (
                      <div
                        style={{
                          maxWidth: maxWidth ? maxWidth : 500,
                          flexGrow: 1,
                        }}
                      >
                        <Tooltip
                          title={
                            prop && formState.errors && formState.errors[prop]
                              ? String(formState.errors[prop]!.message)
                              : ""
                          }
                        >
                          <TextField
                            label={title}
                            meta={meta}
                            id={createId(title)}
                            defaultValue={value}
                            multiline={!!rows}
                            rows={rows}
                            rowsMax={rowsMax}
                            disabled={currentDisabled}
                            className={className}
                            maxLength={maxLength}
                            autoFocus={autoFocus}
                            inputRef={inputRef}
                            variant={
                              formState.errors.hasOwnProperty(prop)
                                ? "error"
                                : undefined
                            }
                            onKeyDown={(
                              e: React.KeyboardEvent<HTMLInputElement>
                            ) => {
                              if (uppercase) {
                                setCursorPosition(getCursorPosition(e));
                              }
                            }}
                            {...register(prop, {
                              ...(maxLength
                                ? {
                                    maxLength: {
                                      value: maxLength,
                                      message: `Maximum length is ${maxLength} characters.`,
                                    },
                                    max,
                                    min,
                                  }
                                : {}),
                              ...(validate ? { validate } : {}),
                              ...(required ? { required } : {}),
                              ...(valueAsNumber ? { valueAsNumber } : {}),
                              ...(onChange
                                ? { onChange }
                                : {
                                    onChange: (e) => {
                                      if (uppercase) {
                                        inputRef.current?.setSelectionRange(
                                          cursorPosition,
                                          cursorPosition
                                        );
                                        inputRef.current?.focus();
                                        setValue(
                                          String(prop),
                                          e.target.value.toUpperCase()
                                        );
                                      }
                                    },
                                  }),
                            })}
                          />
                        </Tooltip>
                      </div>
                    )) ||
                    (type === "option" && (
                      <div
                        style={{
                          maxWidth: maxWidth ? maxWidth : 300,
                          flexGrow: 1,
                        }}
                      >
                        <Tooltip
                          title={
                            prop && formState.errors && formState.errors[prop]
                              ? String(formState.errors[prop]!.message)
                              : ""
                          }
                        >
                          <NativeSelect
                            defaultValue={String(value)}
                            id={prop}
                            label={title}
                            disabled={currentDisabled}
                            {...register(prop, {
                              ...(validate ? { validate } : {}),
                              ...(required ? { required } : {}),
                              ...(valueAsNumber ? { valueAsNumber } : {}),
                              ...(onChange ? { onChange } : {}),
                            })}
                            className={
                              prop && formState.errors && formState.errors[prop]
                                ? "inputError"
                                : ""
                            }
                          >
                            {(options || optionsWithIds) &&
                              !disableNotSetOption && (
                                <option
                                  key=""
                                  value={
                                    notSetValue !== undefined ? notSetValue : ""
                                  }
                                >
                                  {notSetText ?? "- Not set -"}
                                </option>
                              )}
                            {(options || optionsWithIds || value) &&
                              !doNotAddNonExisting &&
                              value &&
                              value !== notSetValue &&
                              !displayOptions
                                .map((e) => String(e.id))
                                .includes(String(value)) && (
                                <option key={value} value={value}>
                                  {value}
                                  {nonExistingText === undefined
                                    ? " (missing item)"
                                    : nonExistingText === ""
                                    ? ""
                                    : ` (${nonExistingText})`}
                                </option>
                              )}
                            {displayOptions.map((option) => (
                              <option
                                key={String(option.id)}
                                value={String(option.id)}
                              >
                                {option.option}
                              </option>
                            ))}
                          </NativeSelect>
                        </Tooltip>
                      </div>
                    )) ||
                    (type === "checkbox" && (
                      <Checkbox
                        id={prop}
                        label={title}
                        defaultChecked={value === "Y"}
                        disabled={currentDisabled}
                        {...register(prop, {
                          ...(required ? { required } : {}),
                        })}
                      />
                    )) ||
                    (type === "switch" && (
                      <Switch
                        id={prop}
                        label={title}
                        defaultChecked={value === "Y"}
                        disabled={currentDisabled}
                        {...register(prop)}
                      />
                    )) ||
                    (type === "radio" && optionsWithIds && (
                      <div>
                        <Label label={title} />
                        <FormOptionsContainer alignLeft>
                          {optionsWithIds.map((optionWithId) => (
                            <FlexElement key={optionWithId.id}>
                              <Radio
                                label={optionWithId.option}
                                defaultChecked={value === optionWithId.id}
                                value={optionWithId.id}
                                disabled={currentDisabled}
                                {...register(prop)}
                              />
                            </FlexElement>
                          ))}
                        </FormOptionsContainer>
                      </div>
                    )) ||
                    (type === "autocomplete" && (
                      <div
                        style={{
                          maxWidth: maxWidth ? maxWidth : "none",
                          flexGrow: 1,
                        }}
                      >
                        <Controller
                          control={control}
                          name={prop}
                          // @ts-ignore
                          defaultValue={value}
                          rules={{
                            ...(maxLength
                              ? {
                                  maxLength: {
                                    value: maxLength,
                                    message: `Maximum length is ${maxLength} characters.`,
                                  },
                                  max,
                                  min,
                                }
                              : {}),
                            ...(validate ? { validate } : {}),
                            ...(required ? { required } : {}),
                            ...(valueAsNumber ? { valueAsNumber } : {}),
                            ...(onChange ? { onChange } : {}),
                          }}
                          render={({ field: { onChange, onBlur, value } }) => {
                            return (
                              <Tooltip
                                title={
                                  prop &&
                                  formState.errors &&
                                  formState.errors[prop]
                                    ? String(formState.errors[prop]!.message)
                                    : ""
                                }
                              >
                                <Autocomplete
                                  onBlur={onBlur}
                                  onOptionsChange={(e) => {
                                    onChange(e.selectedItems[0]);
                                  }}
                                  onInput={(
                                    e: React.ChangeEvent<HTMLInputElement>
                                  ) => {
                                    onChange(e.target.value);
                                  }}
                                  selectedOptions={[value]}
                                  title={value}
                                  label={title}
                                  options={options ? options : []}
                                  hideClearButton={true}
                                  // @ts-ignore
                                  multiline
                                  style={{ flexGrow: 1 }}
                                  disabled={currentDisabled}
                                  className={
                                    prop &&
                                    formState.errors &&
                                    formState.errors[prop]
                                      ? "inputError"
                                      : ""
                                  }
                                  autoWidth={true}
                                  clearSearchOnChange={false}
                                />
                              </Tooltip>
                            );
                          }}
                        />
                      </div>
                    ))
                  : type === "series" &&
                    Array.isArray(value) &&
                    Array.isArray(prop) && (
                      <div
                        style={{
                          maxWidth: maxWidth ? maxWidth : "100%",
                          flexGrow: 1,
                        }}
                      >
                        <Label label={title} />
                        <SeriesContainer>
                          {prop.map((p, i) => {
                            return (
                              <FlexElement key={p}>
                                <Input
                                  defaultValue={value[i]}
                                  disabled={currentDisabled}
                                  {...register(p, {
                                    ...(required ? { required } : {}),
                                    ...(validate ? { validate } : {}),
                                    ...(valueAsNumber ? { valueAsNumber } : {}),
                                    ...(onChange ? { onChange } : {}),
                                  })}
                                />
                              </FlexElement>
                            );
                          })}
                        </SeriesContainer>
                      </div>
                    )}
                {afterInputContent}
              </PreviewWithInputContainer>
            </>
          )}
        </InpCont>
        {typeof note !== "undefined" && typeof prop === "string" && (
          <NoteCont>
            <Note
              noteProp={noteProp ? noteProp : "NoteID" + prop}
              title={noteTitle ? noteTitle : title + " Note"}
              note={note}
            />
          </NoteCont>
        )}
      </FormRow>
    )
  );
}

const getCursorPosition = (e: React.KeyboardEvent<HTMLInputElement>) => {
  const { key, currentTarget, shiftKey, ctrlKey } = e;

  if (
    ((shiftKey || ctrlKey) && key === "Backspace") ||
    (ctrlKey && (key.toLowerCase() === "z" || key.toLowerCase())) === "y"
  ) {
    // return 0 as cursorPosition if (shift or ctrl) + backspace is pressed
    // or its undo or redo
    return 0;
  }

  let cursorPosition = currentTarget.selectionStart ?? 0;
  if (key === "Backspace") {
    cursorPosition--;
  } else {
    cursorPosition++;
  }

  return cursorPosition;
};
