import { Button, Icon } from "@equinor/eds-core-react";
import { check } from "@equinor/eds-icons";
import {
  FlexColumnContainer,
  FlexContainer,
  FlexElement,
} from "components/Components";
import { MessageModal } from "components/MessageModal";
import {
  ModalSideMargin,
  ModalWindow,
  ModalWindowButtonContainer,
  ModalWindowContentDefault,
} from "components/ModalWindow";
import StatusModal from "components/StatusModal";
import useConfirm from "components/confirm/useConfirm";
import { MultiselectFieldFromCommaSeparated } from "components/form/EditComponents";
import { Field } from "components/form/Field";
import { FieldContext } from "components/form/FieldContext";
import {
  Fieldset,
  FieldsetContainer,
  FormRowBlock,
} from "components/form/Form";
import { combineQueryStatuses } from "queries/queryUtil";
import { useCodelist } from "queries/useCodelist";
import useSaveVDSProperties from "features/sheets/queries/useSaveVDSProperties";
import useSheet, { VDSProperties } from "features/sheets/queries/useSheet";
import { useEffect, useMemo, useReducer, useState } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { confirmNavigation } from "utils/confirmNavigation";
import { successMessage } from "utils/successMessage";
import { Nullable } from "utils/types";
import { closeEditSheet } from "../../commands";
import { sheetProperties } from "../../config/sheetConfig";
import { FormVDSProperties, SaveVDSProperties } from "../../types";
import { QueryStatusAction, statusesReducer } from "../VDSCommonProperties";

export function VDSCharacteristics({
  data,
  statusesDispatch,
  isRefetching,
}: {
  data: VDSProperties;
  statusesDispatch: React.Dispatch<QueryStatusAction>;
  isRefetching: boolean;
}) {
  const {
    data: vdsCharacteristicsData,
    status,
    error,
  } = useCodelist({
    codelist: "vds-characteristics",
  });

  useEffect(() => {
    statusesDispatch({
      type: "set",
      payload: { "vds-characteristics": status },
    });
  }, [status, statusesDispatch]);

  const value = data.getVDSCharSum.map((e) => e.CharacteristicID).join();

  const vdsCharacteristicsOptions = useMemo(
    () =>
      vdsCharacteristicsData?.map((e) => ({
        key: String(e.CharacteristicID),
        title: e.Description,
        inherited: data.getVDSCharInherited
          .map((i) => i.CharacteristicID)
          .includes(e.CharacteristicID) ? (
          <Icon data={check} size={16} />
        ) : (
          ""
        ),
      })),
    [data.getVDSCharInherited, vdsCharacteristicsData]
  );

  return (
    <MultiselectFieldFromCommaSeparated
      options={vdsCharacteristicsOptions}
      prop="VDSCharacteristicProperty"
      title="VDS Characteristics"
      value={value}
      noneSelectedString="None"
      noneSelectedValue=""
      status={status}
      error={error}
      additionalColumns={[{ key: "inherited", title: "Inherited" }]}
      showTableHeader={true}
      titleTitle="Characteristics"
      isRefetching={isRefetching}
      selectedFirst={false}
    />
  );
}

export function VDSPropertiesFields({
  data,
  statusesDispatch,
  isRefetching,
}: {
  data: VDSProperties;
  statusesDispatch: React.Dispatch<QueryStatusAction>;
  isRefetching: boolean;
}) {
  const { data: specialRequirements, status: specialRequirementsStatus } =
    useCodelist({ codelist: "special-requirements" });
  const { data: endConnections1, status: endConnection1Status } = useCodelist({
    codelist: "vds-end-connections",
    extraParams: { EndConnection: "1" },
  });
  const { data: endConnections2, status: endConnection2Status } = useCodelist({
    codelist: "vds-end-connections",
    extraParams: { EndConnection: "2" },
  });

  const specialRequiremenetsOptions = useMemo(
    () =>
      specialRequirements?.map((e) => ({
        id: String(e.SpecialRequirementID),
        option: e.Description,
      })),
    [specialRequirements]
  );

  const endConnection1Options = useMemo(
    () => endConnections1?.map((e) => e.EndConnection),
    [endConnections1]
  );

  const endConnection2Options = useMemo(
    () => endConnections2?.map((e) => e.EndConnection),
    [endConnections2]
  );

  useEffect(() => {
    statusesDispatch({
      type: "set",
      payload: {
        "special-requirements": specialRequirementsStatus,
        endConnections1: endConnection1Status,
        endConnections2: endConnection2Status,
      },
    });
  }, [
    endConnection1Status,
    endConnection2Status,
    specialRequirementsStatus,
    statusesDispatch,
  ]);

  return (
    <>
      <FlexColumnContainer style={{ marginBottom: 24 }} gap={70}>
        <FlexElement>
          <VDSCharacteristics
            data={data}
            statusesDispatch={statusesDispatch}
            isRefetching={isRefetching}
          />
        </FlexElement>
        <FlexElement>
          <Field
            type="checkbox"
            prop="SubsegmentFilter"
            title="Use Subsegment Filter"
            value={data.getProperties[0].SubsegmentFilter}
            style={{ margin: "1.5em 0" }}
          />
          <Field
            type="option"
            prop="SpecialReqID"
            title="Special Requirement"
            value={String(data.getProperties[0].SpecialReqID)}
            optionsWithIds={specialRequiremenetsOptions}
            style={{ margin: "1.25em 0" }}
            notSetValue="0"
          />
          <Field
            type="option"
            prop="EndConnection1"
            title="End Connection 1"
            value={data.getProperties[0].EndConnection1}
            options={endConnection1Options}
            style={{ margin: "1.25em 0" }}
          />
          <Field
            type="option"
            prop="EndConnection2"
            title="End Connection 2"
            value={data.getProperties[0].EndConnection2}
            options={endConnection2Options}
            style={{ margin: "1.25em 0" }}
          />
        </FlexElement>
      </FlexColumnContainer>
      <FlexContainer>
        <FlexElement style={{ flex: 1 }}>
          <Field
            type="text"
            prop="Notepad"
            title="Notes"
            value={data.getProperties[0].Notepad}
            rows={8}
            maxWidth={700}
          />
        </FlexElement>
      </FlexContainer>
    </>
  );
}

export function VDSPropertiesModal({
  name,
  revision,
  tab,
}: {
  name: string;
  revision: string;
  tab: string;
}) {
  const history = useHistory();
  const open = useMemo(
    () => !!name && !!revision && tab === "properties",
    [name, revision, tab]
  );

  const [showAlertModal, setShowAlertModal] = useState(false);

  const { data, status, error, isRefetching, isRefetchError, refetch } =
    useSheet({
      sheetType: "vds",
      name,
      revision,
      postfix: "properties",
    });

  const {
    mutate: saveVDSProperties,
    status: saveVDSPropertiesStatus,
    error: saveVDSPropertiesError,
    data: saveVDSPropertiesData,
    reset: saveVDSPropertiesReset,
  } = useSaveVDSProperties();

  const processData = (
    formData: FormVDSProperties,
    data: VDSProperties
  ): SaveVDSProperties => ({
    ...formData,
    IncludeCharProperty: formData.VDSCharacteristicProperty.split(",")
      .filter(
        (c) =>
          !data.getVDSCharInherited
            .map((i) => String(i.CharacteristicID))
            .includes(c)
      )
      .join(","),
    ExcludeCharProperty: data.getVDSCharInherited
      .map((i) => String(i.CharacteristicID))
      .filter((i) => !formData.VDSCharacteristicProperty.split(",").includes(i))
      .join(","),
    SubsegmentFilter: formData.SubsegmentFilter ? "Y" : "N",
  });

  const onSubmit: SubmitHandler<Nullable<FormVDSProperties>> = (formData) => {
    if (data) {
      if (
        Object.keys(formData)
          .map((e) => formData[e as keyof typeof formData])
          .includes("-")
      ) {
        setShowAlertModal(true);
      } else {
        saveVDSProperties({
          name,
          revision,
          content: processData(formData as FormVDSProperties, data),
        });
      }
    }
  };

  const methods = useForm<FormVDSProperties>({
    shouldUnregister: true,
    mode: "all",
  });
  const { reset, formState, handleSubmit } = methods;
  const { isDirty } = formState;

  const { isConfirmed } = useConfirm();

  const closeModal = async () => {
    (!isDirty || (await confirmNavigation({ isConfirmed }))) &&
      closeEditSheet({ history });
  };

  const [statuses, statusesDispatch] = useReducer(statusesReducer, {});

  const combinedStatuses = useMemo(
    () => combineQueryStatuses(...Object.values(statuses)),
    [statuses]
  );

  useEffect(() => {
    combinedStatuses === "success" &&
      !isRefetching &&
      !isRefetchError &&
      reset(undefined, { keepDirty: false, keepValues: true });
  }, [isRefetching, isRefetchError, data, reset, combinedStatuses]);

  return (
    <>
      <MessageModal
        isOpen={showAlertModal}
        onClose={() => setShowAlertModal(false)}
        title="Modify selection"
        shouldCloseOnOverlayClick={true}
      >
        Either All/None or at least one element should be selected.
      </MessageModal>
      <ModalWindow
        isOpen={open}
        closeModal={closeModal}
        title={`Properties for ${sheetProperties["vds"].name} ${name}`}
        layer="default"
        status={combinedStatuses || status}
        error={error}
        refetch={refetch}
      >
        {data && (
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <FieldContext.Provider value={{}}>
                <ModalSideMargin>
                  <ModalWindowContentDefault>
                    <FieldsetContainer noBottomMargin>
                      <Fieldset>
                        <FormRowBlock style={{ marginBottom: 0 }}>
                          <VDSPropertiesFields
                            data={data}
                            statusesDispatch={statusesDispatch}
                            isRefetching={isRefetching}
                          />
                        </FormRowBlock>
                      </Fieldset>
                    </FieldsetContainer>
                  </ModalWindowContentDefault>
                </ModalSideMargin>
                <ModalWindowButtonContainer>
                  <Button
                    type="submit"
                    disabled={combinedStatuses !== "success" || isRefetching}
                  >
                    Save
                  </Button>
                  <Button variant="outlined" onClick={closeModal}>
                    Close
                  </Button>
                </ModalWindowButtonContainer>
              </FieldContext.Provider>
            </form>
          </FormProvider>
        )}
      </ModalWindow>
      <StatusModal
        status={saveVDSPropertiesStatus}
        error={saveVDSPropertiesError}
        onSettledClose={saveVDSPropertiesReset}
        successMessage={successMessage(saveVDSPropertiesData)}
      />
    </>
  );
}
