import StatusModal from "components/StatusModal";
import { useAffectedVDSByReplacement } from "features/sheets/queries/useAffectedVDSByReplacement";
import { AffectedVDSByReplacementItem } from "features/sheets/queries/useAffectedVDSByReplacementQuery";
import { SetSheetStatus } from "features/sheets/queries/useSetSheetStatus";
import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { AffectedProgress, AffectedVDS } from "./AffectedVDS";

export type AttempSetVDSSubsegmentExpiredFunction = (args: {
  subsegmentId: number;
  subsegmentRevision: string;
}) => void;

export function useAttemptSetVDSSubsegmentStatusExpired({
  setSheetStatusQuery,
}: {
  setSheetStatusQuery: SetSheetStatus;
}): {
  attempSetVDSSubsegmentExpired: AttempSetVDSSubsegmentExpiredFunction;
  attemptSetVDSSubsegmentStatusExpiredContent: React.ReactFragment;
  affectedProgress: AffectedProgress;
} {
  const [subsegmentId, setSubsegmentId] = useState<number | undefined>(
    undefined
  );
  const [subsegmentRevision, setSubsegmentRevision] = useState<
    string | undefined
  >(undefined);
  const [showAffectedVDSModal, setShowAffectedVDSModal] = useState(false);

  const [affectedProgress, setAffectedProgress] =
    useState<AffectedProgress>("idle");

  const {
    mutate: affectedVDSByReplacementMutate,
    status: affectedVDSByReplacementStatus,
    error: affectedVDSByReplacementError,
    data: affectedVDSByReplacementData,
    reset: affectedVDSByReplacementReset,
  } = useAffectedVDSByReplacement();

  const affectedVDSList = useRef([] as AffectedVDSByReplacementItem[]);

  const { mutate: setSheetStatusMutate, status: setSheetStatusStatus } =
    setSheetStatusQuery;

  const attempSetVDSSubsegmentExpired = useCallback(
    ({
      subsegmentId: attemptSubsegmentId,
      subsegmentRevision: attemptSubsegmentRevision,
    }: {
      subsegmentId: number;
      subsegmentRevision: string;
    }) => {
      if (attemptSubsegmentId && attemptSubsegmentRevision) {
        setSubsegmentId(attemptSubsegmentId);
        setSubsegmentRevision(attemptSubsegmentRevision);
        setAffectedProgress("processing");
        affectedVDSByReplacementMutate({
          subsegmentId: attemptSubsegmentId,
          subsegmentRevision: attemptSubsegmentRevision,
        });
      }
    },
    [affectedVDSByReplacementMutate]
  );

  useLayoutEffect(() => {
    if (
      affectedVDSByReplacementStatus === "success" &&
      subsegmentId &&
      subsegmentRevision
    ) {
      if (affectedVDSByReplacementData.data.getVDSAffected.length === 0) {
        setSheetStatusMutate({
          name: String(subsegmentId),
          revision: subsegmentRevision,
          status: "E",
          sheetType: "vds-subsegments",
        });
      } else {
        setAffectedProgress("waiting-for-user");
        setShowAffectedVDSModal(true);
        affectedVDSList.current =
          affectedVDSByReplacementData.data.getVDSAffected;
      }
    }
  }, [
    affectedVDSByReplacementData,
    affectedVDSByReplacementStatus,
    setSheetStatusMutate,
    subsegmentId,
    subsegmentRevision,
  ]);

  useEffect(() => {
    if (
      affectedProgress === "ready-to-save" &&
      subsegmentId &&
      subsegmentRevision
    ) {
      affectedVDSByReplacementMutate({
        subsegmentId,
        subsegmentRevision,
      });
    }
  }, [
    affectedProgress,
    subsegmentId,
    subsegmentRevision,
    affectedVDSByReplacementMutate,
  ]);

  useEffect(() => {
    if (setSheetStatusStatus === "success") {
      setAffectedProgress("done");
    }
  }, [setSheetStatusStatus]);

  const onErrorClose = useCallback(() => {
    affectedVDSByReplacementReset();
    setAffectedProgress("idle");
  }, [affectedVDSByReplacementReset]);

  const attemptSetVDSSubsegmentStatusExpiredContent = (
    <>
      {showAffectedVDSModal && subsegmentId && subsegmentRevision && (
        <AffectedVDS
          name={String(subsegmentId)}
          revision={subsegmentRevision}
          changeType="expiry"
          affectedVDS={affectedVDSList.current}
          setShowAffectedVDSModal={setShowAffectedVDSModal}
          setAffectedProgress={setAffectedProgress}
        />
      )}
      <StatusModal
        status={affectedVDSByReplacementStatus}
        error={affectedVDSByReplacementError}
        onSuccess={affectedVDSByReplacementReset}
        onErrorClose={onErrorClose}
      />
    </>
  );
  return {
    attempSetVDSSubsegmentExpired,
    attemptSetVDSSubsegmentStatusExpiredContent,
    affectedProgress,
  };
}
