import axios from "axios";
import { SheetStatus } from "../config/statusConfig";
import { useQuery } from "react-query";
import { getSheetApiUrl } from "queries/queryUtil";

export type SubsegmentSubStitutionForExpirationItem = {
  SubSegmentID: number;
  Revision: string;
  Status: SheetStatus;
  Description: string;
};

export function useVDSSubsegmentSubstitutionForExpiration({
  name,
  revision,
  disabled,
}: {
  name: string;
  revision: string;
  disabled?: boolean;
}) {
  return useQuery<SubsegmentSubStitutionForExpirationItem[], Error>(
    ["vds-subsegment-list", name, revision],
    async () => {
      const { data } = await axios.get<{
        getSubsegmentExpiryReplacement: SubsegmentSubStitutionForExpirationItem[];
      }>(
        getSheetApiUrl({
          sheetType: "vds-subsegments",
          name,
          revision,
          postfix: `subsegment-substitution-for-expiration`,
        })
      );
      return data.getSubsegmentExpiryReplacement;
    },
    {
      enabled: !disabled,
    }
  );
}
