import axios from "axios";
import { SheetTypeData, VDSSubsegment } from "features/sheets/types";
import { useQuery, useQueryClient } from "react-query";
import { PlantProps } from "../../plant/types";
import {
  SheetTypes,
  plantDependentSheets,
  sheetProperties,
} from "../config/sheetConfig";
import { TypedSheetTypes } from "../types";
import { getSheetApiUrl } from "../../../queries/queryUtil";

export type ValidationStatus = "W" | "S" | "I";

export interface ValLogDetail {
  Sequence: number;
  StepNo: number;
  LineNo: number;
  Description: string;
  Note: string;
  Approved: "Y" | "N";
  Status: ValidationStatus;
}

export interface ValLogStep {
  StepNo: number;
  Description: string;
  Status: "0" | "1";
}

interface ValidationCheck {
  RevValidationCheck: {
    ValidationCheckID: number;
    Description: string;
    Status: "1" | "0";
  }[];
}

type VDSTextBlockVSK = {
  getVDSTextBlockVSK: { VSK: string }[];
};

type VDSTextBlockMDS = {
  getVDSTextBlockMDS: { MDS: string }[];
};

export type VDSSubsegmentInVDS = {
  GroupID: number;
  SubsegmentID: number;
  Revision: string;
  SubsegmentName: string;
  Sequence: number;
};

export type VDS = {
  getVDSContent: {
    ValveTypeID: number;
    RatingClassID: number;
    MaterialTypeID: number;
    EndConnectionID: number;
    FullReducedBoreIndicator: "N" | "Y";
    BoreID: number;
    VDSSizeID: number;
    HousingDesignIndicator: "N" | "Y";
    HousingDesignID: number;
    SpecialReqID: number;
    MinOperatingTemperature: number;
    MaxOperatingTemperature: number;
    VDSDescription: string;
    Notepad: string;
    RevDate: string;
    LastUpdate: string;
    LastUpdateBy: string;
  }[];
  getVDSSubsegment: VDSSubsegmentInVDS[];
};

export type VDSProperties = {
  getProperties: [
    {
      Notepad: string;
      SubsegmentFilter: "Y" | "N";
      EndConnection1: string;
      EndConnection2: string;
      SpecialReqID: number;
      EnableSpecialReq: "Y" | "N";
    }
  ];
  getVDSCharInherited: {
    CharacteristicID: number;
    Description: string;
  }[];
  getVDSCharIncludeExclude: {
    CharacteristicID: number;
    IncludeExcludeIndicator: "I" | "E";
  }[];
  getVDSCharSum: {
    CharacteristicID: 3;
    Description: "Cryogenic service";
  }[];
};

type Postfixes =
  | "validation-logs/steps"
  | "validation-logs/details"
  | "vsk"
  | "mds"
  | string;

type ReturnType<T, S> = S extends "pcs"
  ? T extends "validation-logs/steps"
    ? {
        getValLogSteps: ValLogStep[];
        ApprovalDate: string;
        Approver: string;
        CaseHandler: string;
        LastUpdate: string;
        LockedBy: string;
        LockedDate: string;
        Status: string;
        ValidationDate: string;
      }
    : T extends "validation-logs/details"
    ? { getValLogDetails: ValLogDetail[] }
    : T extends "main-revision-validation-check"
    ? ValidationCheck
    : T extends "sub-revision-validation-check"
    ? ValidationCheck
    : any
  : S extends "general"
  ? SheetTypeData[S]
  : S extends TypedSheetTypes
  ? T extends "vsk"
    ? VDSTextBlockVSK
    : T extends "mds"
    ? VDSTextBlockMDS
    : SheetTypeData[S]
  : S extends "vds"
  ? T extends "properties"
    ? VDSProperties
    : VDS
  : S extends "vds-subsegment"
  ? VDSSubsegment
  : S extends "vds-textblocks"
  ? T extends "vsk"
    ? VDSTextBlockVSK
    : T extends "mds"
    ? VDSTextBlockMDS
    : SheetTypeData[S]
  : unknown;

export default function useSheet<T extends Postfixes, S extends SheetTypes>({
  plant,
  sheetType,
  name,
  revision,
  prepostfix,
  postfix,
  cacheTime,
  prefix,
  disabled,
}: {
  sheetType: S;
  name: string | undefined;
  revision: string | undefined;
  plant?: PlantProps;
  prepostfix?: string;
  postfix?: T;
  cacheTime?: number;
  prefix?: string;
  disabled?: boolean;
}) {
  const postfixKey = postfix ? ((prepostfix ?? "") + postfix).split("/") : [];
  const queryClient = useQueryClient();
  return useQuery<ReturnType<T, S>, Error>(
    [
      prefix ? prefix : "sheet",
      ...(plantDependentSheets.includes(sheetType) && plant
        ? [plant.PlantID]
        : []),
      sheetType,
      name,
      revision,
      ...postfixKey,
    ],
    async () => {
      const { apiMultiPropertiesData, listProperty, contentProperty } =
        sheetProperties[sheetType];
      const { data } = await axios.get(
        getSheetApiUrl({
          plant,
          sheetType,
          name,
          revision,
          postfix: (prepostfix ?? "") + (postfix ?? ""),
        })
      );
      return postfix || apiMultiPropertiesData
        ? data
        : data[contentProperty ?? listProperty][0];
    },
    {
      cacheTime,
      enabled: !disabled,
      onSuccess: () => {
        if (
          postfix === "main-revision-validation-check" ||
          postfix === "sub-revision-validation-check"
        ) {
          queryClient.invalidateQueries(["sheets", plant?.PlantID, sheetType]);
        }
      },
    }
  );
}
