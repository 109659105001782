import axios, { AxiosResponse } from "axios";
import { useMutation } from "react-query";
import { getSheetApiUrl } from "../../../queries/queryUtil";
import { AffectedVDSApiData } from "./useAffectedVDSByReplacementQuery";

export function useAffectedVDSByReplacement() {
  async function vdsSubsegmentPropertiesCommand({
    subsegmentId,
    subsegmentRevision,
  }: {
    subsegmentId: number;
    subsegmentRevision: string;
  }): Promise<AxiosResponse<AffectedVDSApiData>> {
    return await axios(
      getSheetApiUrl({
        sheetType: "vds-subsegments",
        name: String(subsegmentId),
        revision: subsegmentRevision,
        postfix: "affected-vds-by-replacement",
      }),
      {
        method: "get",
      }
    );
  }

  return useMutation(vdsSubsegmentPropertiesCommand);
}
