import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { DndContext } from "@dnd-kit/core";
import axios from "axios";
import ConfirmContextProvider from "components/confirm/ConfirmContextProvider";
import React from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { acquireAccessToken } from "./app/auth";
import { msalConfig } from "./app/authConfig";
import config from "./config/config";
import { appProps } from "./app/props";
import { store } from "./app/store";
import ConfirmContextModal from "./components/confirm/ConfirmContextModal";
import "./styles/main.scss";

const reactQueryDevTools = true;

const msalInstance = new PublicClientApplication(msalConfig);

axios.interceptors.request.use(
  async function (axiosConfig) {
    if (
      axiosConfig.url?.startsWith(config.restApiBaseUrl) &&
      !!acquireAccessToken
    ) {
      const accessToken = await acquireAccessToken();
      if (!axiosConfig.headers) {
        axiosConfig.headers = {};
      }
      axiosConfig.headers["Authorization"] = `Bearer ${accessToken}`;
      if ("extraHeaders" in config && config.extraHeaders) {
        axiosConfig.headers = {
          ...axiosConfig.headers,
          ...config.extraHeaders,
        };
      }
    }
    return axiosConfig;
  },
  function (error) {
    console.error(error);
    // Do something with request error
    return Promise.reject(error);
  }
);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

queryClient.setQueryDefaults(["settings"], {
  staleTime: Infinity,
  cacheTime: Infinity,
});

export const AppProviders: React.FC = ({ children }) => {
  return (
    <MsalProvider instance={msalInstance}>
      <Provider store={store}>
        <BrowserRouter basename={appProps.appBasePath as string}>
          <QueryClientProvider client={queryClient}>
            <ConfirmContextProvider>
              <DndContext>
                {children}
                {reactQueryDevTools && (
                  <ReactQueryDevtools initialIsOpen={false} />
                )}
              </DndContext>
              <ConfirmContextModal />
            </ConfirmContextProvider>
          </QueryClientProvider>
        </BrowserRouter>
      </Provider>
    </MsalProvider>
  );
};
