import { ColumnsProps } from "../../../components/table/Table";
import {
  StatusChip,
  RevisionMark,
  DateDisplay,
} from "../../../components/Components";
import { SheetStatus } from "./statusConfig";
import { NameProperties } from "utils/filterItemsByName";

export const maxPCSNameLength = 14;
export const maxSheetNameLength = 8;

export type SheetTypes =
  | "general"
  | "pcs"
  | "sc"
  | "vsm"
  | "eds"
  | "vds"
  | "vds-unstructured"
  | "vds-subsegments"
  | "vds-textblocks"
  | "mds"
  | "vsk"
  | "pipe-elements"
  | "esk";

export type PCSReferenceSheetTypes = "sc" | "vsm" | "eds" | "vds";
export type ListSheetsDisplayTypes = "main" | "copyModal";
export type IssueSheetTypes =
  | "general"
  | "pcs"
  | "eds"
  | "vds"
  | "mds"
  | "vsk"
  | "pipe-elements"
  | "sc"
  | "vsm"
  | "esk";

export const plantDependentSheets = ["general", "pcs", "sc", "vsm"];

export const textualSheets = ["eds", "general", "vsm", "sc"];

export const issueSheetTypes = [
  "General",
  "PCS",
  "SC",
  "VSM",
  "EDS",
  "VDS",
  "VSK",
  "MDS",
  "PMG",
];

export const pcsWorkbenchCommands = [
  "references",
  "standard-notes",
  "pipe-elements",
  "properties",
] as const;

export const vdsWorkbenchCommands = [
  "replace-subsegment",
  "update-content",
] as const;

export const defaultComponents: {
  [key: string]: React.ComponentType<{ children: React.ReactNode }>;
} = {
  Status: StatusChip,
  Revision: RevisionMark,
  RevDate: DateDisplay,
  LastUpdate: DateDisplay,
};

export const defaultWidths: { [key: string]: number | string } = {
  Revision: 38,
  RevDate: 80,
  Status: 50,
  LastUpdate: 90,
  LatUpdateBy: 100,
  Notepad: "100%",
  Format: 40,
  SpecialRequirement: 100,
  RatingClass: 175,
};

const nameWidth = 80;
export const vdsWidth = 107;
export const idWidth = 40;
const commaDelimitedWidth = 120;
const smallWidth = 60;
const groupWidth = 145;
const tempWidth = 100;

export type SortMode = "date" | "numeric" | "natural" | "string" | "revision";

export const defaultSort: { [key: string]: SortMode } = {
  LastUpdate: "date",
  RevDate: "date",
  AddStackDateTime: "date",
  DesignTemperature: "numeric",
  OperatingTemperature: "numeric",
  Revision: "revision",
};

export const sheetProperties: {
  [key in SheetTypes]: {
    name: string;
    nameProperty: string;
    listProperty: string;
    contentProperty?: string;
    filterNameProperty?: NameProperties;
    filterPlaceholder?: string;
    columns: ColumnsProps[];
    statuses: SheetStatus[];
    previewInEdit?: boolean;
    sheetListProperty?: string;
    elementNameSingular?: string;
    elementNamePlural?: string;
    filterFirst?: boolean;
    apiPath?: string;
    apiMultiPropertiesData?: boolean;
    apiNoMultiAction?: boolean;
    apiNoIndividualDatasheet?: boolean;
    apiDeleteSingle?: boolean;
  };
} = {
  eds: {
    name: "EDS",
    listProperty: "getEDS",
    nameProperty: "EDS",
    statuses: ["O", "E", "W", "U", "R"],
    columns: [
      { key: "EDS", title: "EDS", width: nameWidth, className: "nameDisplay" },
      { key: "Revision", title: "Rev.", longTitle: "Revision" },
      { key: "RevDate", title: "Rev. Date", longTitle: "Revision Date" },
      { key: "Status", title: "Status" },
      { key: "LastUpdate", title: "Last Update" },
      { key: "LastUpdateBy", title: "Last Update By" },
      { key: "Notepad", title: "Note" },
    ],
  },
  vds: {
    name: "VDS",
    listProperty: "getVDS",
    nameProperty: "VDS",
    statuses: ["O", "E", "W", "U", "R", "D"],
    columns: [
      { key: "VDS", title: "VDS", width: vdsWidth, className: "nameDisplay" },
      { key: "Revision", title: "Rev.", longTitle: "Revision" },
      { key: "RevDate", title: "Rev. Date", longTitle: "Revision Date" },
      { key: "Status", title: "Status" },
      {
        key: "LastUpdate",
        title: "Last Update",
        display: "no-replace-workbench",
      },
      { key: "LastUpdateBy", title: "Last Update By" },
      {
        key: "SpecialReqID",
        title: "Special Requirement",
        codelist: "special-requirements",
        display: "no-replace-workbench",
      },
      {
        key: "Description",
        title: "Description",
        width: "33%",
        minWidth: 200,
        display: "no-replace-workbench",
      },
      {
        key: "VDSDescription",
        title: "Description",
        width: "33%",
        minWidth: 2000,
        display: "only-replace-workbench",
      },
      {
        key: "Notepad",
        title: "Note",
        width: "33%",
        minWidth: 200,
        display: "no-replace-workbench",
      },
      {
        key: "ValveTypeID",
        title: "Valve Type",
        codelist: "valve-types",
        display: "no-replace-workbench",
      },
      {
        key: "RatingClassID",
        title: "Rating Class",
        codelist: "rating-classes",
        display: "no-replace-workbench",
      },
      {
        key: "MaterialGroupID",
        title: "Mat. Group",
        codelist: "material-groups",
        display: "no-replace-workbench",
      },
      {
        key: "EndConnectionID",
        title: "End Connection",
        codelist: "end-connections",
        display: "no-replace-workbench",
      },
      {
        key: "BoreID",
        title: "Bore",
        codelist: "bores",
        display: "no-replace-workbench",
      },
      {
        key: "VDSSizeID",
        title: "Size",
        codelist: "vds-sizes",
        display: "no-replace-workbench",
      },
      {
        key: "SubsegmentList",
        title: "Subsegments",
        width: "33%",
        minWidth: 300,
        display: "no-replace-workbench",
      },
    ],
    filterFirst: true,
    apiMultiPropertiesData: true,
  },
  "vds-unstructured": {
    name: "Unstructured VDS",
    apiPath: "vds",
    listProperty: "getVDS",
    nameProperty: "VDS",
    statuses: ["O", "E", "W", "U", "R"],
    columns: [
      { key: "VDS", title: "VDS", width: nameWidth, className: "nameDisplay" },
      { key: "Revision", title: "Rev.", longTitle: "Revision" },
      { key: "RevDate", title: "Rev. Date", longTitle: "Revision Date" },
      { key: "Status", title: "Status" },
      { key: "LastUpdate", title: "Last Update" },
      { key: "LastUpdateBy", title: "Last Update By" },
      {
        key: "SpecialReqID",
        title: "Special Requirement",
        codelist: "special-requirements",
      },
      { key: "Description", title: "Description", width: "50%" },
      { key: "Notepad", title: "Note", width: "50%" },
    ],
    filterFirst: true,
  },
  pcs: {
    name: "PCS",
    listProperty: "getPCS",
    nameProperty: "PCS",
    statuses: ["O", "E", "W", "U", "R", "I", "S"],
    columns: [
      { key: "PCS", title: "PCS", width: nameWidth, className: "nameDisplay" },
      { key: "Revision", title: "Rev.", longTitle: "Revision" },
      { key: "RevDate", title: "Rev. Date", longTitle: "Revision Date" },
      { key: "Status", title: "Status" },
      { key: "LastUpdate", title: "Last Update", width: 115 }, // this one has time too
      { key: "LastUpdateBy", title: "Last Update By" },
      { key: "Approver", title: "Approver" },
      { key: "DesignCode", title: "Design Code" },
      { key: "MaterialGroup", title: "Material" },
      {
        key: "SpecialReqID",
        title: "Special Requrement",
        display: "workbench",
        codelist: "special-requirements",
      },
      { key: "RatingClass", title: "Rating Class", display: "workbench" },
      {
        key: "NewVDSSection",
        title: "VDS Section",
        display: "workbench",
        codelist: "vds-section",
      },
      {
        key: "TubePCS",
        title: "Tube PCS",
        display: "workbench",
        codelist: "tube-pcs",
      },
      { key: "Notepad", title: "Note", minWidth: 100 },
    ],
  },
  general: {
    name: "General",
    listProperty: "getGeneral",
    nameProperty: "Revision",
    statuses: ["O", "E", "W", "U", "R", "I"],
    columns: [
      { key: "Revision", title: "Rev.", longTitle: "Revision" },
      { key: "RevDate", title: "Rev. Date", longTitle: "Revision Date" },
      { key: "Status", title: "Status" },
      { key: "LastUpdate", title: "Last Update" },
      { key: "LastUpdateBy", title: "Last Update By" },
      { key: "Notepad", title: "Note" },
    ],
  },
  sc: {
    name: "SC",
    listProperty: "getSC",
    nameProperty: "SC",
    statuses: ["O", "E", "W", "U", "R"],
    columns: [
      { key: "SC", title: "SC", width: nameWidth, className: "nameDisplay" },
      { key: "Revision", title: "Rev.", longTitle: "Revision" },
      { key: "RevDate", title: "Rev. Date", longTitle: "Revision Date" },
      { key: "Status", title: "Status" },
      { key: "LastUpdate", title: "Last Update" },
      { key: "LastUpdateBy", title: "Last Update By" },
      { key: "Format", title: "Format" },
      { key: "Notepad", title: "Note" },
    ],
  },
  vsm: {
    name: "VSM",
    listProperty: "getVSM",
    nameProperty: "VSM",
    statuses: ["O", "E", "W", "U", "R"],
    columns: [
      { key: "VSM", title: "VSM", width: nameWidth, className: "nameDisplay" },
      { key: "Revision", title: "Rev.", longTitle: "Revision" },
      { key: "RevDate", title: "Rev. Date", longTitle: "Revision Date" },
      { key: "Status", title: "Status" },
      { key: "LastUpdate", title: "Last Update" },
      { key: "LastUpdateBy", title: "Last Update By" },
      { key: "Format", title: "Format" },
      { key: "Notepad", title: "Note" },
    ],
  },
  "vds-subsegments": {
    name: "VDS Subsegment",
    listProperty: "getVDSSubSegmentList",
    nameProperty: "SubsegmentID",
    filterNameProperty: [
      { name: "SubsegmentName", matchAnywhere: true },
      "SubsegmentID",
    ],
    filterPlaceholder: "Filter names, IDs",
    statuses: ["O", "E", "W", "R", "D"],
    sheetListProperty: "vdssubsegments",
    elementNameSingular: "subsegment",
    elementNamePlural: "subsegments",
    apiMultiPropertiesData: true,
    filterFirst: true,
    columns: [
      {
        key: "GroupID",
        title: "Subsegment Group",
        width: groupWidth,
        codelist: "vds-subsegment-groups",
      },
      {
        key: "SubsegmentID",
        title: "ID",
        width: idWidth,
        className: "nameDisplay",
      },
      { key: "SubsegmentName", title: "Subsegment Name", width: "270px" },
      { key: "Revision", title: "Rev.", longTitle: "Revision" },
      { key: "RevDate", title: "Rev. Date", longTitle: "Revision Date" },
      { key: "Status", title: "Status" },
      { key: "LastUpdateBy", title: "Last Update By" },
      {
        key: "VDSTextBlockID",
        title: "VDS Text Blocks",
        width: "120px",
        type: "comma-delimited",
      },
      {
        key: "ValveType",
        title: "Valve Type",
        type: "comma-delimited",
        width: commaDelimitedWidth,
        codelist: "valve-types",
      },
      {
        key: "RatingClass",
        title: "Rating Class",
        type: "comma-delimited",
        codelist: "rating-classes",
      },
      {
        key: "MaterialGroup",
        title: "Mat. Group",
        type: "comma-delimited",
        width: commaDelimitedWidth,
        codelist: "material-groups",
      },
      {
        key: "EndConnection",
        title: "End Connection",
        type: "comma-delimited",
        width: commaDelimitedWidth,
        codelist: "end-connections",
      },
      {
        key: "Bore",
        title: "Bore",
        type: "comma-delimited",
        width: smallWidth,
        codelist: "bores",
      },
      {
        key: "VDSSize",
        title: "Size",
        type: "comma-delimited",
        width: smallWidth,
        codelist: "vds-sizes",
      },
      {
        key: "SpecialRequirement",
        title: "Special Requirement",
        type: "comma-delimited",
        codelist: "special-requirements",
      },
      { key: "Notepad", title: "Note", width: "150px" },
    ],
  },
  "vds-textblocks": {
    name: "VDS Text Block",
    listProperty: "getVDSTextblock",
    contentProperty: "getVDSTextblockContent",
    nameProperty: "TextBlockID",
    filterNameProperty: [
      { name: "TextBlockName", matchAnywhere: true },
      "TextBlockID",
    ],
    filterPlaceholder: "Filter names, IDs",
    statuses: ["O", "E", "W", "R", "D"],
    previewInEdit: true,
    sheetListProperty: "textblocks",
    elementNameSingular: "text block",
    elementNamePlural: "text blocks",
    filterFirst: true,
    columns: [
      {
        key: "GroupID",
        title: "Subsegment Group",
        width: groupWidth,
        codelist: "vds-subsegment-groups",
      },
      {
        key: "TextBlockID",
        title: "ID",
        width: idWidth,
        className: "nameDisplay",
      },
      { key: "TextBlockName", title: "Text Block Name", width: "170px" },
      { key: "Revision", title: "Rev.", longTitle: "Revision" },
      { key: "RevDate", title: "Rev. Date", longTitle: "Revision Date" },
      { key: "Status", title: "Status" },
      { key: "LastUpdateBy", title: "Last Update By" },
      { key: "ItemDescription", title: "Text Block Type", width: groupWidth },
      { key: "ItemSection", title: "Section" },
      { key: "ItemID", title: "Item" },
      { key: "VDSDescription", title: "VDS Description", width: groupWidth },
      { key: "DesignTemperature", title: "Design Temp.", width: tempWidth },
      { key: "OperatingTemperature", title: "Oper. Temp.", width: tempWidth },
      {
        key: "ValveType",
        title: "Valve Type",
        type: "comma-delimited",
        width: commaDelimitedWidth,
      },
      {
        key: "RatingClass",
        title: "Rating Class",
        type: "comma-delimited",
      },
      {
        key: "MaterialGroup",
        title: "Mat. Group",
        type: "comma-delimited",
        width: commaDelimitedWidth,
      },
      {
        key: "EndConnection",
        title: "End Connection",
        type: "comma-delimited",
        width: commaDelimitedWidth,
      },
      {
        key: "Bore",
        title: "Bore",
        type: "comma-delimited",
        width: smallWidth,
      },
      {
        key: "VDSSize",
        title: "Size",
        type: "comma-delimited",
        width: smallWidth,
      },
      {
        key: "SpecialRequirement",
        title: "Special Requirement",
        type: "comma-delimited",
      },
    ],
  },
  mds: {
    name: "MDS",
    listProperty: "getMDS",
    nameProperty: "MDS",
    columns: [],
    statuses: ["O", "W"],
  },
  vsk: {
    name: "VSK",
    listProperty: "getVSK",
    nameProperty: "VSK",
    columns: [],
    statuses: ["O", "W"],
  },
  "pipe-elements": {
    name: "Pipe Element",
    listProperty: "",
    nameProperty: "ElementID",
    columns: [],
    statuses: ["O", "W"],
  },
  esk: {
    name: "ESK",
    listProperty: "getESK",
    nameProperty: "ElementID",
    columns: [],
    statuses: ["O", "W"],
  },
};

export const sheetTypes = Object.keys(sheetProperties);

export const sheetTypeNames = Object.fromEntries(
  Object.keys(sheetProperties).map((sheet) => {
    const sheetProperty = sheetProperties[sheet as SheetTypes];
    return [sheet, sheetProperty.name];
  })
);

export const nameProperties = Object.fromEntries(
  Object.keys(sheetProperties).map((sheet) => {
    const sheetProperty = sheetProperties[sheet as SheetTypes];
    return [sheet, sheetProperty.nameProperty];
  })
);

export const sheetDocumentWidth = 700;
export const minTableWidth = 300;

export const getElementNamePlural = (sheetType: SheetTypes) =>
  sheetProperties[sheetType].elementNamePlural ?? "data sheets";
export const getElementNameSingular = (sheetType: SheetTypes) =>
  sheetProperties[sheetType].elementNameSingular ?? "data sheet";
