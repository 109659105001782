import { Button, Icon, Menu } from "@equinor/eds-core-react";
import {
  assignment,
  delete_forever,
  edit,
  file,
  label,
  more_vertical,
  skip_next,
} from "@equinor/eds-icons";
import { RevisionMark, getStatusChips } from "components/Components";
import { ContextMenu } from "components/ContextMenu";
import StatusModal from "components/StatusModal";
import useConfirm from "components/confirm/useConfirm";
import { useFilter } from "components/table/Filter";
import Table, {
  ColumnsProps,
  SmallTableContainer,
} from "components/table/Table";
import { editItem } from "components/table/tableUtils";
import { useTable } from "components/table/useTable";
import { SheetStatus } from "features/sheets/config/statusConfig";
import { combineQueryStatuses } from "queries/queryUtil";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { downloadAsXLSX } from "utils/download";
import { successMessage } from "utils/successMessage";
import useDeleteMaterialGroup from "../queries/useDeleteMaterialGroup";
import { MaterialGroup, useMaterialGroups } from "../queries/useMaterialGroups";
import useNewMaterialGroupRevision from "../queries/useNewMaterialGroupRevision";
import useSetMaterialGroupStatus from "../queries/useSetMaterialGroupStatus";
import {
  CreateNewPipeMaterialGroup,
  EditPipeMaterialGroup,
} from "./EditPipeMaterialGroup";
import { MaterialGroupVerification } from "./MaterialGroupVerification";
import { PipeMaterialGroupProperties } from "./PipeMaterialGroupProperties";

const columns: ColumnsProps[] = [
  { key: "MaterialGroupID", title: "ID", width: 40 },
  { key: "MaterialGroup", title: "Material Group", width: 80 },
  { key: "Revision", title: "Rev.", longTitle: "Revision" },
  { key: "RevDate", title: "Rev. Date", longTitle: "Revision Date" },
  { key: "Status", title: "Status" },
  { key: "LastUpdate", title: "Last Update" },
  { key: "LastUpdateBy", title: "Last Update By" },
  { key: "Notepad", title: "Note", width: "100%" },
];

const additionColumns: ColumnsProps[] = [
  { key: "DimStandard", title: "Dimension Standard", width: 120 },
  { key: "ProductForm", title: "Product Form", width: 180 },
  { key: "MaterialGrade", title: "Material Grade", width: 120 },
  { key: "MDS", title: "MDS", width: 105 },
  {
    key: "MDSRevision",
    title: "MDS Rev.",
    longTitle: "MDS Revision",
    Component: RevisionMark,
  },
  { key: "OperatorName", title: "Operator" },
  { key: "Area", title: "Area", width: 60 },
  { key: "SpecialRequirement", title: "Special Requirement", width: "50%" },
  {
    key: "TypeOfChange",
    title: "Type of Change",
  },
];

const defaultStatusFilter: SheetStatus[] = ["O", "W"];
const availableStatuses: SheetStatus[] = ["O", "W", "R"];

function RowMenu({
  item,
  deleteItem,
  newRevision,
  data,
  currentItemId,
  currentRevision,
  setShowVerify,
}: {
  item: MaterialGroup;
  deleteItem: Function;
  newRevision: Function;
  data: MaterialGroup[];
  currentItemId: React.MutableRefObject<number>;
  currentRevision: React.MutableRefObject<string>;
  setShowVerify: React.Dispatch<boolean>;
}) {
  const history = useHistory();
  const { isConfirmed } = useConfirm();

  return (
    <ContextMenu buttonContent={<Icon data={more_vertical}></Icon>}>
      <Menu.Item
        onClick={() => {
          editItem({
            itemId: item.MaterialGroupID,
            revision: item.Revision,
            history,
          });
        }}
      >
        {item.Status !== "W" ? (
          <>
            <Icon size={16} data={file} />
            View
          </>
        ) : (
          <>
            <Icon size={16} data={edit} />
            Edit
          </>
        )}
      </Menu.Item>
      <Menu.Item
        onClick={() => {
          editItem({
            itemId: item.MaterialGroupID,
            revision: item.Revision,
            history,
            tab: "properties",
          });
        }}
      >
        <Icon size={16} data={assignment} />
        Properties
      </Menu.Item>
      <Menu.Item
        disabled={item.Status !== "W"}
        onClick={async () => {
          (await isConfirmed(
            <>
              Are you sure you want to delete PMG {item.MaterialGroup} rev.{" "}
              {item.Revision}?
            </>,
            { buttonColor: "danger" }
          )) &&
            deleteItem({
              name: item.MaterialGroupID,
              revision: item.Revision,
            });
        }}
      >
        <Icon size={16} data={delete_forever} />
        Delete
      </Menu.Item>
      <Menu.Item
        disabled={
          data
            .filter((e) => e.MaterialGroup === item.MaterialGroup)
            .filter((e) => e.Status === "W").length > 0 || item.Status !== "O"
        }
        onClick={async () => {
          currentItemId.current = item.MaterialGroupID;
          currentRevision.current = item.Revision;
          (await isConfirmed(
            `Are you sure you want to create a new revision for PMG ${item.MaterialGroup}?`
          )) &&
            newRevision({
              name: item.MaterialGroupID,
              revision: item.Revision,
            });
        }}
      >
        <Icon size={16} data={skip_next} />
        New revision
      </Menu.Item>
      <Menu.Section>
        <Menu.Item
          disabled={item.Status !== "W"}
          onClick={async () => {
            currentItemId.current = item.MaterialGroupID;
            currentRevision.current = item.Revision;
            (await isConfirmed(
              <>
                <p>
                  This will set PMG {item.MaterialGroup} rev. {item.Revision} to
                  Status Official.
                </p>
                <p>
                  When a PMG is set to Official, the Pipe Element Article
                  database is updated.
                </p>
                <p>Do you want to continue?</p>
              </>
            )) && setShowVerify(true);
          }}
        >
          <Icon size={16} data={label} />
          Set Official (O)
        </Menu.Item>
      </Menu.Section>
    </ContextMenu>
  );
}

function Commands({ item }: { item: MaterialGroup }) {
  const history = useHistory();

  return (
    <Button
      variant="ghost_icon"
      onClick={() =>
        editItem({
          itemId: item.MaterialGroupID,
          revision: item.Revision,
          history,
        })
      }
    >
      <Icon data={item.Status === "W" ? edit : file}></Icon>
    </Button>
  );
}

export function PipeMaterialGroupList() {
  const query = useMaterialGroups({ postfix: undefined });
  const { data } = query;

  const currentItemId = useRef(0);
  const currentRevision = useRef("");
  const [createNew, setCreateNew] = useState(false);
  const openCreateNew = useCallback(() => {
    setCreateNew(true);
  }, []);

  const {
    mutate: deleteItem,
    status: deleteStatus,
    error: deleteError,
    data: deleteData,
    reset: deleteReset,
  } = useDeleteMaterialGroup();

  const {
    mutate: newRevision,
    status: newRevisionStatus,
    error: newRevisionError,
    data: newRevisionData,
    reset: newRevisionReset,
  } = useNewMaterialGroupRevision();

  const {
    mutate: setStatus,
    status: setStatusStatus,
    error: setStatusError,
    data: setStatusData,
    reset: setStatusReset,
  } = useSetMaterialGroupStatus();

  const [showVerify, setShowVerify] = useState(false);

  const { content, filtersDispatch, editName, editRevision, editTab } =
    useTable({
      query,
      columns,
      title: "Pipe Material Groups",
      filterGroupProperties: [
        {
          col: 1,
          group: "Selections",
        },
        { col: 2, group: "Statuses" },
        { col: 3, group: "Users" },
      ],
      nameProperty: "MaterialGroup",
      nameFilterPlaceholder: "PMG filter",
      itemIdProp: "MaterialGroupID",
      RowMenu,
      Commands,
      contextData: {
        deleteItem,
        newRevision,
        data,
        currentItemId,
        currentRevision,
        setShowVerify,
      },
      createNew: openCreateNew,
    });

  useFilter({
    codelist: "material-groups",
    filtersDispatch,
    group: "Selections",
  });

  const [statusFilter, setStatusFilter] = useState(defaultStatusFilter);

  useEffect(() => {
    filtersDispatch({
      type: "update",
      payload: {
        type: "checkboxes",
        title: "Statuses",
        prop: "Status",
        filterOptions: getStatusChips(availableStatuses),
        filterState: statusFilter,
        defaultFilterState: defaultStatusFilter,
        setFilterState: setStatusFilter,
        commaSeparated: false,
        group: "Statuses",
        isArray: true,
        apiFilterPorp: "statusFilter",
      },
    });
  }, [statusFilter, filtersDispatch]);

  const [userFilter, setUserFilter] = useState("");

  const userOptions = useMemo(
    () =>
      data
        ? Array.from(
            new Set([
              ...data.map((e) => e.LastUpdateBy),
              ...(userFilter !== "" ? [userFilter] : []),
            ])
          )
        : userFilter
        ? [userFilter]
        : [],
    [data, userFilter]
  );

  useEffect(() => {
    filtersDispatch({
      type: "update",
      payload: {
        type: "select",
        title: "Last Update By",
        prop: "LastUpdateBy",
        filterOptions: userOptions,
        filterState: userFilter,
        defaultFilterState: "",
        setFilterState: setUserFilter,
        commaSeparated: false,
        group: "Users",
      },
    });
  }, [filtersDispatch, userFilter, userOptions]);

  const {
    data: additionData,
    status: additionStatus,
    error: additionError,
  } = useMaterialGroups({
    name: String(currentItemId.current),
    revision: currentRevision.current,
    postfix: "structured-pipe-elements-addition",
    disabled: newRevisionStatus !== "success",
  });

  const additionDataCompiled = useMemo(
    () =>
      additionData?.map((e) => ({
        ...e,
        TypeOfChange:
          e.TypeOfChange === "R"
            ? "Revised MDS"
            : e.TypeOfChange === "N"
            ? "New MDS"
            : e.TypeOfChange,
        itemId: JSON.stringify(e),
      })),
    [additionData]
  );

  const combinedRevisionAdditionStatus = combineQueryStatuses(
    newRevisionStatus,
    additionStatus
  );

  const history = useHistory();

  return (
    <>
      {content}
      <PipeMaterialGroupProperties
        name={editName}
        revision={editRevision}
        tab={editTab}
      />
      <EditPipeMaterialGroup
        name={editName}
        revision={editRevision}
        tab={editTab}
      />
      <CreateNewPipeMaterialGroup
        isOpen={createNew}
        closeModal={() => setCreateNew(false)}
      />
      <StatusModal
        status={deleteStatus}
        error={deleteError}
        onSettledClose={() => deleteReset()}
        successMessage={successMessage(deleteData)}
      />
      <StatusModal
        status={
          newRevisionStatus === "idle" ? "idle" : combinedRevisionAdditionStatus
        }
        error={
          newRevisionStatus === "error"
            ? newRevisionError
            : additionStatus === "error"
            ? additionError
            : undefined
        }
        onSettledClose={() => {
          const newRevision = newRevisionData?.data.AdditionalInfo;
          newRevisionReset();
          if (newRevision) {
            editItem({
              history,
              itemId: currentItemId.current,
              revision: newRevision,
              tab: "properties",
            });
          }
        }}
        successTitle={
          additionDataCompiled && additionDataCompiled?.length > 0
            ? "New Revision Status Report"
            : undefined
        }
        extraButton={
          additionDataCompiled && additionDataCompiled.length > 0 ? (
            <Button
              variant="outlined"
              onClick={() =>
                downloadAsXLSX({
                  columns: additionColumns,
                  items: additionDataCompiled ?? [],
                  filename: `New Revision Report for ${
                    data?.find(
                      (e) => e.MaterialGroupID === currentItemId.current
                    )?.MaterialGroup ?? currentItemId.current
                  } Rev ${currentRevision.current}`,
                })
              }
            >
              Export
            </Button>
          ) : null
        }
        noSnackbar
        successMessage={
          additionData ? (
            additionData.length === 0 ? (
              successMessage(newRevisionData)
            ) : (
              <>
                <SmallTableContainer style={{ minWidth: 1200, marginTop: 8 }}>
                  <Table
                    items={additionDataCompiled}
                    columns={additionColumns}
                    itemIdProp="itemId"
                    density="compact"
                  />
                </SmallTableContainer>
              </>
            )
          ) : (
            ""
          )
        }
      />
      <StatusModal
        status={setStatusStatus}
        error={setStatusError}
        onSettledClose={() => {
          setStatusReset();
        }}
        onSuccess={() => {
          setShowVerify(false);
        }}
        successMessage={successMessage(setStatusData)}
      />
      <MaterialGroupVerification
        isOpen={showVerify}
        closeModal={() => setShowVerify(false)}
        materialGroupId={String(currentItemId.current)}
        revision={currentRevision.current}
        setStatus={setStatus}
      />
    </>
  );
}
