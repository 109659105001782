import { useMsal } from "@azure/msal-react";
import {
  Button,
  Icon,
  Menu,
  Tooltip,
  Typography,
} from "@equinor/eds-core-react";
import {
  add,
  delete_forever,
  edit,
  grid_layer,
  more_horizontal,
  refresh,
} from "@equinor/eds-icons";
import { useAppSelector } from "app/hooks";
import {
  Chip,
  ContentMessage,
  Fade,
  FlexContainer,
  FlexFormContainer,
  HeaderButtonContainer,
  Paragraphs,
  RevisionMark,
  SimpleButtonContainer,
  StatusChip,
} from "components/Components";
import { ContextMenu } from "components/ContextMenu";
import { SimpleDownloads } from "components/Downloads";
import { MessageModal } from "components/MessageModal";
import StatusModal from "components/StatusModal";
import useConfirm from "components/confirm/useConfirm";
import { HeaderProgress } from "components/table/HeaderProgress";
import { History } from "history";
import useIssues, { Issue } from "queries/useIssues";
import useIssuesCommand from "queries/useIssuesCommand";
import React, { useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";
import { useMemoOne as useMemo } from "use-memo-one";
import Table, {
  ColumnsProps,
  ControlHeaderContainer,
  ControlHeaderTitle,
} from "../../components/table/Table";
import { successMessage } from "../../utils/successMessage";
import { selectMainPlant } from "../plant/plantSlice";
import { PlantProps } from "../plant/types";
import { EditIssue } from "./EditIssue";
import { IssuesContext, IssuesContextProvider } from "./IssuesContext";
import { ModalWindowSimpleContainer } from "components/ModalWindow";

const RevAndDate = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  word-break: normal;
  flex-direction: column;
  padding: 8px;
`;

const Date = styled.div`
  font-size: 87%;
`;

function IssueSheetData({ item, column }: { item: any; column: ColumnsProps }) {
  return (
    <RevAndDate>
      <RevisionMark>{item[column.key + "Revision"]}</RevisionMark>
      <Date>{item[column.key + "RevDate"]}</Date>
    </RevAndDate>
  );
}

function OpenIssueCommandButton({ item }: { item: Issue }) {
  const history = useHistory();
  const { isEditable } = useContext(IssuesContext);
  const canEdit = item.Status === "W" && isEditable;
  return (
    <Button
      variant="ghost_icon"
      onClick={() => editIssue({ item, history })}
      title={canEdit ? "Edit Issue" : "View Issue"}
    >
      <Icon data={canEdit ? edit : grid_layer} />
    </Button>
  );
}

export const editIssue = ({
  item,
  history,
}: {
  item: Issue;
  history: History;
}) => {
  history.push({
    search: `?edit=${item.IssueRevision}`,
  });
};

export const closeIssue = ({ history }: { history: History }) => {
  history.push({ search: "" });
};

function IssueItemMenu({ item }: { item: Issue }) {
  const { isConfirmed } = useConfirm();
  const { issuesCommand, isEditable } = useContext(IssuesContext);
  const plant = useAppSelector(selectMainPlant);

  return (
    <ContextMenu buttonContent={<Icon data={more_horizontal}></Icon>}>
      <Menu.Section>
        <Menu.Item
          disabled={item.Status !== "W" || !isEditable}
          onClick={async () => {
            (await isConfirmed(
              <>Are you sure you want to delete this Issue?</>,
              { buttonColor: "danger" }
            )) &&
              issuesCommand &&
              issuesCommand({
                command: "delete",
                IssueRevision: item.IssueRevision,
                plant,
              });
          }}
        >
          <Icon data={delete_forever} onClick={() => {}} />
          Delete Issue
        </Menu.Item>
      </Menu.Section>
    </ContextMenu>
  );
}

export function LockIndicator({
  lock,
  userName,
  date,
  wantToLeave,
  setWantToLeave,
  setLockRelease,
}: {
  lock: LockType;
  userName?: string;
  date?: string;
  wantToLeave?: boolean;
  setWantToLeave: React.Dispatch<boolean>;
  setLockRelease: React.Dispatch<boolean>;
}) {
  const plant = useAppSelector(selectMainPlant);
  const { refetch } = useIssues({
    plant,
    request: "issuelist",
  });
  const { mutate: issuesCommand, status: issuesCommandStatus } =
    useIssuesCommand();
  const { accounts } = useMsal();
  const { isConfirmed } = useConfirm();

  return (
    <FlexFormContainer style={{ flexWrap: "nowrap", minHeight: 36 }}>
      <div style={{ minWidth: 150 }}>
        {issuesCommandStatus === "loading" ? (
          <Chip>Sending command</Chip>
        ) : lock === "others" ? (
          <Chip variant="error" title={`Lock issued at ${date} to ${userName}`}>
            Locked by {userName}
          </Chip>
        ) : lock === "mine" ? (
          <Chip variant="active" title={`Locked at ${date} by ${userName}`}>
            Locked
          </Chip>
        ) : lock === "released" ? (
          <Chip variant="default">Lock released</Chip>
        ) : lock === "pending" ? (
          <Chip variant="default">Lock pending</Chip>
        ) : lock === "loading" ? (
          <Chip variant="default">Loading</Chip>
        ) : lock === "error" ? (
          <Typography color="danger">Error requesting lock.</Typography>
        ) : lock === "wait" ? (
          <Chip variant="default">No lock</Chip>
        ) : (
          <>Lock status unknown.</>
        )}
      </div>
      {lock === "others" && (
        <>
          <div>
            <Button
              variant="outlined"
              onClick={() => {
                setLockRelease(false);
                refetch();
              }}
            >
              Check lock
            </Button>
          </div>
          <div>
            <Button
              variant="contained"
              onClick={async () => {
                (await isConfirmed(
                  <>
                    <p>
                      Are you sure you want to take over and edit this locked
                      Issue?
                    </p>
                    <p>
                      If another user is actively editing this Issue in
                      paralell, inconsistencies might occur.
                    </p>
                  </>,
                  { buttonColor: "danger" }
                )) &&
                  issuesCommand({
                    command: "user-protection",
                    SetUserProtectIndicator: "Y",
                    UserName: accounts[0].username,
                    plant,
                  });
              }}
            >
              Take over lock
            </Button>
          </div>
        </>
      )}
      {lock === "released" && (
        <div>
          <Button
            variant="outlined"
            onClick={() => {
              setLockRelease(false);
              setWantToLeave(false);
              issuesCommand({
                command: "user-protection",
                SetUserProtectIndicator: "Y",
                UserName: accounts[0].username,
                plant,
              });
            }}
          >
            Acquire lock again
          </Button>
        </div>
      )}
      {wantToLeave && lock === "mine" && (
        <Button
          variant="outlined"
          onClick={() => {
            setLockRelease(true);
            issuesCommand({
              command: "user-protection",
              SetUserProtectIndicator: "N",
              UserName: accounts[0].username,
              plant,
            });
          }}
          title="Release edit lock on this issue before closing the window."
        >
          Release lock
        </Button>
      )}
    </FlexFormContainer>
  );
}

export function Issues() {
  const plant = useAppSelector(selectMainPlant);
  return plant.PlantID === 0 ? <></> : <IssuesList plant={plant} />;
}

type LockType =
  | "mine"
  | "others"
  | "pending"
  | "loading"
  | "released"
  | "error"
  | "wait";

export function IssuesList({ plant }: { plant: PlantProps }) {
  const { data, status, error, refetch, isRefetching } = useIssues({
    plant,
    request: "issuelist",
  });
  const items = data ? [...data.getIssueList].reverse() : undefined;
  const { search } = useLocation();
  const parsedSearch = useMemo(() => new URLSearchParams(search), [search]);
  const {
    mutate: issuesCommand,
    status: issuesCommandStatus,
    error: issuesCommandError,
    reset: issuesCommandReset,
    data: issuesCommandData,
  } = useIssuesCommand();
  const { mutate: issuesCommandSilent, status: issuesCommandSilentStatus } =
    useIssuesCommand();
  const { accounts } = useMsal();
  const canBeLocked = useMemo(
    () =>
      !!data &&
      data?.getIssueList.length > 0 &&
      !!accounts &&
      (data.UserProtected === "" ||
        data.UserProtected === " " ||
        // The empty string and the space has the same meaning,
        // the API is inconsistent depdending on how the protection is acquired
        data.UserName === accounts[0].username),
    [data, accounts]
  );
  const [initialRequest, setInitialRequest] = useState(true);
  const [wantToLeave, setWantToLeave] = useState(false);
  const [lockRelease, setLockRelease] = useState(false);
  const [messageShown, setMessageShown] = useState(false);
  useEffect(() => {
    setWantToLeave(false);
    setMessageShown(false);
  }, [plant]);
  const { isConfirmed } = useConfirm();

  const lock: LockType = useMemo(
    () =>
      data?.getIssueList.length === 0
        ? "wait"
        : issuesCommandSilentStatus === "error"
        ? "error"
        : data && accounts
        ? isRefetching
          ? "loading"
          : data.UserProtected === "P"
          ? data.UserName === accounts[0].username
            ? "mine"
            : "others"
          : lockRelease
          ? "released"
          : "pending"
        : "loading",
    [data, accounts, lockRelease, issuesCommandSilentStatus, isRefetching]
  );
  const isEditable = useMemo(
    () => lock === "mine" || data?.getIssueList.length === 0,
    [lock, data]
  );

  useEffect(
    () => {
      return () => {
        issuesCommandSilent({
          command: "user-protection",
          SetUserProtectIndicator: "N",
          UserName: accounts[0].username,
          plant,
        });
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    if (initialRequest) {
      canBeLocked &&
        issuesCommandSilent({
          command: "user-protection",
          SetUserProtectIndicator: "Y",
          UserName: accounts[0].username,
          plant,
        });
    } else {
      setInitialRequest(false);
    }
  }, [initialRequest, canBeLocked, issuesCommandSilent, accounts, plant]);

  useEffect(() => {
    if (
      lock === "pending" &&
      (issuesCommandSilentStatus !== "loading" ||
        issuesCommandStatus !== "loading")
    ) {
      issuesCommandSilent({
        command: "user-protection",
        SetUserProtectIndicator: "Y",
        UserName: accounts[0].username,
        plant,
      });
    }
    // This reacquires the lock in case it is not being acquired but should be acquired.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lock]);

  useEffect(() => {
    // Trying to prevent the user to leave the Issue page without releasing the lock
    // this warns the user before closing the window (also reload) if the issue is locked for the user.
    // Note: if there were no interactions on the page, onbeforeunload won't be fired.
    function listener(e: BeforeUnloadEvent) {
      if (lock === "mine") {
        setWantToLeave(true);
        e.preventDefault();
        return (e.returnValue = "Are you sure?");
      }
    }

    window.addEventListener("beforeunload", listener);

    return () => {
      window.removeEventListener("beforeunload", listener);
    };
  }, [lock]);

  const LockIndicatorContent = (
    <LockIndicator
      lock={lock}
      userName={data?.UserName}
      date={data?.UserEntryTime}
      wantToLeave={wantToLeave}
      setWantToLeave={setWantToLeave}
      setLockRelease={setLockRelease}
    />
  );

  return plant.PlantID === 0 ? (
    <ContentMessage>Select a plant first.</ContentMessage>
  ) : (
    <>
      {wantToLeave && (
        <MessageModal
          isOpen={wantToLeave}
          title="Release lock before exiting"
          closeButton={false}
        >
          <ModalWindowSimpleContainer>
            If you plan to leave the application, release the lock on this Issue
            first.
            <br />
            <br />
            <SimpleButtonContainer style={{ justifyContent: "space-between" }}>
              {LockIndicatorContent}
              <Button variant="outlined" onClick={() => setWantToLeave(false)}>
                Close
              </Button>
            </SimpleButtonContainer>
          </ModalWindowSimpleContainer>
        </MessageModal>
      )}
      <MessageModal
        isOpen={!messageShown && lock === "others"}
        title="Issue locked"
        onClose={() => {
          setMessageShown(true);
        }}
        shouldCloseOnOverlayClick={true}
      >
        <Paragraphs>
          <p>
            Issues of this plant have been locked by {data?.UserName} at{" "}
            {data?.UserEntryTime}.
          </p>
          <p>Edit controls are disabled.</p>
        </Paragraphs>
      </MessageModal>
      <IssuesContextProvider
        issuesCommand={issuesCommand}
        issues={items}
        isEditable={isEditable}
      >
        {parsedSearch.get("edit") && (
          <EditIssue
            plant={plant}
            issues={items}
            isEditable={isEditable}
            LockIndicator={LockIndicatorContent}
          />
        )}
        <Table
          nonVirtual={true}
          refetch={refetch}
          items={items}
          columns={[
            {
              key: "IssueRevision",
              title: "Issue Revision",
              width: 105,
              align: "center",
              sort: "natural",
            },
            {
              key: "RevDate",
              title: "Revision Date",
              width: 105,
              align: "center",
            },
            {
              key: "Status",
              title: "Status",
              Component: StatusChip,
              width: 60,
              align: "center",
            },
            {
              key: "General",
              title: "General",
              Component: IssueSheetData,
              type: "with-context",
            },
            {
              key: "PCS",
              title: "PCS",
              Component: IssueSheetData,
              type: "with-context",
            },
            {
              key: "SC",
              title: "SC",
              Component: IssueSheetData,
              type: "with-context",
            },
            {
              key: "VSM",
              title: "VSM",
              Component: IssueSheetData,
              type: "with-context",
            },
            {
              key: "EDS",
              title: "EDS",
              Component: IssueSheetData,
              type: "with-context",
            },
            {
              key: "VDS",
              title: "VDS",
              Component: IssueSheetData,
              type: "with-context",
            },
            {
              key: "VSK",
              title: "VSK",
              Component: IssueSheetData,
              type: "with-context",
            },
            {
              key: "MDS",
              title: "MDS",
              Component: IssueSheetData,
              type: "with-context",
            },
            {
              key: "ESK",
              title: "ESK",
              Component: IssueSheetData,
              type: "with-context",
            },
          ]}
          status={status}
          error={error}
          itemIdProp="IssueRevision"
          selectionMode={false}
          sortable={true}
          Commands={[OpenIssueCommandButton]}
          autoSizeColumns={true}
          centerColumnHeaders={true}
          RowMenu={IssueItemMenu}
          controlHeader={
            <ControlHeaderContainer>
              <FlexContainer>
                <div>
                  <FlexContainer
                    style={{
                      justifyContent: "flex-start",
                      gap: 16,
                      marginLeft: 8,
                    }}
                  >
                    <ControlHeaderTitle>
                      Issues<Fade> in </Fade>
                      {plant.LongDescription}
                    </ControlHeaderTitle>
                    {LockIndicatorContent}
                  </FlexContainer>
                </div>
                <HeaderButtonContainer>
                  <div>
                    <HeaderProgress isRefetching={isRefetching} />
                  </div>
                  <Tooltip title="Reload">
                    <Button variant="ghost_icon" onClick={() => refetch()}>
                      <Icon data={refresh} />
                    </Button>
                  </Tooltip>
                  <SimpleDownloads
                    columns={[
                      {
                        key: "IssueRevision",
                        title: "Issue Revision",
                      },
                      {
                        key: "RevDate",
                        title: "Revision Date",
                      },
                      {
                        key: "Status",
                        title: "Status",
                      },
                      {
                        key: "GeneralRevision",
                        title: "General Revision",
                      },
                      {
                        key: "GeneralRevDate",
                        title: "General Rev. Date",
                      },
                      {
                        key: "PCSRevision",
                        title: "PCS Revision",
                      },
                      {
                        key: "PCSRevDate",
                        title: "PCS Rev. Date",
                      },
                      {
                        key: "SCRevision",
                        title: "SC Revision",
                      },
                      {
                        key: "SCRevDate",
                        title: "SC Rev. Date",
                      },
                      {
                        key: "VSMRevision",
                        title: "VSM Revision",
                      },
                      {
                        key: "VSMRevDate",
                        title: "VSM Rev. Date",
                      },
                      {
                        key: "EDSRevision",
                        title: "EDS Revision",
                      },
                      {
                        key: "EDSRevDate",
                        title: "EDS Rev. Date",
                      },
                      {
                        key: "VDSRevision",
                        title: "VDS Revision",
                      },
                      {
                        key: "VDSRevDate",
                        title: "VDS Rev. Date",
                      },
                      {
                        key: "VSKRevision",
                        title: "VSK Revision",
                      },
                      {
                        key: "VSKRevDate",
                        title: "VSK Rev. Date",
                      },
                      {
                        key: "MDSRevision",
                        title: "MDS Revision",
                      },
                      {
                        key: "MDSRevDate",
                        title: "MDS Rev. Date",
                      },
                      {
                        key: "ESKRevision",
                        title: "ESK Revision",
                      },
                      {
                        key: "ESKRevDate",
                        title: "ESK Rev. Date",
                      },
                    ]}
                    items={items}
                    filename={`${plant.ShortDescription}_Issues`}
                  />
                  <Tooltip title="Create new Issue">
                    <Button
                      variant="ghost_icon"
                      onClick={async () =>
                        (await isConfirmed(
                          "Are you sure you want to create a new Issue?"
                        )) &&
                        issuesCommand({
                          command: "create",
                          plant,
                        })
                      }
                      disabled={
                        !isEditable ||
                        !data ||
                        data.getIssueList
                          .map((i) => i.Status)
                          .some((i) => i === "W")
                      }
                    >
                      <Icon data={add} />
                    </Button>
                  </Tooltip>
                </HeaderButtonContainer>
              </FlexContainer>
            </ControlHeaderContainer>
          }
        />
      </IssuesContextProvider>
      <StatusModal
        error={issuesCommandError}
        status={issuesCommandStatus}
        onSettledClose={issuesCommandReset}
        successMessage={successMessage(issuesCommandData)}
      />
    </>
  );
}
