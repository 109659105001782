import {
  FlexColumnContainer,
  FlexColumnElement,
  FlexContainer,
  FlexElement,
} from "components/Components";
import { Fieldset, FieldsetContainer } from "components/form/Form";
import { Field } from "components/form/Field";
import { useCodelist } from "queries/useCodelist";
import useOperators from "queries/useOperators";
import usePlant from "queries/usePlant";
import { useEffect, useRef } from "react";
import { useMemo } from "use-memo-one";
import { EditComponentProps } from "../ItemEditModal";
import { combineQueryStatuses } from "queries/queryUtil";
import { Button } from "@equinor/eds-core-react";
import { successMessage } from "utils/successMessage";
import StatusModal from "components/StatusModal";
import useIssuesCommand from "queries/useIssuesCommand";

export function PlantsEdit({
  edit,
  setEditLoading,
  setInitializing,
  setChildStatus,
  setChildError,
}: EditComponentProps<"plants">) {
  const {
    data: item,
    status,
    error,
    isRefetching,
  } = usePlant({ plantId: edit ? Number(edit) : 0, cacheTime: 0 });

  const { current: initialRevisionOptions } = useRef(["0", "1"]);

  const { data: plantCategory, status: plantCategoryStatus } = useCodelist({
    codelist: "plant-categories",
  });
  const plantCategoryOptions = useMemo(
    () =>
      plantCategory
        ? plantCategory.map((e) => ({
            id: String(e.CategoryID),
            option: e.Description,
          }))
        : [],
    [plantCategory]
  );

  const { data: operators, status: operatorsStatus } = useOperators();
  const operatorsOptions = useMemo(
    () =>
      operators
        ? operators.map((e) => ({
            id: e.OperatorID,
            option: e.OperatorName,
          }))
        : [],
    [operators]
  );

  const { data: areas, status: areasStatus } = useCodelist({
    codelist: "areas",
  });
  const areasOptions = useMemo(
    () =>
      areas ? areas.map((e) => ({ id: e.AreaID, option: e.Description })) : [],
    [areas]
  );

  const combinedStatuses = combineQueryStatuses(
    status,
    plantCategoryStatus,
    operatorsStatus,
    areasStatus
  );

  useEffect(() => {
    setEditLoading && setEditLoading(true);
    setInitializing && setInitializing(true);
    // disabled until ready
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    status !== "loading" && setInitializing && setInitializing(false);
    combinedStatuses === "success" &&
      (!isRefetching || !edit) &&
      setEditLoading &&
      setEditLoading(false);
  }, [
    status,
    combinedStatuses,
    setInitializing,
    setEditLoading,
    edit,
    isRefetching,
  ]);

  useEffect(() => {
    setChildError && setChildError(error);
    setChildStatus && setChildStatus(status);
  }, [status, error, setChildError, setChildStatus]);

  const {
    mutate: issuesCommand,
    status: issuesCommandStatus,
    error: issuesCommandError,
    reset: issuesCommandReset,
    data: issuesCommandData,
  } = useIssuesCommand();

  return (
    <>
      <FlexColumnContainer>
        <FlexColumnElement>
          <FieldsetContainer>
            <Fieldset>
              <Field
                area="plants"
                type="text"
                title="Short Description"
                prop="ShortDescription"
                value={item ? item.ShortDescription : ""}
                maxLength={8}
                uppercase
              />
              <Field
                area="plants"
                type="text"
                title="Project"
                prop="Project"
                value={item ? item.Project : ""}
              />
              <Field
                area="plants"
                type="option"
                title="Initial Revision"
                prop="InitialRevision"
                value={item ? item.InitialRevision : "0"}
                options={initialRevisionOptions}
                maxWidth={50}
                disableNotSetOption={true}
              />
            </Fieldset>
            <Fieldset>
              <Field
                area="plants"
                type="text"
                title="Long Description"
                prop="LongDescription"
                value={item ? item.LongDescription : ""}
              />
              <Field
                area="plants"
                type="text"
                title="Web Info Text"
                prop="WebInfoText"
                value={item ? item.WebInfoText : ""}
              />
              <Field
                area="plants"
                type="text"
                title="Remark Text"
                prop="WindowsRemarkText"
                value={item ? item.WindowsRemarkText : ""}
              />
              <Field
                area="plants"
                type="text"
                title="Bolt Tension"
                prop="BoltTensionText"
                value={item ? item.BoltTensionText : ""}
                rows={5}
              />
            </Fieldset>
            <Fieldset>
              <FlexContainer
                style={{ gap: 16, justifyContent: "flex-start", marginTop: 8 }}
              >
                <FlexElement>
                  <Button
                    variant="outlined"
                    onClick={() => {
                      item &&
                        issuesCommand({
                          command: "user-protection",
                          SetUserProtectIndicator: "N",
                          UserName: "",
                          plant: item,
                        });
                    }}
                    disabled={item && item.UserProtected !== "P"}
                  >
                    Release Issue lock
                  </Button>
                </FlexElement>
                <FlexElement>
                  {item && item.UserProtected === "P"
                    ? "Issues locked."
                    : "Issues not locked."}
                </FlexElement>
              </FlexContainer>
            </Fieldset>
          </FieldsetContainer>
        </FlexColumnElement>
        <FlexColumnElement>
          <FieldsetContainer>
            <Fieldset>
              <Field
                area="plants"
                type="option"
                title="Category"
                prop="CategoryID"
                value={item ? item.CategoryID : "1"}
                optionsWithIds={plantCategoryOptions}
                status={plantCategoryStatus}
              />
              <Field
                area="plants"
                type="option"
                title="Operator"
                prop="OperatorID"
                value={item ? item.OperatorID : "1"}
                optionsWithIds={operatorsOptions}
                status={operatorsStatus}
              />
              <Field
                area="plants"
                type="option"
                title="Area"
                prop="AreaID"
                value={item ? item.AreaID : "1"}
                optionsWithIds={areasOptions}
                status={areasStatus}
              />
            </Fieldset>
            <Fieldset slim>
              <Field
                area="plants"
                type="checkbox"
                title="PCS Embedded Notes"
                prop="EnableEmbeddedNote"
                value={item ? item.EnableEmbeddedNote : "N"}
              />
              <Field
                area="plants"
                type="checkbox"
                title="Copy PCS From All Plant"
                prop="EnableCopyPCSFromPlant"
                value={item ? item.EnableCopyPCSFromPlant : "N"}
              />
              <Field
                area="plants"
                type="checkbox"
                title="Document Space Source"
                prop="DocumentSpaceLink"
                value={item ? item.DocumentSpaceLink : "N"}
              />
              <Field
                area="plants"
                type="checkbox"
                title="Bolt Over Length"
                prop="OverLength"
                value={item ? item.OverLength : "N"}
              />
              <Field
                area="plants"
                type="checkbox"
                title="PCS-specific EDS for Mech. Joint"
                prop="EDSMJ"
                value={item ? item.EDSMJ : "N"}
              />
            </Fieldset>
            <Fieldset slim>
              <Field
                area="plants"
                type="checkbox"
                title="PCS Approval"
                prop="PCSQA"
                value={item ? item.PCSQA : "N"}
              />
              <Field
                area="plants"
                type="checkbox"
                title="Celsius/Bar Measurement"
                prop="CelsiusBar"
                value={item ? item.CelsiusBar : "N"}
              />
            </Fieldset>
            <Fieldset slim>
              <Field
                area="plants"
                type="checkbox"
                title="Visible"
                prop="Visible"
                value={item ? item.Visible : "N"}
              />
            </Fieldset>
          </FieldsetContainer>
        </FlexColumnElement>
      </FlexColumnContainer>
      <StatusModal
        error={issuesCommandError}
        status={issuesCommandStatus}
        onSettledClose={issuesCommandReset}
        successMessage={successMessage(issuesCommandData)}
      />
    </>
  );
}
