import Table, { SmallTableContainer } from "../../../components/table/Table";
import { StatusChip } from "../../../components/Components";
import { useTableSelect } from "../../../components/table/useTableSelect";
import useSheets from "../../sheets/queries/useSheets";
import { PlantProps } from "../../plant/types";
import { PreviewButtons } from "../../sheets/SheetPane";
import { SheetTypes } from "../../sheets/config/sheetConfig";
import { useMemoOne as useMemo } from "use-memo-one";

export default function useSheetSelector({
  sheetType,
  plant,
  defaultSelection,
  nameFilter,
}: {
  sheetType: SheetTypes;
  plant: PlantProps;
  defaultSelection?: string[];
  nameFilter: string;
}): {
  sheetSelectorContent: React.ReactFragment;
  selection: string[];
  selectedLines: any[];
  selectionDispatch: Function;
  isLoading: boolean;
} {
  const { data, status, isLoading, error, refetch } = useSheets({
    plant,
    sheetType,
    extraParams: {
      nameFilter,
      ...(sheetType === "mds" || sheetType === "vsk"
        ? { allVariants: "Y" }
        : {}),
    },
  });

  const { selectionMode, selection, selectionDispatch } = useTableSelect({
    selectionMode: "single",
    selection: defaultSelection,
  });

  const columns = useMemo(
    () => [
      {
        key: sheetType.toUpperCase(),
        title: sheetType.toUpperCase(),
        width: sheetType === "mds" ? 105 : 86,
      },
      { key: "Revision", title: "Rev.", longTitle: "Revision" },
      { key: "RevDate", title: "Rev. Date", longTitle: "Revision Date" },
      { key: "LastUpdate", title: "Last Update" },
      { key: "LastUpdateBy", title: "Last Update By" },
      { key: "Status", title: "Status", Component: StatusChip },
      ...(sheetType === "vds"
        ? [
            { key: "SizeRange", title: "Size Range" },
            { key: "Description", title: "Description", width: "100%" },
          ]
        : []),
      ...(sheetType === "mds"
        ? [{ key: "Area", title: "Area", width: 160 }]
        : []),
    ],
    [sheetType]
  );

  const sheetSelectorContent = (
    <SmallTableContainer>
      <Table
        density="compact"
        items={data && data.sheets}
        itemIdProp="itemID"
        columns={columns}
        status={status}
        selectionMode={selectionMode}
        selection={selection}
        selectionDispatch={selectionDispatch}
        sortable={true}
        fullRowSelect={true}
        error={error}
        refetch={refetch}
        loadingString="Loading Data Sheets..."
        selectedFirst={true}
        RowMenu={PreviewButtons}
        contextData={{ sheetType, plant }}
        fillRest={true}
      />
    </SmallTableContainer>
  );

  return {
    sheetSelectorContent,
    selection,
    selectionDispatch,
    selectedLines:
      selection && data
        ? selection.map((s) => data.sheets.find((i: any) => i.itemID === s))
        : [],
    isLoading,
  };
}
