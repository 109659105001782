import config from "config/config";

const baseTag = document.getElementsByTagName("base");

export const appProps = {
  appBasePath:
    baseTag && baseTag[0] && typeof baseTag[0].getAttribute !== "undefined"
      ? String(baseTag[0].getAttribute("href"))
      : "/",
  appBaseUrl: window.location.protocol + "//" + window.location.host,
  version:
    process.env.NODE_ENV === "development"
      ? `Development`
      : config.properties.buildNumber,
};
