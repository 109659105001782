import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { Button } from "@equinor/eds-core-react";
import {
  AppTitle,
  AppTitleFullContainer,
} from "components/AppDisplayComponents";
import { handleLogout } from "UserMenu";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { userPower } from "app/userConfig";
import {
  ButtonContainer,
  FullPageContainer,
  Loader,
  PreLine,
  TotalCenter,
} from "components/Components";
import { SignInButton, signInStateMessages } from "components/SignInButton";
import { combineQueryStatuses } from "queries/queryUtil";
import { CodelistSpecification, useCodelist } from "queries/useCodelist";
import useUser, { UserData, UserInfo, noUserInfo } from "queries/useUser";
import React, {
  createContext,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { selectUIState, setShowAlpha, setShowBeta } from "uiSlice";
import { useSettingsQuery } from "features/settings/useSettingsQuery";

type Settings = { [key: string]: string };

type UserConfigData = {
  caps: string[];
  userInfo: UserInfo;
  configParams: CodelistSpecification["configuration-parameters"][];
  previewBaseUrl: string;
  settingsRef: React.MutableRefObject<Settings>;
  sitePrefix: string;
};

const initialUserConfigData: UserConfigData = {
  caps: [],
  userInfo: noUserInfo,
  configParams: [],
  previewBaseUrl: "",
  settingsRef: {} as UserConfigData["settingsRef"],
  sitePrefix: "",
};

export const UserConfigContext = createContext<UserConfigData>(
  initialUserConfigData
);

export const useUserConfig = (): UserConfigData => {
  return useContext(UserConfigContext);
};

export const UserAccessGate: React.FC = ({ children }) => {
  const isAuthenticated = useIsAuthenticated();
  const { inProgress } = useMsal();

  return (
    <>
      {inProgress !== "none" ? (
        <Loader
          topLabel={<AppTitle secondary presightSolutionsLogo />}
          label={signInStateMessages[inProgress]}
        />
      ) : isAuthenticated ? (
        <UserGate>{children}</UserGate>
      ) : (
        <TotalCenter>
          <div style={{ marginBottom: 12 }}>
            <AppTitle secondary presightSolutionsLogo />
          </div>
          <div style={{ minHeight: 72, paddingTop: 10 }}>
            <SignInButton buttonText="Sign in" />
          </div>
        </TotalCenter>
      )}
    </>
  );
};

function DisplayLoadError({
  error,
  title,
}: {
  error: Error | null;
  title: string;
}) {
  return error instanceof Error ? (
    <PreLine>
      {title}: {error.message}
    </PreLine>
  ) : null;
}

const UserGate: React.FC = ({ children }) => {
  const { instance, inProgress } = useMsal();

  const {
    data: userData,
    status: userStatus,
    error: userError,
  } = useUser({ disabled: inProgress !== "none" });
  const {
    data: roleData,
    status: roleStatus,
    error: roleError,
  } = useCodelist({ codelist: "capability-role-matrix" });

  const combinedStatus = useMemo(
    () => combineQueryStatuses(userStatus, roleStatus),
    [userStatus, roleStatus]
  );

  const { showRoleTest, roleTest, adminRoleTest } =
    useAppSelector(selectUIState);

  const compiledUserData = useMemo<UserData | undefined>(
    () =>
      showRoleTest && userData
        ? {
            userInfo: {
              ...userData.userInfo,
              Role: roleTest,
              AdminRole: adminRoleTest,
            },
            userCaps:
              roleData
                ?.filter(
                  (role) =>
                    (userPower[role.Role] <= userPower[roleTest] &&
                      (roleTest === "X" ||
                        role.AdminRole === "" ||
                        role.AdminRole === adminRoleTest ||
                        adminRoleTest === "X")) ||
                    false
                )
                .map((role) => role.Capability) || [],
          }
        : userData,
    [showRoleTest, userData, roleTest, adminRoleTest, roleData]
  );

  const dispatch = useAppDispatch();

  const defaultYouCanTry = (
    <div style={{ textAlign: "center" }}>
      You can try the following:
      <ButtonContainer style={{ justifyContent: "center" }}>
        <Button variant="outlined" onClick={() => window.location.reload()}>
          Reload
        </Button>
        <Button onClick={() => handleLogout(instance)} variant="outlined">
          Sign out and sign in with a different user
        </Button>
      </ButtonContainer>
    </div>
  );

  useLayoutEffect(() => {
    if (userData) {
      if (userData.userInfo.Role === "X") {
        dispatch(setShowAlpha(false));
        dispatch(setShowBeta(true));
      } else {
        dispatch(setShowAlpha(false));
        dispatch(setShowBeta(false));
      }
    }
  }, [userData, dispatch]);

  if (combinedStatus === "success" && compiledUserData) {
    return <ConfigGate userData={compiledUserData}>{children}</ConfigGate>;
  } else if (combinedStatus === "loading") {
    return (
      <TotalCenter>
        <Loader
          topLabel={<AppTitle secondary presightSolutionsLogo />}
          label="Loading User Data..."
        />
      </TotalCenter>
    );
  } else if (combinedStatus === "error") {
    return (
      <FullPageContainer>
        <AppTitleFullContainer>
          <AppTitle />
        </AppTitleFullContainer>
        {userError && userError.request.status === 401 ? (
          <>
            <h2>
              {userError && userError.response
                ? userError.response.data.exceptionMessage
                : "User Unauthorized."}
            </h2>
            {userError &&
            userError.response &&
            userError.response.data.exceptionMessage ===
              "TR2000 user not found. No access." ? (
              <>
                <p>
                  This is a restricted application only for TR2000 users. Please
                  request access through AccessIT if access is required.
                </p>
                <div style={{ textAlign: "center", marginTop: "2em" }}>
                  Or you can try the following:
                  <ButtonContainer style={{ justifyContent: "center" }}>
                    <Button
                      onClick={() => handleLogout(instance)}
                      variant="outlined"
                    >
                      Sign out and sign in with a different user
                    </Button>
                  </ButtonContainer>
                </div>
              </>
            ) : (
              defaultYouCanTry
            )}
          </>
        ) : (
          <>
            <h2>Error Loading User Data.</h2>
            {defaultYouCanTry}
            <DisplayLoadError error={userError} title="User Data" />
            <DisplayLoadError error={roleError} title="Role Data" />
            <p>
              {userError &&
                userError.response &&
                userError.response.data &&
                userError.response.data.exceptionMessage}
            </p>
          </>
        )}
      </FullPageContainer>
    );
  } else {
    return <div>UserGate is idle.</div>;
  }
};

const ConfigGate: React.FC<{ userData: UserData }> = ({
  children,
  userData,
}) => {
  const { instance } = useMsal();

  const {
    data: settingsData,
    status: settingsStatus,
    error: settingsError,
    failureCount: settingsFailureCount,
  } = useSettingsQuery();
  const {
    data: configParams,
    status: configParamsStatus,
    error: configParamsError,
    failureCount: configParamsFailureCount,
  } = useCodelist({
    codelist: "configuration-parameters",
  });

  const combinedStatus = useMemo(
    () => combineQueryStatuses(settingsStatus, configParamsStatus),
    [settingsStatus, configParamsStatus]
  );
  const failureCount = useMemo(
    () => settingsFailureCount + configParamsFailureCount,
    [settingsFailureCount, configParamsFailureCount]
  );
  const [showMoreOptions, setShowMoreOptions] = useState(false);

  const previewBaseUrl = useMemo(
    () => configParams?.find((e) => e.ID === 1)?.URL ?? "",
    [configParams]
  );

  const settingsRef = useRef<Settings>({});

  const [settingsRefSet, setSettingsRefSet] = useState(false);

  useEffect(() => {
    if (settingsData) {
      settingsRef.current = settingsData
        ? Object.fromEntries(
            settingsData.getUserSettings.map((setting) => [
              setting.Key,
              setting.Value,
            ])
          )
        : {};
      setSettingsRefSet(true);
    }
  }, [settingsData]);

  if (combinedStatus === "success") {
    if (!previewBaseUrl) {
      throw new Error("No previewBaseUrl in Configuration Parameters.");
    } else {
      if (userData && configParams && settingsRefSet) {
        return (
          <UserConfigContext.Provider
            value={{
              userInfo: userData.userInfo,
              caps: userData.userCaps,
              configParams,
              previewBaseUrl,
              settingsRef: settingsRef,
              sitePrefix: configParams.find((e) => e.ID === 31)?.URL || "",
            }}
          >
            {children}
          </UserConfigContext.Provider>
        );
      } else {
        return (
          <Loader
            topLabel={<AppTitle secondary presightSolutionsLogo />}
            label="Processing..."
          />
        );
      }
    }
  } else if (combinedStatus === "loading") {
    return (
      <TotalCenter>
        <Loader
          topLabel={<AppTitle secondary presightSolutionsLogo />}
          label={`${failureCount > 0 ? "Still " : ""}Loading Configuration...`}
        />
      </TotalCenter>
    );
  } else if (combinedStatus === "error") {
    return (
      <FullPageContainer>
        <AppTitleFullContainer>
          <AppTitle />
        </AppTitleFullContainer>
        <h2>Error Loading Configuration.</h2>
        <div style={{ textAlign: "center", margin: "1em 0" }}>
          <p>You can try the following:</p>
        </div>
        <ButtonContainer style={{ justifyContent: "center" }}>
          <Button onClick={() => window.location.reload()}>Reload</Button>
          <Button
            variant="outlined"
            onClick={() => setShowMoreOptions(true)}
            disabled={showMoreOptions}
          >
            More options...
          </Button>
        </ButtonContainer>
        {showMoreOptions && (
          <ButtonContainer style={{ justifyContent: "center" }}>
            <SignInButton buttonText="Sign in again" variant="outlined" />
            <Button onClick={() => handleLogout(instance)} variant="outlined">
              Sign out
            </Button>
          </ButtonContainer>
        )}
        <DisplayLoadError error={settingsError} title="Settings Data" />
        <DisplayLoadError
          error={configParamsError}
          title="Configuration Parameters Data"
        />
      </FullPageContainer>
    );
  } else {
    return <div>ConfigGate is idle.</div>;
  }
};
