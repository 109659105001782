import { Button } from "@equinor/eds-core-react";
import { useUserConfig } from "UserConfigContext";
import config from "config/config";
import { settingKey } from "features/settings/settingsKey";
import { useSettings } from "features/settings/useSettings";
import useCopyVDS from "features/sheets/queries/useCopyVDS";
import useCreateEDS from "features/sheets/queries/useCreateEDS";
import useSetSheetStatusMultiple from "features/sheets/queries/useSetSheetStatusMultiple";
import {
  SheetFilter,
  SheetsReturnData,
  SheetsTypeData,
  useSheetList,
  useSheetsNamesNew,
} from "features/sheets/queries/useSheetList";
import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useReducer,
  useRef,
  useState,
} from "react";
import { useForm } from "react-hook-form";
import { QueryStatus } from "react-query";
import { useHistory, useLocation } from "react-router-dom";
import * as Space from "react-spaces";
import useMeasure from "react-use-measure";
import { selectUIState } from "uiSlice";
import { useMemoOne as useMemo } from "use-memo-one";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  AbsoluteFlexContainer,
  BottomButtonContainer,
  ContentMessage,
  FlexContentElement,
  FlexHeaderElement,
  ValveTypeCodeContainer,
  getStatusChips,
} from "../../components/Components";
import StatusModal from "../../components/StatusModal";
import useConfirm from "../../components/confirm/useConfirm";
import {
  FilterProps,
  filtersReducer,
  getServerSideFilters,
} from "../../components/table/Filters";
import Table, { ColumnsProps } from "../../components/table/Table";
import { useTableSelect } from "../../components/table/useTableSelect";
import { combineQueryStatuses } from "../../queries/queryUtil";
import { useCodelist } from "../../queries/useCodelist";
import { filterItemsByName } from "../../utils/filterItemsByName";
import {
  CommonObjectContent,
  filterItemsByProps,
} from "../../utils/filterItemsByProps";
import { successMessage } from "../../utils/successMessage";
import { areArraysOfObjectsIdentical } from "../../utils/util";
import { selectMainPlant, selectSourcePlant } from "../plant/plantSlice";
import { PlantProps } from "../plant/types";
import {
  EditCommandButton,
  PreviewInPaneCommandButton,
} from "./CommandButtons";
import ItemMenu from "./ItemMenu";
import { SheetControlHeader } from "./SheetControlHeader";
import { SheetPane } from "./SheetPane";
import { VDSTaskMonitor } from "./VDSTaskMonitor";
import {
  copySelected,
  deleteSelected,
  deleteSingle,
  editSheet,
} from "./commands";
import {
  ListSheetsDisplayTypes,
  SheetTypes,
  minTableWidth,
  pcsWorkbenchCommands,
  plantDependentSheets,
  sheetDocumentWidth,
  sheetProperties,
  sheetTypeNames,
  textualSheets,
  vdsWorkbenchCommands,
} from "./config/sheetConfig";
import {
  MaybeSheetStatus,
  SheetStatus,
  sheetStatusConfig,
} from "./config/statusConfig";
import { NewNameInput } from "./modals/CopySheets";
import EditHTMLModal from "./modals/EditHTML";
import { SetStatus } from "./modals/SetStatus";
import { CreateEDS, NewEDSOperation } from "./modals/eds/CreateEDS";
import EditPCSModal from "./modals/pcs/EditPCS";
import { EditVDSSubsegment } from "./modals/vds-subsegments/EditVDSSubsegment";
import { NewVDSSubsegment } from "./modals/vds-subsegments/NewVDSSubsegment";
import { SubsegmentFilter } from "./modals/vds-subsegments/SubsegmentFilter";
import { VDSSubsegmentPropertiesModal } from "./modals/vds-subsegments/VDSSubsegmentProperties";
import { EditVDSTextBlockDescription } from "./modals/vds-textblocks/EditDescription";
import { EditVDSTextBlock } from "./modals/vds-textblocks/EditVDSTextBlock";
import { NewVDSTextBlock } from "./modals/vds-textblocks/NewVDSTextBlock";
import { VDSTextBlockPropertiesModal } from "./modals/vds-textblocks/VDSTextBlockProperties";
import { EditVDS } from "./modals/vds/EditVDS";
import { VDSPropertiesModal } from "./modals/vds/VDSProperties";
import PCSPipeElementsPanel from "./panels/PCSPipeElementsPanel";
import PCSPropertiesPanel from "./panels/PCSPropertiesPanel";
import PCSReferencePanel from "./panels/PCSReferencePanel";
import PCSStandardNotesPanel from "./panels/PCSStandardNotesPanel";
import { VDSReplaceSubsegment } from "./panels/VDSReplaceSubsegmentPanel";
import { VDSUpdateContent } from "./panels/VDSUpdateContentPanel";
import { useAffectedVDSByReplacementQuery } from "./queries/useAffectedVDSByReplacementQuery";
import useCopySheets from "./queries/useCopySheets";
import useDeleteSheets from "./queries/useDeleteSheets";
import useNewSheetRevision from "./queries/useNewSheetRevision";
import useNewSheetRevisions from "./queries/useNewSheetRevisions";
import useSetSheetStatus from "./queries/useSetSheetStatus";
import { SheetsData } from "./queries/useSheets";
import {
  getSheetState,
  hideSheetCopyModal,
  setModalMutationStatuses,
  setMutationStatuses,
  setSheetType,
} from "./sheetSlice";
import { SheetCommands } from "./types";
import { useAttemptSetVDSSubsegmentStatusExpired } from "./modals/vds-subsegments/useAttemptSetVDSSubsegmentStatusExpired";

export type SheetProps = {
  name: string;
  revision: string;
};

export type HashProps = { [index: string]: string };

export default function Sheets<T extends SheetTypes>({
  plant,
  sheetType,
  displayType,
  newRevision,
  sheetCommand,
  closeModal,
}: {
  plant: PlantProps;
  sheetType: T;
  displayType: ListSheetsDisplayTypes;
  newRevision?: string;
  sheetCommand?: SheetCommands;
  closeModal?: () => void;
}) {
  return (
    <SheetsDisplay
      plant={plant}
      sheetType={sheetType}
      displayType={displayType}
      sheetCommand={sheetCommand}
      newRevision={newRevision}
      closeModal={closeModal}
      // Remount on change:
      key={
        sheetType +
        (plantDependentSheets.includes(sheetType) ? plant.PlantID : "") +
        displayType +
        sheetCommand
      }
    />
  );
}

function SheetsDisplay<T extends SheetTypes>({
  plant,
  sheetType,
  displayType,
  newRevision,
  sheetCommand,
  closeModal,
}: {
  plant: PlantProps;
  sheetType: T;
  displayType: ListSheetsDisplayTypes;
  newRevision?: string;
  sheetCommand?: SheetCommands;
  closeModal?: () => void;
}) {
  const isPCSWorkbenchCommand = !!(
    sheetCommand && pcsWorkbenchCommands.some((w) => w === sheetCommand)
  );
  const isVDSWorkbenchCommand = !!(
    sheetCommand && vdsWorkbenchCommands.some((w) => w === sheetCommand)
  );
  const serverSideStatusFiltering =
    ((sheetType === "vds" && sheetCommand !== "replace-subsegment") ||
      sheetType === "vds-unstructured") &&
    !!config.useAPIFilters;
  const serverSideFiltering =
    ((sheetType === "vds" && sheetCommand !== "replace-subsegment") ||
      sheetType === "vds-unstructured") &&
    !!config.useAPIFilters;
  const isSubsegmentWorkbench =
    sheetType === "vds" && sheetCommand === "replace-subsegment";

  const { search, hash } = useLocation();
  const parsedSearch = useMemo(() => new URLSearchParams(search), [search]);
  const editName = parsedSearch.get("edit") ?? "";
  const editRevision = parsedSearch.get("rev") ?? "";
  const editTab = parsedSearch.get("tab") ?? "";
  const isEdit = !!editName && !!editRevision;

  /* @experiment */
  const hashParts = useMemo(
    () =>
      hash
        ? Object.fromEntries(
            decodeURI(hash)
              .substring(1)
              .split("&")
              .map((e) => e.split("="))
          )
        : {},
    [hash]
  );

  const {
    mutationStatuses,
    modalMutationStatuses,
    pipeElementFilter,
    noteFilter,
    subsegmentRefFilter,
    subsegmentRefFilterRev,
  } = useAppSelector(getSheetState);

  const { paneModes, showVDSTaskMonitor } = useAppSelector(selectUIState);
  const isCopyModal = displayType === "copyModal";
  const hasName = sheetType !== "general";
  const sheetTypeName = sheetTypeNames[sheetType];

  const {
    statuses: sheetStatuses,
    filterNameProperty: definedFilternameProperty,
    nameProperty,
    filterPlaceholder,
    filterFirst,
    apiNoMultiAction,
  } = sheetProperties[sheetType];

  const filterNameProperty = definedFilternameProperty
    ? definedFilternameProperty
    : nameProperty;

  const columns = useMemo<ColumnsProps[]>(
    () => [
      ...(isCopyModal && sheetType !== "general"
        ? [
            {
              type: "with-context",
              key: "NewName",
              title: "New Name",
              Component: NewNameInput,
            } as const,
          ]
        : []),
      ...sheetProperties[sheetType].columns.filter(
        (col) =>
          !col?.display ||
          (isPCSWorkbenchCommand && col?.display === "workbench") ||
          (!isSubsegmentWorkbench && col?.display === "no-replace-workbench") ||
          (isSubsegmentWorkbench && col?.display === "only-replace-workbench")
      ),
    ],
    [isCopyModal, sheetType, isPCSWorkbenchCommand, isSubsegmentWorkbench]
  );

  const filterKeySheetType = useMemo(
    () => settingKey(["sheets", sheetType, "filters"]),
    [sheetType]
  );

  const settings = useSettings();

  const filterKey = useMemo(
    () =>
      settingKey([
        "sheets",
        sheetType,
        "filters",
        {
          ...(plantDependentSheets.includes(sheetType) ? { isCopyModal } : {}),
          ...(sheetType === "pcs" ? { isPCSWorkbenchCommand } : {}), // every PCS workbench share the saved filter state
          ...(sheetType === "vds" ? { sheetCommand } : {}), // VDS workbenches do not share the saved filter state
        },
      ]),
    [sheetType, isCopyModal, isPCSWorkbenchCommand, sheetCommand]
  );

  const dispatch = useAppDispatch();
  const sourcePlant = useAppSelector(selectSourcePlant);
  const mainPlant = useAppSelector(selectMainPlant);

  const [containerRef, containerSize] = useMeasure();

  const history = useHistory();

  const [filteringDisabled, setFilteringDisabled] = useState(!!filterFirst);

  // availableStatuses contains the statuses for this sheetType and displayType
  const availableStatuses = useMemo<SheetStatus[]>(
    () =>
      (
        (isPCSWorkbenchCommand
          ? ["W"]
          : isVDSWorkbenchCommand
          ? ["O", "W"]
          : isCopyModal
          ? sheetType === "sc"
            ? ["O"]
            : ["O", "W", "I"]
          : sheetStatuses) as SheetStatus[]
      ).sort(
        (a, b) =>
          Object.keys(sheetStatusConfig).indexOf(a) -
          Object.keys(sheetStatusConfig).indexOf(b)
      ),
    [
      isPCSWorkbenchCommand,
      isVDSWorkbenchCommand,
      isCopyModal,
      sheetType,
      sheetStatuses,
    ]
  );

  const defaultStatusFilter = useMemo<SheetStatus[]>(
    () =>
      availableStatuses.includes("I")
        ? ["O", "W", "I"]
        : availableStatuses.includes("O")
        ? ["O", "W"]
        : ["W"],
    [availableStatuses]
  );

  const defaultAllSelected = "";

  const [statusFilter, setStatusFilter] = useState(
    hashParts.statusFilter
      ? hashParts.statusFilter
          .split(",")
          .filter((e: string) =>
            availableStatuses.includes(e as unknown as SheetStatus)
          )
      : settings[settingKey([filterKey, "Status"])]
      ? settings[settingKey([filterKey, "Status"])].split(",")
      : defaultStatusFilter
  );
  const [newRevisionStatusFilter, setNewRevisionStatusFilter] = useState("");
  const [nameFilter, setNameFilter] = useState(
    hashParts.nameFilter ??
      settings[
        settingKey([
          filterKeySheetType,
          "nameFilter",
          // Name filters are shared in different screens inside sheetTypes, except in the replace-subsegment VDS workbench:
          ...(isSubsegmentWorkbench ? ["replace-subsegment"] : []),
        ])
      ] ??
      ""
  );
  const [singleValveTypeFilter, setSingleValveTypeFilter] =
    useState(defaultAllSelected);
  const [multiValveTypeFilter, setMultiValveTypeFilter] = useState([]);
  const [ratingClassFilter, setRatingClassFilter] =
    useState(defaultAllSelected);
  const { current: defaultEmptyArray } = useRef([]);
  const [groupFilter, setGroupFilter] = useState(defaultAllSelected);
  const [materialGroupFilter, setMaterialGroupFilter] =
    useState(defaultAllSelected);
  const [endConnectionFilter, setEndConnectionFilter] =
    useState(defaultAllSelected);
  const [boreFilter, setBoreFilter] = useState(defaultAllSelected);
  const [vdsSizeFilter, setVdsSizeFilter] = useState(defaultAllSelected);
  const [specialRequirementFilter, setSpecialRequirementFilter] =
    useState(defaultAllSelected);
  const [itemFilter, setItemFilter] = useState(defaultAllSelected);
  const [sectionFilter, setSectionFilter] = useState(defaultAllSelected);
  const [userFilter, setUserFilter] = useState(defaultAllSelected);
  const [subsegmentFilter, setSubsegmentFilter] = useState([]);
  const [textBlockFilter, setTextBlockFilter] = useState("");

  const [filtersSetup, setFiltersSetup] = useState(false);

  const [filters, filtersDispatch] = useReducer(
    filtersReducer,
    [] as FilterProps[]
  );
  const [confirmedFilters, confirmedFiltersDispatch] = useReducer(
    filtersReducer,
    [] as FilterProps[]
  );

  const { sitePrefix } = useUserConfig();

  const sheetFilter: SheetFilter = useMemo(
    () => ({
      ...getServerSideFilters(confirmedFilters),
      ...(serverSideFiltering ? { nameFilter: nameFilter + "*" } : {}),
      ...(isCopyModal && ["sc", "vsm"].includes(sheetType)
        ? { freeformatFilter: "F" as "F" }
        : {}),
      ...(sheetType === "vds"
        ? { basedOnSubsegment: "Y" as const }
        : sheetType === "vds-unstructured"
        ? { basedOnSubsegment: "N" as const }
        : {}),
      ...(noteFilter !== 0 ? { noteId: String(noteFilter) } : {}),
      ...(pipeElementFilter !== 0
        ? { elementId: String(pipeElementFilter) }
        : {}),
      ...(subsegmentRefFilter !== 0
        ? { subsegmentRef: String(subsegmentRefFilter) }
        : {}),
      ...(sheetType === "vds" && sheetCommand === "update-content" && sitePrefix
        ? {
            nameFilter: sitePrefix + (nameFilter || "*"),
          }
        : {}),
    }),
    [
      confirmedFilters,
      serverSideFiltering,
      nameFilter,
      isCopyModal,
      sheetType,
      noteFilter,
      pipeElementFilter,
      subsegmentRefFilter,
      sheetCommand,
      sitePrefix,
    ]
  );

  const {
    status: sheetListStatus,
    data: sheetListData,
    error: sheetListError,
    isRefetching: sheetListIsRefetching,
    refetch: sheetListRefetch,
    remove: sheetListRemove,
  } = useSheetList({
    plant,
    sheetType,
    sheetFilter,
    disabled:
      isSubsegmentWorkbench ||
      !filtersSetup ||
      (filters &&
        filters.reduce((a, c) => a || !!c.serverSide, false) &&
        filteringDisabled),
  });

  const {
    status: affectedVDSStatus,
    data: affectedVDSData,
    error: affectedVDSError,
    isRefetching: affectedVDSIsRefetching,
    refetch: affectedVDSRefetch,
    remove: affectedVDSRemove,
  } = useAffectedVDSByReplacementQuery({
    subsegmentId: subsegmentRefFilter,
    subsegmentRevision: subsegmentRefFilterRev,
    enabled: isSubsegmentWorkbench,
  });

  const mainItems = isSubsegmentWorkbench ? affectedVDSData : sheetListData;
  const mainItemsStatus = isSubsegmentWorkbench
    ? affectedVDSStatus
    : sheetListStatus;
  const mainItemsError = isSubsegmentWorkbench
    ? affectedVDSError
    : sheetListError;
  const isRefetching = isSubsegmentWorkbench
    ? affectedVDSIsRefetching
    : sheetListIsRefetching;
  const refetch = useCallback(() => {
    if (isSubsegmentWorkbench) {
      affectedVDSRefetch();
    } else {
      sheetListRefetch();
    }
  }, [isSubsegmentWorkbench, affectedVDSRefetch, sheetListRefetch]);
  const remove = useCallback(() => {
    sheetListRemove();
    affectedVDSRemove();
  }, [sheetListRemove, affectedVDSRemove]);

  useEffect(() => {
    if (isSubsegmentWorkbench) {
      affectedVDSRemove();
    }
  }, [isSubsegmentWorkbench, affectedVDSRemove]);

  const {
    data: mainItemsNames,
    status: mainItemsNamesStatus,
    error: mainItemsNamesError,
  } = useSheetsNamesNew({
    plant: mainPlant,
    sheetType,
    disabled: !isCopyModal || !plantDependentSheets.includes(sheetType),
  });

  const [createNew, setCreateNew] = useState(false);
  const [showCreateNew, setShowCreateNew] = useState(false);
  const [showCopy, setShowCopy] = useState(false);
  const [newEDSOperation, setNewEDSOperation] =
    useState<NewEDSOperation>(false);
  const [newEDSName, setNewEDSName] = useState("");
  const [newEDSRevision, setNewEDSRevision] = useState("0");
  const [sourceEDSName, setSourceEDSName] = useState("");
  const [sourceEDSRevision, setSourceEDSRevision] = useState("");
  const [modifiedItems, setModifiedItems] = useState<any[]>([]);
  const itemsCopy = useRef<SheetsData>({ sheets: [] });
  const [newNames, setNewNames] = useState<any>({});
  const [listOfSetOfficial, setListOfSetOfficial] = useState<string[]>([]);
  const [wantToSetStatus, setWantToSetStatus] =
    useState<MaybeSheetStatus>(undefined);
  const [selectedSheet, setSelectedSheet] = useState<SheetProps>({
    name: "",
    revision: "",
  });
  const { isConfirmed } = useConfirm();

  /***************/
  /*** Filters ***/
  /***************/

  const applyConfirmedFilters = useCallback(() => {
    confirmedFiltersDispatch({ type: "replace", payload: filters });
    setFilteringDisabled(false);
  }, [confirmedFiltersDispatch, filters]);

  const restoreConfirmedFilters = useCallback(() => {
    if (confirmedFilters.length) {
      filtersDispatch({ type: "replace", payload: confirmedFilters });
      filters.forEach((filter) => {
        const confirmedState = confirmedFilters.find(
          (e) => e.prop === filter.prop
        )?.filterState;
        confirmedState && filter.setFilterState(confirmedState);
      });
    }
  }, [confirmedFilters, filters]);

  const filterCodelists = filters.map((filter) => filter.codelist);

  const {
    data: valveTypeCodelist,
    status: valveTypeCodelistStatus,
    error: valveTypeCodelistError,
  } = useCodelist({
    codelist: "valve-types",
    enabled: filterCodelists.includes("valve-types"),
  });
  const {
    data: vdsSubsegmentGroupCodelist,
    status: vdsSubsegmentGroupCodelistStatus,
    error: vdsSubsegmentGroupCodelistError,
  } = useCodelist({
    codelist: "vds-subsegment-groups",
    enabled: filterCodelists.includes("vds-subsegment-groups"),
  });
  const {
    data: materialGroupsCodelist,
    status: materialGroupsCodelistStatus,
    error: materialGroupsCodelistError,
  } = useCodelist({
    codelist: "material-groups",
    enabled:
      filterCodelists.includes("material-groups") ||
      sheetType === "vds-subsegments",
  });
  const {
    data: endConnectionCodelist,
    status: endConnectionCodelistStatus,
    error: endConnectionCodelistError,
  } = useCodelist({
    codelist: "end-connections",
    enabled: filterCodelists.includes("end-connections"),
  });
  const {
    data: ratingClassCodelist,
    status: ratingClassCodelistStatus,
    error: ratingClassCodelistError,
  } = useCodelist({
    codelist: "rating-classes",
    enabled: filterCodelists.includes("rating-classes"),
  });
  const {
    data: boreCodelist,
    status: boreCodelistStatus,
    error: boreCodelistError,
  } = useCodelist({
    codelist: "bores",
    enabled:
      filterCodelists.includes("bores") || sheetType === "vds-subsegments",
  });
  const {
    data: vdsSizeCodelist,
    status: vdsSizeCodelistStatus,
    error: vdsSizeCodelistError,
  } = useCodelist({
    codelist: "vds-sizes",
    enabled:
      filterCodelists.includes("vds-sizes") || sheetType === "vds-subsegments",
  });
  const {
    data: specialRequirementCodelist,
    status: specialRequirementCodelistStatus,
    error: specialRequirementCodelistError,
  } = useCodelist({
    codelist: "special-requirements",
    enabled:
      filterCodelists.includes("special-requirements") ||
      sheetType === "vds-subsegments",
  });

  const codelistsStatuses = useMemo(
    () => [
      valveTypeCodelistStatus,
      vdsSubsegmentGroupCodelistStatus,
      materialGroupsCodelistStatus,
      endConnectionCodelistStatus,
      ratingClassCodelistStatus,
      boreCodelistStatus,
      vdsSizeCodelistStatus,
      specialRequirementCodelistStatus,
    ],
    [
      boreCodelistStatus,
      endConnectionCodelistStatus,
      materialGroupsCodelistStatus,
      ratingClassCodelistStatus,
      specialRequirementCodelistStatus,
      valveTypeCodelistStatus,
      vdsSizeCodelistStatus,
      vdsSubsegmentGroupCodelistStatus,
    ]
  );

  const allErrors = useMemo(
    () => ({
      ...{ mainItemsError },
      ...{ mainItemsNamesError },
      ...{ valveTypeCodelistError },
      ...{ vdsSubsegmentGroupCodelistError },
      ...{ materialGroupsCodelistError },
      ...{ endConnectionCodelistError },
      ...{ ratingClassCodelistError },
      ...{ boreCodelistError },
      ...{ vdsSizeCodelistError },
      ...{ specialRequirementCodelistError },
    }),
    [
      boreCodelistError,
      endConnectionCodelistError,
      mainItemsError,
      mainItemsNamesError,
      materialGroupsCodelistError,
      ratingClassCodelistError,
      specialRequirementCodelistError,
      valveTypeCodelistError,
      vdsSizeCodelistError,
      vdsSubsegmentGroupCodelistError,
    ]
  );

  const combinedCodelistStatuses = useMemo(
    () => combineQueryStatuses(...codelistsStatuses),
    [codelistsStatuses]
  );

  const combinedStatuses = useMemo(
    () =>
      combineQueryStatuses(
        mainItemsStatus,
        mainItemsNamesStatus,
        ...codelistsStatuses
      ),
    [codelistsStatuses, mainItemsNamesStatus, mainItemsStatus]
  );

  const valveTypeCodelistFilterItems = useMemo(
    () =>
      valveTypeCodelist
        ? valveTypeCodelist.map((i: any) => ({
            key: i.ValveTypeID,
            title: (
              <>
                <ValveTypeCodeContainer>{i.VDSCode}</ValveTypeCodeContainer>{" "}
                {i.Description}
              </>
            ),
          }))
        : [],
    [valveTypeCodelist]
  );

  const sectionIds = useMemo(
    () =>
      sheetType === "vds-textblocks" && mainItems
        ? (Array.from(
            new Set(
              (mainItems as SheetsReturnData<"vds-textblocks">).sheets.map(
                (e) => e.ItemSection
              )
            )
          ) as string[])
        : [],
    [mainItems, sheetType]
  );

  const itemIds = useMemo(
    () =>
      sheetType === "vds-textblocks" && mainItems
        ? Array.from(
            new Set(
              (mainItems as SheetsReturnData<"vds-textblocks">).sheets.map(
                (e) => String(e.ItemID)
              )
            )
          ).sort((a, b) => a.localeCompare(b, undefined, { numeric: true }))
        : [],
    [mainItems, sheetType]
  );

  const vdsSubsegmentGroupOptions = useMemo(
    () =>
      vdsSubsegmentGroupCodelist
        ? vdsSubsegmentGroupCodelist.map((i) => ({
            key: String(i.GroupID),
            title: i.Description,
          }))
        : [],
    [vdsSubsegmentGroupCodelist]
  );

  const ratingClassOptions = useMemo(
    () =>
      ratingClassCodelist
        ? ratingClassCodelist.map((i) => ({
            key: String(i.RatingClassID),
            title: i.Description,
          }))
        : [],
    [ratingClassCodelist]
  );

  const materialGroupOptions = useMemo(
    () =>
      materialGroupsCodelist
        ? materialGroupsCodelist.map((i) => ({
            key: String(i.MaterialGroupID),
            title: i.MaterialGroup,
          }))
        : [],
    [materialGroupsCodelist]
  );

  const endConnectionOptions = useMemo(
    () =>
      endConnectionCodelist
        ? endConnectionCodelist.map((i) => ({
            key: String(i.EndConnectionID),
            title: `(${i.VDSCode}) ${i.Description}`,
          }))
        : [],
    [endConnectionCodelist]
  );

  const valveTypeOptions = useMemo(
    () =>
      valveTypeCodelist
        ? valveTypeCodelist.map((i) => ({
            key: String(i.ValveTypeID),
            title: `(${i.VDSCode}) ${i.Description}`,
          }))
        : [],
    [valveTypeCodelist]
  );

  const boreOptions = useMemo(
    () =>
      boreCodelist
        ? boreCodelist.map((i) => ({
            key: String(i.BoreID),
            title: i.Description,
          }))
        : [],
    [boreCodelist]
  );

  const vdsSizeOptions = useMemo(
    () =>
      vdsSizeCodelist
        ? vdsSizeCodelist.map((i) => ({
            key: String(i.VDSSizeID),
            title: i.Description,
          }))
        : [],
    [vdsSizeCodelist]
  );

  const specialRequirementOptions = useMemo(
    () =>
      specialRequirementCodelist
        ? specialRequirementCodelist.map((i) => ({
            key: String(i.SpecialRequirementID),
            title: i.Description,
          }))
        : [],
    [specialRequirementCodelist]
  );

  const userOptions = useMemo(
    () =>
      mainItems
        ? Array.from(
            new Set([
              ...mainItems.sheets.map((e) => e.LastUpdateBy),
              ...(userFilter !== "" ? [userFilter] : []),
            ])
          )
        : userFilter
        ? [userFilter]
        : [],
    [mainItems, userFilter]
  );

  useLayoutEffect(() => {
    if (["vds-subsegments", "vds-textblocks"].includes(sheetType)) {
      filtersDispatch({
        type: "update",
        payload: {
          type: "select",
          title: "Group",
          prop: "GroupID",
          filterOptions: vdsSubsegmentGroupOptions,
          filterState: groupFilter,
          defaultFilterState: defaultAllSelected,
          setFilterState: setGroupFilter,
          commaSeparated: false,
          group: "Selections",
          codelist: "vds-subsegment-groups",
          apiFilterPorp: "groupId",
          serverSide: serverSideFiltering,
        },
      });
    }
  }, [groupFilter, sheetType, vdsSubsegmentGroupOptions, serverSideFiltering]);

  useLayoutEffect(() => {
    if (["vds-textblocks"].includes(sheetType)) {
      filtersDispatch({
        type: "update",
        payload: {
          type: "select",
          title: "Section",
          prop: "ItemSection",
          filterOptions: sectionIds,
          filterState: sectionFilter,
          defaultFilterState: defaultAllSelected,
          setFilterState: setSectionFilter,
          commaSeparated: false,
          group: "Selections",
        },
      });
    }
  }, [sectionFilter, sectionIds, sheetType]);

  useLayoutEffect(() => {
    if (["vds-textblocks"].includes(sheetType)) {
      filtersDispatch({
        type: "update",
        payload: {
          type: "select",
          title: "Item",
          prop: "ItemID",
          filterOptions: itemIds,
          filterState: itemFilter,
          defaultFilterState: defaultAllSelected,
          setFilterState: setItemFilter,
          commaSeparated: false,
          group: "Selections",
        },
      });
    }
  }, [itemFilter, itemIds, sheetType]);

  useLayoutEffect(() => {
    if (["vds-subsegments"].includes(sheetType)) {
      filtersDispatch({
        type: "update",
        payload: {
          type: "select",
          title: "Rating Class",
          prop: "RatingClass",
          filterOptions: ratingClassOptions,
          filterState: ratingClassFilter,
          defaultFilterState: defaultAllSelected,
          setFilterState: setRatingClassFilter,
          commaSeparated: true,
          group: "Selections",
          codelist: "rating-classes",
          apiFilterPorp: "ratingClassId",
          serverSide: serverSideFiltering,
        },
      });
    }
  }, [ratingClassFilter, ratingClassOptions, sheetType, serverSideFiltering]);

  useLayoutEffect(() => {
    if (sheetType === "vds-subsegments") {
      filtersDispatch({
        type: "update",
        payload: {
          type: "select",
          title: "Material Group",
          prop: "MaterialGroup",

          filterOptions: materialGroupOptions,
          filterState: materialGroupFilter,
          defaultFilterState: defaultAllSelected,
          setFilterState: setMaterialGroupFilter,
          commaSeparated: true,
          group: "Selections",
          codelist: "material-groups",
          apiFilterPorp: "materialGroupId",
          serverSide: serverSideFiltering,
        },
      });
    }
  }, [
    materialGroupFilter,
    materialGroupOptions,
    sheetType,
    serverSideFiltering,
  ]);

  useLayoutEffect(() => {
    if (["vds-subsegments"].includes(sheetType)) {
      filtersDispatch({
        type: "update",
        payload: {
          type: "select",
          title: "End Connection",
          prop: "EndConnection",
          filterOptions: endConnectionOptions,
          filterState: endConnectionFilter,
          defaultFilterState: defaultAllSelected,
          setFilterState: setEndConnectionFilter,
          commaSeparated: true,
          group: "Selections",
          codelist: "end-connections",
          apiFilterPorp: "endConnectionId",
          serverSide: serverSideFiltering,
        },
      });
    }
  }, [
    endConnectionFilter,
    endConnectionOptions,
    sheetType,
    serverSideFiltering,
  ]);

  useLayoutEffect(() => {
    if (["vds-subsegments"].includes(sheetType)) {
      filtersDispatch({
        type: "update",
        payload: {
          type: "multiselect",
          title: "Valve Types",
          prop: "ValveType",
          filterOptions: valveTypeCodelistFilterItems,
          filterState: multiValveTypeFilter,
          defaultFilterState: defaultEmptyArray,
          setFilterState: setMultiValveTypeFilter,
          commaSeparated: true,
          group: "Valve Types",
          codelist: "valve-types",
          isArray: true,
          apiFilterPorp: "valveTypeId",
          serverSide: serverSideFiltering,
        },
      });
    }
  }, [
    defaultEmptyArray,
    multiValveTypeFilter,
    sheetType,
    valveTypeCodelistFilterItems,
    serverSideFiltering,
  ]);

  useLayoutEffect(() => {
    if (sheetType === "vds" && !isSubsegmentWorkbench) {
      filtersDispatch({
        type: "update",
        payload: {
          type: "select",
          title: "Valve Type",
          prop: "ValveTypeID",
          filterOptions: valveTypeOptions,
          filterState: singleValveTypeFilter,
          defaultFilterState: defaultAllSelected,
          setFilterState: setSingleValveTypeFilter,
          commaSeparated: false,
          group: "Valve Characteristics",
          codelist: "valve-types",
          apiFilterPorp: "valveTypeId",
          serverSide: serverSideFiltering,
        },
      });
    }
  }, [
    sheetType,
    singleValveTypeFilter,
    valveTypeOptions,
    serverSideFiltering,
    isSubsegmentWorkbench,
  ]);

  useLayoutEffect(() => {
    if (sheetType === "vds" && !isSubsegmentWorkbench) {
      filtersDispatch({
        type: "update",
        payload: {
          type: "select",
          title: "Rating Class",
          prop: "RatingClassID",
          filterOptions: ratingClassOptions,
          filterState: ratingClassFilter,
          defaultFilterState: defaultAllSelected,
          setFilterState: setRatingClassFilter,
          commaSeparated: false,
          group: "Valve Characteristics",
          codelist: "rating-classes",
          apiFilterPorp: "ratingClassId",
          serverSide: serverSideFiltering,
        },
      });
    }
  }, [
    ratingClassFilter,
    ratingClassOptions,
    sheetType,
    serverSideFiltering,
    isSubsegmentWorkbench,
  ]);

  useLayoutEffect(() => {
    if (sheetType === "vds" && !isSubsegmentWorkbench) {
      filtersDispatch({
        type: "update",
        payload: {
          type: "select",
          title: "Material Group",
          prop: "MaterialGroupID",
          filterOptions: materialGroupOptions,
          filterState: materialGroupFilter,
          defaultFilterState: defaultAllSelected,
          setFilterState: setMaterialGroupFilter,
          commaSeparated: false,
          group: "Valve Characteristics",
          codelist: "material-groups",
          apiFilterPorp: "materialGroupId",
          serverSide: serverSideFiltering,
        },
      });
    }
  }, [
    materialGroupFilter,
    materialGroupOptions,
    sheetType,
    serverSideFiltering,
    isSubsegmentWorkbench,
  ]);

  useLayoutEffect(() => {
    if (sheetType === "vds" && !isSubsegmentWorkbench) {
      filtersDispatch({
        type: "update",
        payload: {
          type: "select",
          title: "End Connection",
          prop: "EndConnectionID",
          filterOptions: endConnectionOptions,
          filterState: endConnectionFilter,
          defaultFilterState: defaultAllSelected,
          setFilterState: setEndConnectionFilter,
          commaSeparated: false,
          group: "Valve Characteristics",
          codelist: "end-connections",
          apiFilterPorp: "endConnectionId",
          serverSide: serverSideFiltering,
        },
      });
    }
  }, [
    endConnectionFilter,
    endConnectionOptions,
    sheetType,
    serverSideFiltering,
    isSubsegmentWorkbench,
  ]);

  useLayoutEffect(() => {
    if (sheetType === "vds" && !isSubsegmentWorkbench) {
      filtersDispatch({
        type: "update",
        payload: {
          type: "select",
          title: "Bore",
          prop: "BoreID",
          filterOptions: boreOptions,
          filterState: boreFilter,
          defaultFilterState: defaultAllSelected,
          setFilterState: setBoreFilter,
          commaSeparated: false,
          group: "Valve Characteristics",
          codelist: "bores",
          apiFilterPorp: "boreId",
          serverSide: serverSideFiltering,
        },
      });
    }
  }, [
    boreFilter,
    boreOptions,
    sheetType,
    serverSideFiltering,
    isSubsegmentWorkbench,
  ]);

  useLayoutEffect(() => {
    if (sheetType === "vds" && !isSubsegmentWorkbench) {
      filtersDispatch({
        type: "update",
        payload: {
          type: "select",
          title: "Size",
          prop: "VDSSizeID",
          filterOptions: vdsSizeOptions,
          filterState: vdsSizeFilter,
          defaultFilterState: defaultAllSelected,
          setFilterState: setVdsSizeFilter,
          commaSeparated: false,
          group: "Valve Characteristics",
          codelist: "vds-sizes",
          apiFilterPorp: "vdsSizeId",
          serverSide: serverSideFiltering,
        },
      });
    }
  }, [
    sheetType,
    vdsSizeFilter,
    vdsSizeOptions,
    serverSideFiltering,
    isSubsegmentWorkbench,
  ]);

  useLayoutEffect(() => {
    if (
      (sheetType === "vds" && !isSubsegmentWorkbench) ||
      (sheetType === "pcs" && isPCSWorkbenchCommand)
    ) {
      filtersDispatch({
        type: "update",
        payload: {
          type: "select",
          title: "Special Requirement",
          prop: "SpecialReqID",
          filterOptions: specialRequirementOptions,
          filterState: specialRequirementFilter,
          defaultFilterState: defaultAllSelected,
          setFilterState: setSpecialRequirementFilter,
          commaSeparated: false,
          group: "Priority",
          codelist: "special-requirements",
          apiFilterPorp: "specialReqId",
          serverSide: serverSideFiltering,
        },
      });
    }
  }, [
    isPCSWorkbenchCommand,
    isSubsegmentWorkbench,
    sheetType,
    specialRequirementFilter,
    specialRequirementOptions,
    serverSideFiltering,
  ]);

  useLayoutEffect(() => {
    if (sheetType === "vds" && !isSubsegmentWorkbench) {
      filtersDispatch({
        type: "update",
        payload: {
          type: "custom",
          title: "Subsegments",
          prop: "SubsegmentList",
          filterState: subsegmentFilter,
          defaultFilterState: [],
          setFilterState: setSubsegmentFilter,
          isArray: true,
          commaSeparated: true,
          group: "Subsegments",
          transformer: (s: string) => s.replaceAll(", ", ","),
          CustomFilterComponent: SubsegmentFilter,
        },
      });
    }
  }, [
    sheetType,
    subsegmentFilter,
    vdsSubsegmentGroupCodelist,
    isSubsegmentWorkbench,
  ]);

  useLayoutEffect(() => {
    if (sheetType === "vds" && !isSubsegmentWorkbench) {
      filtersDispatch({
        type: "update",
        payload: {
          type: "text",
          title: "Text Block",
          prop: "TextBlock",
          filterState: textBlockFilter,
          defaultFilterState: "",
          setFilterState: setTextBlockFilter,
          group: "Text Blocks",
          serverSide: serverSideFiltering,
          apiFilterPorp: "textBlockId",
          placeholder: "Text Block ID",
        },
      });
    }
  }, [isSubsegmentWorkbench, sheetType, textBlockFilter, serverSideFiltering]);

  useLayoutEffect(() => {
    filtersDispatch({
      type: "update",
      payload: {
        type: "checkboxes",
        title: "Statuses",
        prop: "Status",
        filterOptions: getStatusChips(availableStatuses),
        filterState: statusFilter,
        defaultFilterState: defaultStatusFilter,
        setFilterState: setStatusFilter,
        commaSeparated: false,
        group: "Statuses",
        isArray: true,
        apiFilterPorp: "statusFilter",
        serverSide: serverSideStatusFiltering,
        disabled: !!newRevisionStatusFilter,
      },
    });
  }, [
    statusFilter,
    defaultStatusFilter,
    availableStatuses,
    sheetType,
    settings,
    isCopyModal,
    isPCSWorkbenchCommand,
    serverSideStatusFiltering,
    newRevisionStatusFilter,
  ]);

  useLayoutEffect(() => {
    if (sheetType === "pcs" && !isPCSWorkbenchCommand) {
      filtersDispatch({
        type: "update",
        payload: {
          type: "switch",
          title: "New Revision Candidates",
          prop: "NewRevisionStatuses",
          filterState: newRevisionStatusFilter,
          defaultFilterState: "",
          setFilterState: setNewRevisionStatusFilter,
          group: "Statuses",
          filterFn: (item: SheetsTypeData<"pcs"> | CommonObjectContent) => {
            const itemsStatuses =
              mainItems?.sheets
                .filter((e) => (e as SheetsTypeData<"pcs">).PCS === item.PCS)
                .map((e) => e.Status) ?? [];
            return itemsStatuses.includes("W")
              ? false
              : item.Status === "I"
              ? true
              : !itemsStatuses.includes("I") && item.Status === "O"
              ? true
              : false;
          },
        },
      });
    }
  }, [
    defaultStatusFilter,
    newRevisionStatusFilter,
    sheetType,
    mainItems,
    isPCSWorkbenchCommand,
  ]);

  useLayoutEffect(() => {
    filtersDispatch({
      type: "update",
      payload: {
        type: "select",
        title: "Last Update By",
        prop: "LastUpdateBy",
        filterOptions: userOptions,
        filterState: userFilter,
        defaultFilterState: defaultAllSelected,
        setFilterState: setUserFilter,
        commaSeparated: false,
        group: "Users",
      },
    });
  }, [
    availableStatuses,
    sheetType,
    settings,
    isCopyModal,
    isPCSWorkbenchCommand,
    userFilter,
    userOptions,
  ]);

  useEffect(() => {
    // We can count on that there will be at least two filters, Status and LastUpdateBy
    !filtersSetup && filters.length > 1 && setFiltersSetup(true);
    // filtersSetup not observed on purpose.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  const { selectionMode, selection, selectionDispatch } = useTableSelect({
    selectionMode: apiNoMultiAction ? false : "multi",
  });

  useLayoutEffect(() => {
    !filterFirst && applyConfirmedFilters();
  }, [applyConfirmedFilters, filterFirst]);

  useLayoutEffect(() => {
    if (!filteringDisabled) {
      applyConfirmedFilters();
    }
    // If filtering is enabled, run confirmed filters anyway.
    // eslint-disable-next-line
  }, [filteringDisabled]);

  const displayItems = useMemo(
    () =>
      !filteringDisabled && mainItems
        ? filterItemsByProps({
            filters: confirmedFilters,
            alwaysPresent: { idProp: "itemID", ids: selection },
            items: filterItemsByName({
              nameFilter,
              nameProperty: filterNameProperty,
              items: mainItems && (mainItems.sheets as CommonObjectContent[]),
              alwaysPresent: { idProp: "itemID", ids: selection },
              skip: serverSideFiltering,
            }),
          })
        : [],
    // selection omitted on purpose.
    // eslint-disable-next-line
    [
      sheetCommand,
      mainItems,
      filteringDisabled,
      confirmedFilters,
      nameFilter,
      filterNameProperty,
      serverSideFiltering,
    ]
  );

  /*******************/
  /*** End Filters ***/
  /*******************/

  const { register, formState, trigger, reset } = useForm({
    shouldUnregister: true,
    mode: "all",
  });

  useLayoutEffect(() => {
    if (isCopyModal) {
      setTimeout(() => trigger());
    }
  }, [selection, reset, trigger, isCopyModal]);

  const { isCopyModalOpen } = useAppSelector(getSheetState);

  useEffect(() => {
    isCopyModal && isCopyModalOpen && reset();
  }, [isCopyModal, isCopyModalOpen, reset]);

  const {
    mutate: copySheets,
    status: copySheetsStatus,
    reset: copySheetsReset,
    error: copySheetsError,
    data: copySheetsData,
  } = useCopySheets();
  const {
    mutate: deleteSheets,
    status: deleteSheetsStatus,
    reset: deleteSheetsReset,
    error: deleteSheetsError,
    data: deleteSheetsData,
  } = useDeleteSheets();
  const {
    mutate: newSheetRevision,
    status: newSheetRevisionStatus,
    reset: newSheetRevisionReset,
    error: newSheetRevisionError,
    data: newSheetRevisionData,
  } = useNewSheetRevision();
  const {
    mutate: newSheetRevisions,
    status: newSheetRevisionsStatus,
    reset: newSheetRevisionsReset,
    error: newSheetRevisionsError,
    data: newSheetRevisionsData,
  } = useNewSheetRevisions();
  const setSheetStatusQuery = useSetSheetStatus();
  const {
    mutate: setSheetStatus,
    status: setSheetStatusStatus,
    reset: setSheetStatusReset,
    error: setSheetStatusError,
    data: setSheetStatusData,
  } = setSheetStatusQuery;
  const {
    mutate: setMultipleSheetStatus,
    status: setMultipleSheetStatusStatus,
    reset: setMultipleSheetStatusReset,
    error: setMultipleSheetStatusError,
    data: setMultipleSheetStatusData,
  } = useSetSheetStatusMultiple();
  const {
    mutate: createEDS,
    status: createEDSStatus,
    reset: createEDSReset,
    error: createEDSError,
    data: createEDSData,
  } = useCreateEDS();
  const {
    mutate: copyVDS,
    status: copyVDSStatus,
    reset: copyVDSReset,
    error: copyVDSError,
    data: copyVDSData,
  } = useCopyVDS();
  const [vdsCreateStatusCopy, setVDSCreateStatusCopy] =
    useState<QueryStatus>("idle");

  useEffect(() => {
    const allLocalMutationStatuses = [
      copySheetsStatus,
      deleteSheetsStatus,
      newSheetRevisionStatus,
      newSheetRevisionsStatus,
      setSheetStatusStatus,
      createEDSStatus,
      setMultipleSheetStatusStatus,
      vdsCreateStatusCopy,
    ];
    if (isCopyModal) {
      dispatch(setModalMutationStatuses(allLocalMutationStatuses));
    } else {
      dispatch(setMutationStatuses(allLocalMutationStatuses));
    }
    return () => {
      if (isCopyModal) {
        dispatch(setModalMutationStatuses([]));
      } else {
        dispatch(setMutationStatuses([]));
      }
    };
  }, [
    copySheetsStatus,
    deleteSheetsStatus,
    newSheetRevisionStatus,
    newSheetRevisionsStatus,
    setSheetStatusStatus,
    createEDSStatus,
    dispatch,
    isCopyModal,
    setMultipleSheetStatusStatus,
    vdsCreateStatusCopy,
  ]);

  const selectedItems = useMemo(
    () =>
      mainItems
        ? mainItems.sheets.filter((i: any) => selection.includes(i.itemID))
        : [],
    [selection, mainItems]
  );

  useEffect(() => {
    // This informs the eventual CopyModal of the sheetType.
    dispatch(setSheetType(sheetType));
  }, [sheetType, dispatch]);

  useEffect(() => {
    if (mainItems && isCopyModal) {
      setNewNames(
        mainItems.sheets.reduce(
          (acc: any, curr: any) => ({
            ...acc,
            [curr.itemID]: curr[sheetTypeName],
          }),
          {}
        )
      );
    }
  }, [mainItems, isCopyModal, sheetTypeName]);

  const allMutationStatuses = useMemo(
    () => [...mutationStatuses, ...modalMutationStatuses],
    [modalMutationStatuses, mutationStatuses]
  );
  const isAfterMutation = allMutationStatuses.includes("success");
  const isAllMutationIdle =
    allMutationStatuses.includes("idle") &&
    new Set(allMutationStatuses).size === 1;

  const [updatedItemsExpected, setUpdatedItemsExpected] = useState(false);

  useEffect(() => {
    isAfterMutation && setUpdatedItemsExpected(true);
    !isAfterMutation && !isRefetching && setUpdatedItemsExpected(false);
  }, [isAfterMutation, isRefetching]);

  useEffect(() => {
    if (updatedItemsExpected) {
      if (
        mainItems &&
        Array.isArray(mainItems.sheets) &&
        !areArraysOfObjectsIdentical(mainItems.sheets, itemsCopy.current.sheets)
      ) {
        const newLines = mainItems.sheets
          .map((mi) => mi.itemID)
          .filter(
            (mid) =>
              !itemsCopy.current.sheets
                .map((ic: any) => ic.itemID)
                .includes(mid)
          );
        const newStatuses = mainItems.sheets
          .filter(
            (mi) =>
              itemsCopy.current.sheets.filter(
                (ic: any) => ic.itemID === mi.itemID
              ).length > 0 &&
              itemsCopy.current.sheets.find(
                (ic: any) => ic.itemID === mi.itemID
              )?.Status !== mi?.Status
          )
          .map((i) => i.itemID);
        setModifiedItems([
          ...modifiedItems,
          ...newLines.map((i) => {
            return { itemID: i, element: "_line" };
          }),
          ...newStatuses.map((i) => {
            return { itemID: i, element: "Status" };
          }),
        ]);
      }
    }
    if (mainItemsStatus === "success" && mainItems) {
      itemsCopy.current = mainItems;
    }
  }, [mainItemsStatus, mainItems, modifiedItems, updatedItemsExpected]);

  useEffect(() => {
    if (isAllMutationIdle && modifiedItems.length > 0) {
      const nowModified = [...modifiedItems];
      const timeout = setTimeout(() => {
        setModifiedItems(
          modifiedItems.filter(
            (i) =>
              !nowModified.map(
                (nm) => nm.itemID === i.itemID && nm.element === i.element
              )
          )
        );
      }, 5000);
      return () => {
        clearTimeout(timeout);
      };
    }
  }, [modifiedItems, isAllMutationIdle]);

  const [panelRef, panelSize] = useMeasure();

  const codelists = useMemo(
    () => ({
      "vds-section": { Y: "New Layout", N: "Old Layout" },
      "tube-pcs": { M: "Metric", I: "Imperal" },
      "vds-subsegment-groups": vdsSubsegmentGroupCodelist
        ? Object.fromEntries(
            vdsSubsegmentGroupCodelist.map((i) => [i.GroupID, i.Description])
          )
        : {},
      "valve-types": valveTypeCodelist
        ? Object.fromEntries(
            valveTypeCodelist.map((i) => [i.ValveTypeID, i.VDSCode])
          )
        : {},
      "material-groups": materialGroupsCodelist
        ? Object.fromEntries(
            materialGroupsCodelist.map((i) => [
              i.MaterialGroupID,
              i.MaterialGroup,
            ])
          )
        : {},
      "end-connections": endConnectionCodelist
        ? Object.fromEntries(
            endConnectionCodelist.map((i) => [i.EndConnectionID, i.VDSCode])
          )
        : {},
      "rating-classes": ratingClassCodelist
        ? Object.fromEntries(
            ratingClassCodelist.map((i) => [i.RatingClassID, i.Description])
          )
        : {},
      bores: boreCodelist
        ? Object.fromEntries(boreCodelist.map((i) => [i.BoreID, i.Description]))
        : {},
      "vds-sizes": vdsSizeCodelist
        ? Object.fromEntries(
            vdsSizeCodelist.map((i) => [i.VDSSizeID, i.Description])
          )
        : {},
      "special-requirements": specialRequirementCodelist
        ? Object.fromEntries(
            specialRequirementCodelist.map((i) => [
              i.SpecialRequirementID,
              i.Description,
            ])
          )
        : {},
    }),
    [
      boreCodelist,
      endConnectionCodelist,
      materialGroupsCodelist,
      ratingClassCodelist,
      specialRequirementCodelist,
      valveTypeCodelist,
      vdsSizeCodelist,
      vdsSubsegmentGroupCodelist,
    ]
  );

  const { userInfo } = useUserConfig();

  const doNotOpenFilters = useMemo(
    () => sheetCommand === "replace-subsegment" || hashParts.nameFilter,
    [sheetCommand, hashParts.nameFilter]
  );

  /* @experiment */
  useEffect(() => {
    filtersSetup &&
      Object.keys(hashParts).length > 0 &&
      applyConfirmedFilters();
    // Only on load.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtersSetup]);

  const {
    attempSetVDSSubsegmentExpired,
    attemptSetVDSSubsegmentStatusExpiredContent,
  } = useAttemptSetVDSSubsegmentStatusExpired({
    setSheetStatusQuery,
  });

  const newSheetRevisionOnSuccess = useCallback(() => {
    selectionDispatch({ type: "reset", payload: [] });
  }, [selectionDispatch]);

  return plantDependentSheets.includes(sheetType) && mainPlant.PlantID === 0 ? (
    <ContentMessage>Select a plant.</ContentMessage>
  ) : (
    <AbsoluteFlexContainer ref={containerRef}>
      <FlexContentElement>
        <Space.Fill>
          <Space.Fill>
            {isPCSWorkbenchCommand && (
              <Space.Top size={panelSize.height}>
                {sheetCommand === "references" && (
                  <PCSReferencePanel
                    ref={panelRef}
                    selectedItems={selectedItems}
                  />
                )}
                {sheetCommand === "pipe-elements" && (
                  <PCSPipeElementsPanel
                    ref={panelRef}
                    selectedItems={selectedItems}
                  />
                )}
                {sheetCommand === "standard-notes" && (
                  <PCSStandardNotesPanel
                    ref={panelRef}
                    selectedItems={selectedItems}
                  />
                )}
                {sheetCommand === "properties" && (
                  <PCSPropertiesPanel
                    ref={panelRef}
                    selectedItems={selectedItems}
                  />
                )}
              </Space.Top>
            )}
            {isVDSWorkbenchCommand && sheetCommand && (
              <Space.Top size={panelSize.height}>
                {sheetCommand === "update-content" && (
                  <VDSUpdateContent
                    ref={panelRef}
                    selectedItems={selectedItems}
                  />
                )}
                {sheetCommand === "replace-subsegment" && (
                  <VDSReplaceSubsegment
                    ref={panelRef}
                    selectedItems={selectedItems}
                    setFilteringDisabled={setFilteringDisabled}
                    listOfSetOfficial={listOfSetOfficial}
                  />
                )}
              </Space.Top>
            )}
            <Space.Fill>
              <Table
                error={allErrors}
                totalCount={
                  serverSideFiltering && mainItems
                    ? "totalCount" in mainItems
                      ? mainItems.totalCount
                      : undefined
                    : mainItems
                    ? mainItems.sheets.length
                    : 0
                }
                infoline={true}
                items={displayItems}
                itemIdProp="itemID"
                controlHeader={
                  <SheetControlHeader
                    plant={plant}
                    mainPlant={mainPlant}
                    isCopyModal={isCopyModal}
                    sheetType={sheetType}
                    selectedItems={selectedItems}
                    deleteSelected={deleteSelected}
                    deleteSheets={deleteSheets}
                    hasName={hasName}
                    sheetTypeName={sheetTypeName}
                    nameProperty={nameProperty}
                    filterNameProperty={filterNameProperty}
                    nameFilter={nameFilter}
                    setNameFilter={setNameFilter}
                    isConfirmed={isConfirmed}
                    newSheetRevisions={newSheetRevisions}
                    setMultipleSheetStatus={setMultipleSheetStatus}
                    filters={filters}
                    mainItemsSheets={mainItems ? mainItems.sheets : []}
                    filteredItems={displayItems}
                    columns={columns}
                    refetch={refetch}
                    isRefetching={isRefetching}
                    dispatch={dispatch}
                    filterPlaceholder={
                      filterPlaceholder
                        ? filterPlaceholder
                        : `${sheetTypeName} filter`
                    }
                    containerSize={containerSize}
                    setCreateNew={setCreateNew}
                    setShowCreateNew={setShowCreateNew}
                    setNewEDSOperation={setNewEDSOperation}
                    isPCSWorkbenchCommand={isPCSWorkbenchCommand}
                    isVDSWorkbenchCommand={isVDSWorkbenchCommand}
                    filterKey={filterKey}
                    filterKeySheetType={filterKeySheetType}
                    applyConfirmedFilters={
                      filterFirst ? applyConfirmedFilters : undefined
                    }
                    restoreConfirmedFilters={
                      filterFirst ? restoreConfirmedFilters : undefined
                    }
                    combinedCodelistStatuses={combinedCodelistStatuses}
                    isEdit={isEdit}
                    doNotOpenFilters={doNotOpenFilters}
                    sheetCommand={sheetCommand}
                    filteringDisabled={filteringDisabled}
                    serverSide={serverSideFiltering}
                    hashParts={hashParts}
                  />
                }
                columns={columns}
                codelists={codelists}
                contextData={{
                  plant,
                  dispatch,
                  sheetType,
                  isCopyModal,
                  deleteSingle,
                  newSheetRevision,
                  newSheetRevisions,
                  setSheetStatus,
                  setMultipleSheetStatus,
                  sheetFilter,
                  hasName,
                  sheetTypeName,
                  nameProperty,
                  mainPlant,
                  deleteSheets,
                  isConfirmed,
                  newNames,
                  setNewNames,
                  trigger,
                  mainItemsNames,
                  register,
                  formState,
                  selectedItems,
                  setWantToSetStatus,
                  setSelectedSheet,
                  copyVDS,
                  setNewEDSOperation,
                  setSourceEDSName,
                  setSourceEDSRevision,
                  listOfSetOfficial,
                  setListOfSetOfficial,
                  setShowCopy,
                  attempSetVDSSubsegmentExpired,
                }}
                status={combinedStatuses}
                loadingString={`${
                  filteringDisabled ? "Preloading" : "Loading"
                } ${
                  mainItemsStatus === "loading"
                    ? `${sheetTypeName}s...`
                    : "Codelists..."
                }`}
                selectionMode={selectionMode}
                selection={selection}
                selectionDispatch={selectionDispatch}
                sortable={true}
                fullRowSelect={false}
                resizable={true}
                RowMenu={ItemMenu}
                Commands={[EditCommandButton, PreviewInPaneCommandButton]}
                itemsClassNames={modifiedItems.map((e) => ({
                  ...e,
                  className: "_newData",
                }))}
                refetch={refetch}
                message={
                  combinedStatuses === "success" && filteringDisabled
                    ? sheetCommand === "replace-subsegment"
                      ? "Select a Subsegment to replace."
                      : "Waiting for Filters."
                    : undefined
                }
                combinedCodelistsStatuses={combinedCodelistStatuses}
                isRefetching={isRefetching}
                remove={remove}
              />
              <StatusModal
                status={deleteSheetsStatus}
                successMessage={successMessage(deleteSheetsData)}
                onSettledClose={deleteSheetsReset}
                error={deleteSheetsError}
              />
              <StatusModal
                status={newSheetRevisionStatus}
                successMessage={successMessage(newSheetRevisionData)}
                onSettledClose={newSheetRevisionReset}
                error={newSheetRevisionError}
              />
              <StatusModal
                status={newSheetRevisionsStatus}
                successMessage={successMessage(newSheetRevisionsData)}
                onSettledClose={newSheetRevisionsReset}
                error={newSheetRevisionsError}
                onSuccess={newSheetRevisionOnSuccess}
              />
              <StatusModal
                status={setSheetStatusStatus}
                successMessage={successMessage(setSheetStatusData)}
                onSettledClose={setSheetStatusReset}
                error={setSheetStatusError}
              />
              <StatusModal
                status={setMultipleSheetStatusStatus}
                successMessage={successMessage(setMultipleSheetStatusData)}
                onSettledClose={setMultipleSheetStatusReset}
                error={setMultipleSheetStatusError}
              />
              <StatusModal
                status={copySheetsStatus}
                successMessage={successMessage(copySheetsData)}
                onSettledClose={copySheetsReset}
                onSuccessClose={() => {
                  dispatch(hideSheetCopyModal());
                }}
                error={copySheetsError}
              />
              <StatusModal
                status={createEDSStatus}
                successMessage={successMessage(createEDSData)}
                onSettledClose={createEDSReset}
                onSuccess={() => {
                  createEDSReset();
                  editSheet({
                    item: {
                      EDS: sitePrefix + newEDSName,
                      Revision: newEDSRevision,
                    },
                    history,
                    sheetType: "eds",
                  });
                }}
                error={createEDSError}
              />
              <StatusModal
                status={copyVDSStatus}
                successMessage={successMessage(copyVDSData)}
                onSuccess={() => {
                  setCreateNew(false);
                  setShowCreateNew(true);
                  copyVDSReset();
                }}
                onErrorClose={() => {
                  copyVDSReset();
                }}
                error={copyVDSError}
              />
              {isEdit &&
                ((textualSheets.includes(sheetType) && (
                  <EditHTMLModal
                    name={editName}
                    revision={editRevision}
                    sheetType={sheetType}
                  />
                )) ||
                  (sheetType === "pcs" && (
                    <EditPCSModal name={editName} revision={editRevision} />
                  )) ||
                  (sheetType === "vds" && (
                    <>
                      <EditVDS
                        name={editName}
                        revision={editRevision}
                        tab={editTab}
                        edit={true}
                      />
                      <VDSPropertiesModal
                        name={editName}
                        revision={editRevision}
                        tab={editTab}
                      />
                    </>
                  )) ||
                  (sheetType === "vds-textblocks" && (
                    <>
                      <EditVDSTextBlock
                        isEdit={true}
                        name={editName}
                        revision={editRevision}
                        tab={editTab}
                      />
                      <VDSTextBlockPropertiesModal
                        name={editName}
                        revision={editRevision}
                        tab={editTab}
                      />
                      <EditVDSTextBlockDescription
                        name={editName}
                        revision={editRevision}
                        tab={editTab}
                      />
                    </>
                  )) ||
                  (sheetType === "vds-subsegments" && (
                    <>
                      <EditVDSSubsegment
                        name={editName}
                        revision={editRevision}
                        tab={editTab}
                        isEdit={true}
                      />
                      <VDSSubsegmentPropertiesModal
                        name={editName}
                        revision={editRevision}
                        tab={editTab}
                      />
                    </>
                  )))}
              {wantToSetStatus && (
                <SetStatus
                  name={selectedSheet.name}
                  revision={selectedSheet.revision}
                  wantToSetStatus={wantToSetStatus}
                  setWantToSetStatus={setWantToSetStatus}
                  setSheetStatus={setSheetStatus}
                  sheetType={sheetType}
                />
              )}
            </Space.Fill>
          </Space.Fill>
          {showVDSTaskMonitor &&
            (
              ["vds", "vds-textblocks", "vds-subsegments"] as SheetTypes[]
            ).includes(sheetType) && (
              <Space.BottomResizable size={200}>
                <VDSTaskMonitor />
              </Space.BottomResizable>
            )}
          {(isCopyModal ? paneModes.copyModal : paneModes.main) && (
            <Space.RightResizable
              size={
                containerSize.width > sheetDocumentWidth + minTableWidth
                  ? sheetDocumentWidth
                  : "50%"
              }
              maximumSize={containerSize.width - minTableWidth}
            >
              <SheetPane location={isCopyModal ? "copyModal" : "main"} />
            </Space.RightResizable>
          )}
        </Space.Fill>
      </FlexContentElement>
      {isCopyModal ? (
        <FlexHeaderElement
          style={{
            textAlign: "center",
            borderTop: "1px solid var(--borderDefault)",
          }}
        >
          <BottomButtonContainer>
            <Button
              disabled={
                selection.length === 0 ||
                Object.keys(formState.errors).length > 0
              }
              onClick={() =>
                copySelected({
                  selectedItems,
                  newRevision,
                  hasName,
                  sheetTypeName,
                  newNames,
                  sourcePlant,
                  mainPlant,
                  copySheets,
                  sheetType,
                  isConfirmed,
                })
              }
            >
              Copy selected data sheets
            </Button>
            <Button
              variant="outlined"
              onClick={() => {
                closeModal && closeModal();
              }}
            >
              Close
            </Button>
          </BottomButtonContainer>
        </FlexHeaderElement>
      ) : (
        <></>
      )}
      {sheetType === "eds" && (
        <CreateEDS
          newEDSOperation={newEDSOperation}
          setNewEDSOperation={setNewEDSOperation}
          createEDS={createEDS}
          newEDSName={newEDSName}
          setNewEDSName={setNewEDSName}
          newEDSRevision={newEDSRevision}
          setNewEDSRevision={setNewEDSRevision}
          sourceEDSName={sourceEDSName}
          sourceEDSRevision={sourceEDSRevision}
        />
      )}
      {sheetType === "vds-textblocks" && (
        <>
          <NewVDSTextBlock
            showCreateNew={showCreateNew}
            setShowCreateNew={setShowCreateNew}
            groupFilter={groupFilter}
          />
          <EditVDSTextBlock
            isCopy={true}
            name={selectedSheet.name}
            revision={selectedSheet.revision}
            showCreateNew={showCopy}
            setShowCreateNew={setShowCopy}
          />
        </>
      )}
      {sheetType === "vds-subsegments" && (
        <>
          <NewVDSSubsegment
            showCreateNew={showCreateNew}
            setShowCreateNew={setShowCreateNew}
            groupFilter={groupFilter}
          />
          <EditVDSSubsegment
            isCopy={true}
            name={selectedSheet.name}
            revision={selectedSheet.revision}
            showCreateNew={showCopy}
            setShowCreateNew={setShowCopy}
          />
        </>
      )}
      {sheetType === "vds" && (
        <EditVDS
          name={userInfo.UserID}
          revision="0"
          setShowCreateNew={setShowCreateNew}
          showCreateNew={showCreateNew}
          createNew={createNew}
          setVDSCreateStatusCopy={setVDSCreateStatusCopy}
        />
      )}
      {attemptSetVDSSubsegmentStatusExpiredContent}
    </AbsoluteFlexContainer>
  );
}
