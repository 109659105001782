import { QueryStatus } from "react-query";
import { ColumnsProps, ItemsClassNames } from "components/table/Table";
import useElementTable, {
  DisplayAsFormElement,
  ElementItemData,
  ElementListGrouped,
  getGroupName,
  GroupsData,
  IdProp,
  useGroupSelector,
  usePositionSelector,
} from "../../hooks/useElementTable";
import {
  fromSizeColumn,
  noteColumnVDS,
  pageBreakColumn,
  remarkColumn,
  revmarkColumn,
  toSizeColumn,
} from "../CommonColumns";
import { useForm, useFormContext } from "react-hook-form";
import {
  ModalButtonContainer,
  ModalContainerAlways,
  ModalContainerScrollable,
} from "../../../../components/Modal";
import { Button, Menu, Icon } from "@equinor/eds-core-react";
import { external_link, visibility } from "@equinor/eds-icons";
import { useState } from "react";
import useVDSSelector from "../../hooks/useVDSSelector";
import {
  Fieldset,
  FieldsetContainer,
  FormRow,
} from "../../../../components/form/Form";
import { useMemoOne as useMemo } from "use-memo-one";
import {
  FlexElement,
  FlexFormContainer,
  StatusChip,
} from "components/Components";
import usePipeDimensions from "../../hooks/usePipeDimensions";
import {
  Pagebreak,
  PagebreakFormElement,
  TextInputRow,
} from "../../../../components/form/EditComponents";
import { setPaneMode, setPaneSheet } from "uiSlice";
import { useAppDispatch } from "app/hooks";
import { ModalSideMargin, ModalWindow } from "components/ModalWindow";
import { previewInNewWindow } from "../../commands";
import { useUserConfig } from "UserConfigContext";
import { vdsWidth } from "features/sheets/config/sheetConfig";

export interface ValveElementsAPIData {
  ValveGroupNo: number;
  LineNo: number;
  ValveType: string;
  VDS: string;
  ValveDescription: string;
  FromSize: string;
  ToSize: string;
  Revmark: string;
  Remark: string;
  PageBreak: Pagebreak;
  NoteID: number;
  PreviousVDS: null | string;
  NewDeletedLine: "N" | "D";
  InitialInfo: string;
  InitialRevmark: string;
  Revision: string;
}

export interface ValveElementGroupsData {
  ValveGroupID: number;
  ValveGroup: string;
}

interface ValveElementsFormData {
  ValveGroupID: number;
  ValveGroupText: string;
  LineNo: number;
  FromSize: string;
  ToSize: string;
  VDS: string;
  PreviousVDS: string;
  NoteID: number;
  Remark: string;
  NewDeletedLine: "N" | "D";
  Pagebreak: Pagebreak;
}

interface ValveElementsPreparedData extends ValveElementsFormData {
  LineNo: number;
  ValveGroupText: string;
}

export function prepareValveElementsData(data: {
  valveelements: { [index: IdProp]: ValveElementsFormData };
  listIds: string;
}): { valveelements: ValveElementsPreparedData[] } | { BackEndAction: string } {
  let prevGroup = 0;
  let lineNo = 10;
  const preparedData = data.valveelements
    ? data.listIds
        .split(",")
        .map((id) => data.valveelements[id])
        .map((e) => {
          if (prevGroup !== e.ValveGroupID) {
            lineNo = 10;
          }
          const prepared = {
            ...e,
            LineNo: lineNo,
            ValveGroupText: lineNo === 10 ? e.ValveGroupText : "",
          };
          prevGroup = e.ValveGroupID;
          lineNo++;
          return prepared;
        })
    : [];
  return preparedData.length
    ? {
        valveelements: preparedData,
      }
    : { BackEndAction: "DeleteWholeSet" };
}

function EditModal({
  setEditModal,
  editModal,
  groupsData,
  rows,
  rowsDispatch,
  group,
  position,
  form,
  dataWithIds,
  setItemsClassNames,
  addedLines,
  setAddedLines,
  revision,
}: {
  setEditModal: Function;
  editModal: string;
  groupsData: GroupsData;
  rows: ElementListGrouped;
  rowsDispatch: Function;
  group?: number;
  position?: number;
  form: { valveelements: { [index: string]: ValveElementsFormData } };
  dataWithIds: ElementItemData[];
  setItemsClassNames: React.Dispatch<ItemsClassNames[]>;
  addedLines: ElementItemData[];
  setAddedLines: React.Dispatch<any[]>;
  revision: string;
}) {
  const editMode = editModal !== "_new";
  const item = useMemo(
    () => (editMode ? form.valveelements[editModal] : undefined),
    [form, editModal, editMode]
  );
  const title = editMode
    ? `Edit ${item!.ValveGroupText} element`
    : "Add new Valve Element";
  const submitProp = "valveelements";
  const defaultSelection = useMemo(
    () => (item && item.VDS ? [item.VDS] : undefined),
    [item]
  );

  const { getValues, setValue } = useFormContext();

  const setFormValue = (name: string, value: string) =>
    setValue(`${submitProp}.${editModal}.${name}`, value, {
      shouldDirty: true,
    });
  const [previousVds, setPreviousVds] = useState(
    getValues(`${submitProp}.${editModal}.PreviousVDS`)
      ? getValues(`${submitProp}.${editModal}.PreviousVDS`)
      : ""
  );

  const { GroupSelector, selectedGroup } = useGroupSelector({
    groupsData,
    defaultGroup: group ? group : 50,
  });

  const { selectedLines, vdsSelectorContent, length, isLoading } =
    useVDSSelector({
      defaultSelection,
      valveGroup: editMode ? item!.ValveGroupID : selectedGroup,
      extendedDisplay: true,
    });

  const { PositionSelector, selectedPosition } = usePositionSelector({
    rows,
    group: selectedGroup,
    defaultPosition: position,
  });

  const { register, formState } = useForm({
    shouldUnregister: true,
    mode: "all",
  });

  const { fromSize, toSize, pipeDimensionsContent } = usePipeDimensions({
    register,
    formState,
    defaultFromSize: item ? item.FromSize : "",
    defaultToSize: item ? item.ToSize : "",
  });

  const [pagebreak, setPagebreak] = useState<Pagebreak>(
    editMode ? (item && item.Pagebreak ? item.Pagebreak : "N") : "N"
  );

  return (
    <ModalWindow
      isOpen={true}
      closeModal={() => setEditModal("")}
      title={title}
      layer="secondary"
    >
      <ModalSideMargin>
        <ModalContainerAlways>
          {!editMode && (
            <FormRow>
              <FlexFormContainer>
                <FlexElement>{GroupSelector}</FlexElement>
                <FlexElement>{PositionSelector}</FlexElement>
              </FlexFormContainer>
            </FormRow>
          )}
        </ModalContainerAlways>
        <ModalContainerScrollable>
          <FieldsetContainer slim>
            <Fieldset>
              <FormRow>{vdsSelectorContent}</FormRow>
              <TextInputRow
                label="Previous VDS"
                value={previousVds}
                setValue={setPreviousVds}
              />
              <FormRow>{pipeDimensionsContent}</FormRow>
              <PagebreakFormElement
                pagebreak={pagebreak}
                setPagebreak={setPagebreak}
              />
            </Fieldset>
          </FieldsetContainer>
        </ModalContainerScrollable>
        <ModalContainerAlways>
          <ModalButtonContainer>
            <Button
              disabled={
                isLoading ||
                Object.keys(formState.errors).length > 0 ||
                (length !== 0 && selectedLines.length === 0)
              }
              onClick={() => {
                if (editMode) {
                  setItemsClassNames([
                    {
                      itemID: editModal,
                      element: "_line",
                      className: "_modifiedData",
                    },
                  ]);
                  setFormValue("PreviousVDS", previousVds);
                  setFormValue(
                    "VDS",
                    selectedLines.length > 0 ? selectedLines[0]["VDS"] : ""
                  );
                  setFormValue(
                    "Remark",
                    selectedLines.length > 0
                      ? selectedLines[0]["Description"]
                      : ""
                  );
                  setFormValue(
                    "SizeRange",
                    selectedLines.length > 0
                      ? selectedLines[0]["SizeRange"]
                      : ""
                  );
                  setFormValue("FromSize", fromSize);
                  setFormValue("ToSize", toSize);
                  setFormValue("Pagebreak", pagebreak);
                  setTimeout(() => {
                    setItemsClassNames([]);
                  }, 3000);
                }

                if (!editMode) {
                  const lastId = [
                    ...dataWithIds.map((e) => e.id),
                    ...addedLines.map((e) => e.id),
                  ].at(-1);
                  const lastIdNum = lastId ? Number(lastId.substring(1)) : 0;
                  const newId = "e" + (lastIdNum + 1);
                  setAddedLines([
                    ...addedLines,
                    {
                      VDS:
                        selectedLines.length > 0 ? selectedLines[0]["VDS"] : "",
                      PreviousVDS: previousVds,
                      Remark:
                        selectedLines.length > 0
                          ? selectedLines[0]["Description"]
                          : "",
                      FromSize: fromSize,
                      ToSize: toSize,
                      ValveGroupNo: selectedGroup,
                      ElementGroupTitle: groupsData.find(
                        (e) => e.id === selectedGroup
                      )!["name"],
                      id: newId,
                      Revmark: revision,
                      Status:
                        selectedLines.length > 0
                          ? selectedLines[0]["Status"]
                          : "",
                      SizeRange:
                        selectedLines.length > 0
                          ? selectedLines[0]["SizeRange"]
                          : "",
                      Revision:
                        selectedLines.length > 0
                          ? selectedLines[0]["Revision"]
                          : "",
                      Pagebreak: pagebreak,
                      NewDeletedLine: "N",
                    },
                  ]);
                  rowsDispatch({
                    type: "add",
                    payload: {
                      toAdd: {
                        group: selectedGroup,
                        position: selectedPosition,
                        id: newId,
                      },
                    },
                  });
                  setItemsClassNames([
                    {
                      itemID: newId,
                      element: "_line",
                      className: "_newData",
                    },
                  ]);
                  setTimeout(() => {
                    setItemsClassNames([]);
                  }, 3000);
                }

                setEditModal("");
              }}
            >
              {editMode ? "Set" : "Add"}
            </Button>
            <Button
              variant="outlined"
              onClick={() => {
                setEditModal("");
              }}
            >
              Cancel
            </Button>
          </ModalButtonContainer>
        </ModalContainerAlways>
      </ModalSideMargin>
    </ModalWindow>
  );
}

function ValveElementRowMenu({ item }: { item: ElementItemData }) {
  const dispatch = useAppDispatch();
  const { previewBaseUrl } = useUserConfig();

  return (
    <Menu.Section>
      <Menu.Item
        disabled={!item.VDS || !item.Revision}
        onClick={() => {
          dispatch(
            setPaneSheet({
              location: "editModal",
              sheet: {
                sheetType: "vds",
                name: item.VDS,
                revision: item.Revision,
              },
            })
          );
          dispatch(setPaneMode({ location: "editModal", mode: "preview" }));
        }}
      >
        <Icon size={16} data={visibility} />
        View VDS in pane
      </Menu.Item>
      <Menu.Item
        disabled={!item.VDS || !item.Revision}
        onClick={() =>
          previewInNewWindow({
            item: { VDS: item.VDS, Revision: item.Revision },
            sheetType: "vds",
            previewBaseUrl,
          })
        }
      >
        <Icon size={16} data={external_link} />
        View VDS in new window
      </Menu.Item>
    </Menu.Section>
  );
}

function ValveElementHiddenInputs({
  item,
  elementGroupsData,
}: {
  item: any;
  elementGroupsData: any[];
}) {
  const { register } = useFormContext();
  const registerName = `valveelements.${item.id}.`;
  return (
    <>
      <input
        type="hidden"
        value={item.ValveGroupNo}
        {...register(`${registerName}ValveGroupID`)}
      />
      <input
        type="hidden"
        value={getGroupName({
          elementName: "valve-elements",
          elementGroupsData,
          groupId: item.ValveGroupNo,
        })}
        {...register(`${registerName}ValveGroupText`)}
      />
      <input
        type="hidden"
        value={item.NewDeletedLine}
        {...register(`${registerName}NewDeletedLine`)}
      />
      <input
        type="hidden"
        value={item.InitialInfo}
        {...register(`${registerName}InitialInfo`)}
      />
      <input
        type="hidden"
        value={item.InitialRevmark}
        {...register(`${registerName}InitialRevmark`)}
      />
    </>
  );
}

const valveElementColumns = [
  revmarkColumn,
  pageBreakColumn,
  fromSizeColumn,
  toSizeColumn,
  {
    key: "VDS",
    title: "VDS",
    Component: DisplayAsFormElement,
    type: "with-context",
    componentProps: {
      prop: "VDS",
    },
    width: vdsWidth,
  },
  {
    key: "Status",
    title: "Sta.",
    longTitle: "Status",
    Component: StatusChip,
    width: 40,
  },
  {
    key: "PreviousVDS",
    title: "Previous VDS",
    Component: DisplayAsFormElement,
    type: "with-context",
    componentProps: {
      prop: "PreviousVDS",
    },
    width: vdsWidth,
  },
  {
    key: "SizeRange",
    title: "Size Range",
    Component: DisplayAsFormElement,
    type: "with-context",
    componentProps: {
      prop: "SizeRange",
    },
  },
  remarkColumn,
  noteColumnVDS,
] as ColumnsProps[];

export function PCSValveElements({
  setIsLoaded,
  name,
  revision,
  saveSheetStatus,
}: {
  setIsLoaded: Function;
  name: string;
  revision: string;
  saveSheetStatus: QueryStatus;
}) {
  const { elementTableContent } = useElementTable({
    elementName: "valve-elements",
    setIsLoaded,
    saveSheetStatus,
    name,
    revision,
    columns: valveElementColumns,
    title: "Valve Elements",
    ElementHiddenInputs: ValveElementHiddenInputs,
    AdditionalRowMenu: ValveElementRowMenu,
    EditModal: EditModal,
  });

  return <>{elementTableContent}</>;
}
