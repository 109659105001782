import { Icon, Menu } from "@equinor/eds-core-react";
import {
  arrow_drop_down,
  arrow_drop_up,
  assignment,
  code,
  delete_forever,
  download,
  external_link,
  format_indent_increase,
  more_horizontal,
  reply,
  visibility,
} from "@equinor/eds-icons";
import { ContextMenu } from "components/ContextMenu";
import { getApiUrl } from "queries/queryUtil";
import {
  DocumentSpaceContextData,
  documentTypesWithFile,
  previewableExtensions,
} from "./DocumentSpace";
import { DocumentListItem } from "./queries/useDocumentList";
import { useFileUrl } from "./useFileUrl";
import { MoveToPositionModal, useSort } from "./useSort";

export const FileRowMenu = ({
  item,
  deleteItem,
  setPaneDocument,
  setPaneCacheBust,
  setEditItem,
  setEditOpen,
  mutateObjectSort,
  mutateObjectSortStatus,
  setRelocateItems,
  setRelocateOpen,
  filesData,
  oid,
}: {
  item: DocumentListItem;
} & DocumentSpaceContextData) => {
  const { moveUp, moveDown, moveToPositionProps, setShowMoveToPosition } =
    useSort({
      neighbors: filesData ?? [],
      item,
      oid: oid ?? 0,
      mutateObjectSort,
      mutateObjectSortStatus,
    });

  const { getFileUrl } = useFileUrl();

  return (
    <>
      <ContextMenu buttonContent={<Icon data={more_horizontal} />}>
        <Menu.Section>
          <Menu.Item
            onClick={(e) => {
              e.stopPropagation();
              setEditItem(item.OID);
              setEditOpen(true);
            }}
          >
            <Icon data={assignment} size={16} />
            {item.DocumentType === "C" ? "Folder content text " : "Document "}
            properties
          </Menu.Item>
        </Menu.Section>

        {documentTypesWithFile.includes(item.DocumentType) &&
          (previewableExtensions.includes(item.DocumentFormat) ? (
            <Menu.Section>
              <Menu.Item
                onClick={() => {
                  setPaneCacheBust(new Date().toString());
                  setPaneDocument(item);
                }}
              >
                <Icon data={visibility} size={16} /> View in pane
              </Menu.Item>

              <Menu.Item
                onClick={() => {
                  window.open(getFileUrl(item), "_blank");
                }}
              >
                <Icon data={external_link} size={16} /> View in new window
              </Menu.Item>
            </Menu.Section>
          ) : (
            <Menu.Section>
              <Menu.Item
                onClick={() => {
                  window.open(getFileUrl(item), "_blank");
                }}
              >
                <Icon data={download} size={16} /> Download / Open
              </Menu.Item>
            </Menu.Section>
          ))}
        {item.DocumentType === "U" && (
          <Menu.Section>
            <Menu.Item onClick={() => window.open(item.URLAddress, "_blank")}>
              <Icon data={external_link} size={16} /> Open URL in new window
            </Menu.Item>
          </Menu.Section>
        )}

        <Menu.Section>
          <Menu.Item
            onClick={() => {
              setRelocateItems([{ oid: item.OID }]);
              setRelocateOpen(true);
            }}
            disabled={item.DocumentType === "C"}
          >
            <Icon
              data={reply}
              style={{ transform: "rotate(180deg)" }}
              size={16}
            />{" "}
            Relocate to another folder
          </Menu.Item>
          <Menu.Item onClick={() => deleteItem(item)}>
            <Icon data={delete_forever} size={16} /> Delete
          </Menu.Item>
        </Menu.Section>
        <Menu.Section>
          <Menu.Item
            disabled={!moveUp}
            onClick={() => {
              moveUp && moveUp();
            }}
          >
            <Icon data={arrow_drop_up} size={16} /> Move up
          </Menu.Item>
          <Menu.Item
            disabled={!moveDown}
            onClick={() => {
              moveDown && moveDown();
            }}
          >
            <Icon data={arrow_drop_down} size={16} /> Move down
          </Menu.Item>
          <Menu.Item
            onClick={() => {
              setShowMoveToPosition(true);
            }}
          >
            <Icon data={format_indent_increase} size={16} /> Move to position
          </Menu.Item>
        </Menu.Section>
        <Menu.Section>
          <Menu.Item
            onClick={(e) => {
              e.stopPropagation();
              window.open(
                getApiUrl(`document-space/${item.OID}/properties`),
                "_blank"
              );
            }}
          >
            <Icon data={code} size={16} /> View JSON
          </Menu.Item>
        </Menu.Section>
      </ContextMenu>
      <MoveToPositionModal {...moveToPositionProps} />
    </>
  );
};
