import {
  Button,
  CircularProgress,
  EdsProvider,
  Icon,
  Switch,
  Typography,
} from "@equinor/eds-core-react";
import {
  border_color,
  check_circle_outlined,
  close,
  close_circle_outlined,
  crop,
  delete_forever,
  delete_to_trash,
  external_link,
  file_description,
  refresh,
  visibility,
} from "@equinor/eds-icons";
import { useUserConfig } from "UserConfigContext";
import { FlexContainer, RevisionMark } from "components/Components";
import { ConfirmListContainer } from "components/Modal";
import {
  ModalWindow,
  ModalWindowButtonContainer,
  ModalWindowDefaultContainer,
} from "components/ModalWindow";
import StatusModal from "components/StatusModal";
import useConfirm from "components/confirm/useConfirm";
import Table, {
  ColumnsProps,
  SmallTableContainer,
} from "components/table/Table";
import { useTableSelect } from "components/table/useTableSelect";
import {
  StructuredPipeElement,
  StructuredPipeElementArticles,
  StructuredPipeElementConditionIndicator,
  StructuredPipeElementMatrices,
  useStructuredPipeElementsQuery,
} from "features/pmg/queries/useStructuredPipeElements";
import React, { useMemo, useState } from "react";
import { successMessage } from "utils/successMessage";
import { useCreateVerifyStructuredPipeElements } from "../queries/useCreateVerifyStructuredPipeElements";
import {
  UseDeleteStructuredPipeElement,
  useDeleteStructuredPipeElement,
} from "../queries/useDeleteStructuredPipeElement";
import {
  ButtonContainer,
  FlatMasterPipeElements,
  HeaderContainer,
  IconCenter,
} from "./EditPipeMaterialGroup";
import { EditSPEDependentInformationModal } from "./EditSPEDependentInformation";
import * as Space from "react-spaces";

const dimensionsColumns: ColumnsProps<StructuredPipeElementArticles>[] = [
  { key: "ArticleNo", title: "Article No." },
  { key: "Type", title: "Type" },
  { key: "Unit", title: "Unit" },
  { key: "Size1", title: "Size 1" },
  { key: "Size2", title: "Size 2" },
  { key: "Other", title: "Other" },
  { key: "Height", title: "Height" },
  { key: "Length", title: "Length" },
  { key: "Width", title: "Width" },
  { key: "WallThickness1", title: "Wth 1", longTitle: "Wall Thickness 1" },
  { key: "WallThickness2", title: "Wth 2", longTitle: "Wall Thickness 2" },
  { key: "Status", title: "Status" },
];

function Dimensions({
  structuredPipeElement,
  setEditDependent,
}: {
  structuredPipeElement: StructuredPipeElement | undefined;
  setEditDependent: React.Dispatch<number | false>;
}) {
  const { configParams } = useUserConfig();
  const externalBaseUrl = configParams.find((e) => e.ID === 1)?.URL ?? "";
  const [viewArticle, setViewArticle] = useState("");
  return structuredPipeElement ? (
    <ModalWindow
      isOpen={true}
      closeModal={() => {
        setEditDependent(false);
      }}
      title={`Dimensions for Structured Pipe Element ${structuredPipeElement.ElementID} rev. ${structuredPipeElement.Revision}`}
    >
      <ModalWindowDefaultContainer
        style={{
          width: 1280,
          height: "70vh",
          borderBottom: "1px solid var(--borderDefault)",
          position: "relative",
        }}
      >
        <Space.Fill>
          <Space.Fill
            style={{ borderBottom: "1px solid var(--borderDefault)" }}
          >
            <SmallTableContainer
              style={{
                minHeight: 100,
              }}
            >
              <Table
                columns={dimensionsColumns as ColumnsProps[]}
                items={structuredPipeElement.StructuredPipeElementArticles}
                itemIdProp="ArticleNo"
                density="compact"
                RowMenu={({
                  item,
                }: {
                  item: StructuredPipeElementArticles;
                }) => (
                  <>
                    <Button
                      variant="ghost_icon"
                      onClick={() => {
                        setViewArticle(item.ArticleNo);
                      }}
                      title="View Article in Pane"
                    >
                      <Icon data={visibility} />
                    </Button>

                    <a
                      href={
                        externalBaseUrl +
                        `/integration/get_articleinfo.jsp?L_NUMBER=${item.ArticleNo}`
                      }
                      target="_blank"
                      rel="noreferrer"
                      title="Open Article in new window"
                    >
                      <Button variant="ghost_icon">
                        <Icon data={external_link} />
                      </Button>
                    </a>
                  </>
                )}
              />
            </SmallTableContainer>
          </Space.Fill>
          {viewArticle && (
            <Space.BottomResizable size={350}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                }}
              >
                <div style={{ padding: "10px 5px 2px 20px" }}>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div>
                      <Typography
                        variant="h5"
                        style={{ fontWeight: "normal", paddingTop: 6 }}
                      >
                        Article No. {viewArticle}
                      </Typography>
                    </div>
                    <div>
                      <Button
                        variant="ghost_icon"
                        onClick={() => {
                          setViewArticle("");
                        }}
                        title="Close Article Pane"
                      >
                        <Icon data={close} size={16} />{" "}
                      </Button>
                    </div>
                  </div>
                </div>

                <div
                  style={{
                    flex: 1,
                  }}
                >
                  <iframe
                    style={{
                      width: "100%",
                      height: "100%",
                      border: "none",
                    }}
                    src={
                      externalBaseUrl +
                      `integration/get_articleinfo.jsp?L_NUMBER=${viewArticle}`
                    }
                    title="Article Pane"
                  ></iframe>
                </div>
              </div>
            </Space.BottomResizable>
          )}
        </Space.Fill>
      </ModalWindowDefaultContainer>
      <ModalWindowButtonContainer>
        <Button variant="outlined" onClick={() => setEditDependent(false)}>
          Close
        </Button>
      </ModalWindowButtonContainer>
    </ModalWindow>
  ) : (
    <></>
  );
}

function SPERowMenu({
  item,
  deleteSPE,
  setEditDependent,
  isEdit,
  isManualDimension,
  mpeId,
}: {
  item: StructuredPipeElement;
  deleteSPE: UseDeleteStructuredPipeElement["mutate"];
  setEditDependent: React.Dispatch<number | false>;
  isEdit: boolean;
  isManualDimension: boolean;
  mpeId: number;
}) {
  const { isConfirmed } = useConfirm();
  return (
    <>
      {isManualDimension ? (
        <Button
          variant="ghost_icon"
          onClick={() => {
            setEditDependent(item.ElementID);
          }}
          title={
            isManualDimension
              ? "Dimensions"
              : isEdit
              ? "Edit Dependent Information"
              : "Dependent Information"
          }
        >
          <Icon data={crop} />
        </Button>
      ) : (
        <Button
          variant="ghost_icon"
          onClick={() => {
            setEditDependent(item.ElementID);
          }}
          title={isEdit ? "Edit Dimensions" : "Dimensions"}
        >
          <Icon data={isEdit ? border_color : file_description} />
        </Button>
      )}
      {isEdit && (
        <Button
          variant="ghost_icon"
          onClick={async () => {
            (await isConfirmed(
              `Are you sure you want to delete Structured Pipe Element ${item.ElementID} rev. ${item.Revision}?`
            )) &&
              deleteSPE({
                pipeElements: [
                  {
                    elementId: item.ElementID,
                    revision: item.Revision,
                    masterPipeElementId: mpeId,
                  },
                ],
              });
          }}
          title="Delete Structured Pipe Element"
        >
          <Icon data={delete_forever} />
        </Button>
      )}
    </>
  );
}

function ConditionComponent({
  children,
}: {
  children: StructuredPipeElementConditionIndicator;
}) {
  return (
    <IconCenter>
      {children === "O" ? (
        <Icon
          data={check_circle_outlined}
          title="OK, will be included."
          color="var(--okColor)"
        />
      ) : children === "D" ? (
        <Icon
          data={delete_to_trash}
          title="Should be deleted."
          color="var(--dangerColor)"
        />
      ) : children === "N" ? (
        <Icon
          data={close_circle_outlined}
          title="Will not be included. Check MDS status."
          color="var(--dangerColor)"
        />
      ) : (
        children
      )}
    </IconCenter>
  );
}

function ComponentTypeIcon({ children }: { children: string }) {
  return <div title={children}>{children[0]}</div>;
}

function SubElementIcon({ children }: { children: string }) {
  return <div title={children}>{children[0]}</div>;
}

function MatrixIcon({
  children,
}: {
  children: StructuredPipeElementMatrices[];
}) {
  return (
    <>
      {children.filter((e) => e.Selected === "Y").length} / {children.length}
    </>
  );
}

const structuredPipeElementColumnsManualDimension: ColumnsProps<StructuredPipeElement>[] =
  [
    {
      key: "ConditionIndicator",
      title: "Co.",
      longTitle: "Condition",
      Component: ConditionComponent,
    },
    { key: "ElementID", title: "ID", width: 45 },
    { key: "Revision", title: "Rev.", longTitle: "Revision", width: 25 },
    {
      key: "DimStandard",
      title: "Dim. Standard",
      longTitle: "Dimensions Standard",
      width: 60,
    },
    { key: "ProductForm", title: "Product Form", width: 55 },
    { key: "MaterialGrade", title: "Material Grade", width: 50 },
    { key: "MDS", title: "MDS", width: 105 },
    {
      key: "MDSRevision",
      title: "MDS Rev.",
      longTitle: "MDS Revision",
      Component: RevisionMark,
    },
    { key: "Area", title: "Area", width: 75 },
    {
      key: "SpecialRequirement",
      title: "Special Req.",
      longTitle: "Special Requirement",
      width: 65,
    },
    { key: "EndConnection1", title: "EC1", longTitle: "End Connection 1" },
    { key: "EndConnection2", title: "EC2", longTitle: "End Connection 2" },
    {
      key: "NonStandardUnderTolerance",
      title: "N-St. UT",
      longTitle: "Non-Standard Under Tolerance",
    },
    { key: "Priority", title: "Pri.", longTitle: "Priority" },
    { key: "RevDate", title: "Rev. Date", longTitle: "Revision Date" },
    { key: "Status", title: "Status" },
    { key: "LastUpdateBy", title: "Last U. By", longTitle: "Last Update By" },
  ];

const structuredPipeElementColumns: ColumnsProps<StructuredPipeElement>[] = [
  ...structuredPipeElementColumnsManualDimension,
  {
    key: "SubElementText",
    title: "SE",
    longTitle: "Subelement Connection Point",
    Component: SubElementIcon,
  },
  {
    key: "ComponentTypeText",
    title: "CT",
    longTitle: "Component Type",
    Component: ComponentTypeIcon,
  },
  {
    key: "StructuredPipeElementMatrices",
    title: "M",
    longTitle: "Master Matrices (selected / all)",
    width: 50,
    Component: MatrixIcon,
  },
];

export function useStructuredPipeElements({
  name,
  revision,
  mpeId,
  masterPipeElementSelectedLine,
  isEdit,
}: {
  name: string;
  revision: string;
  mpeId: number | undefined;
  masterPipeElementSelectedLine: FlatMasterPipeElements[number] | undefined;
  isEdit: boolean;
}) {
  const { data, status, error, isRefetching, refetch } =
    useStructuredPipeElementsQuery({
      name,
      revision,
      mpeId,
    });

  const isManualDimension =
    masterPipeElementSelectedLine?.AddDimensionManually === "Y";

  const [editDependent, setEditDependent] = useState<number | false>(false);
  const [editMulti, setEditMulti] = useState<boolean>(false);

  const structuredPipeElementsEdit = useMemo(
    () => data?.find((e) => e.ElementID === editDependent),
    [data, editDependent]
  );

  const {
    mutate,
    status: mutateStatus,
    error: mutateError,
    reset: mutateReset,
    data: mutateData,
  } = useCreateVerifyStructuredPipeElements();

  const {
    mutate: deleteSPE,
    status: deleteSPEStatus,
    error: deleteSPEError,
    reset: deleteSPEReset,
    data: deleteSPEData,
  } = useDeleteStructuredPipeElement({
    materialGroupId: name,
    materialGroupRevision: revision,
  });

  const { selection, selectionDispatch, selectionMode } = useTableSelect({
    selectionMode: "multi",
  });

  const selectedLines = useMemo(
    () => data?.filter((e) => selection.includes(String(e.ElementID))),
    [data, selection]
  );

  const [doNotUseWildcardLogic, setDoNotUseWildcardLogic] = useState(false);

  const { isConfirmed } = useConfirm();

  const structuredPipeElementsContent = (
    <>
      <Table
        columns={
          (isManualDimension
            ? structuredPipeElementColumnsManualDimension
            : structuredPipeElementColumns) as ColumnsProps[]
        }
        items={data}
        itemIdProp="ElementID"
        density="compact"
        status={status}
        error={error}
        message={
          mpeId && status !== "idle"
            ? undefined
            : "Select a Master Pipe Element"
        }
        nonVirtual
        sortable
        selectionMode={isEdit ? selectionMode : false}
        selectionDispatch={selectionDispatch}
        selection={selection}
        loadingString="Loading Structured Pipe Elements..."
        controlHeader={
          <HeaderContainer>
            <FlexContainer>
              <FlexContainer gap flexStart>
                <Typography variant="h5">
                  Structured Pipe Elements connected to Master Pipe Element
                </Typography>
                {isEdit && (
                  <>
                    <EdsProvider density="comfortable">
                      <ButtonContainer>
                        <FlexContainer gap>
                          <Button
                            variant="outlined"
                            disabled={!mpeId}
                            onClick={() => {
                              if (mpeId) {
                                mutate({
                                  materialGroupId: name,
                                  materialGroupRevision: revision,
                                  masterPipeElementId: mpeId,
                                  doNotUseWildcardLogic,
                                });
                              }
                            }}
                          >
                            Create / Verify
                          </Button>
                          <Switch
                            label="Do not use wildcard logic"
                            checked={doNotUseWildcardLogic}
                            onChange={() => {
                              setDoNotUseWildcardLogic(!doNotUseWildcardLogic);
                            }}
                          />
                          <Button
                            variant="outlined"
                            disabled={selection.length === 0}
                            onClick={() => {
                              setEditMulti(true);
                            }}
                          >
                            Edit Dependent Information of Selected
                          </Button>
                          <Button
                            variant="outlined"
                            color="danger"
                            disabled={selection.length === 0}
                            onClick={async () => {
                              if (selectedLines && mpeId) {
                                (await isConfirmed(
                                  <>
                                    Are you sure you want to delete the
                                    following Structured Pipe Element
                                    {selectedLines.length > 1 ? "s" : ""}?
                                    <ConfirmListContainer>
                                      {selectedLines.map((i) => (
                                        <li key={i.ElementID}>
                                          {i.ElementID} rev. {i.Revision}
                                        </li>
                                      ))}
                                    </ConfirmListContainer>
                                  </>
                                )) &&
                                  deleteSPE({
                                    pipeElements: selectedLines.map((e) => ({
                                      elementId: e.ElementID,
                                      revision: e.Revision,
                                      masterPipeElementId: mpeId,
                                    })),
                                  });
                              }
                            }}
                          >
                            Delete selected
                          </Button>
                        </FlexContainer>
                      </ButtonContainer>
                    </EdsProvider>
                  </>
                )}
              </FlexContainer>
              <div>
                <FlexContainer gap>
                  <div>
                    <CircularProgress
                      size={16}
                      style={{
                        visibility: isRefetching ? "visible" : "hidden",
                      }}
                    />
                  </div>
                  <div>
                    <ButtonContainer>
                      <Button
                        onClick={() => refetch()}
                        variant="ghost_icon"
                        title="Reload Structured Pipe Elements"
                      >
                        <Icon data={refresh} />
                      </Button>
                    </ButtonContainer>
                  </div>
                </FlexContainer>
              </div>
            </FlexContainer>
          </HeaderContainer>
        }
        fullRowSelect
        totalCount={data?.length}
        RowMenu={SPERowMenu}
        contextData={{
          deleteSPE,
          setEditDependent,
          isEdit,
          isManualDimension,
          mpeId,
        }}
      />
      <StatusModal
        status={mutateStatus}
        error={mutateError}
        successMessage={successMessage(mutateData)}
        onSettledClose={() => {
          mutateReset();
        }}
      />
      <StatusModal
        status={deleteSPEStatus}
        error={deleteSPEError}
        successMessage={successMessage(deleteSPEData)}
        onSettledClose={() => {
          deleteSPEReset();
        }}
      />
      {isManualDimension && editDependent ? (
        <Dimensions
          structuredPipeElement={structuredPipeElementsEdit}
          setEditDependent={setEditDependent}
        />
      ) : (
        (editDependent || editMulti) && (
          <EditSPEDependentInformationModal
            structuredPipeElement={structuredPipeElementsEdit}
            materialGroupId={name}
            materialGroupRevision={revision}
            masterPipeElementId={mpeId}
            setEditDependent={setEditDependent}
            elementGroupId={masterPipeElementSelectedLine?.elementGroupId}
            isEdit={isEdit}
            editMulti={editMulti}
            setEditMulti={setEditMulti}
            selectedLines={selectedLines}
          />
        )
      )}
    </>
  );

  return {
    structuredPipeElementsContent,
  };
}
