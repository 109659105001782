import { Button, Icon, Menu, Search, Tooltip } from "@equinor/eds-core-react";
import {
  add,
  copy,
  delete_multiple,
  label,
  more_horizontal,
  refresh,
  skip_next,
} from "@equinor/eds-icons";
import { ExtendedDownloads } from "components/Downloads";
import { ConfirmListContainer } from "components/Modal";
import { NameFilterInfo } from "components/NameFilterInfo";
import { IsConfirmed } from "components/confirm/useConfirm";
import { HeaderProgress } from "components/table/HeaderProgress";
import { settingKey } from "features/settings/settingsKey";
import { useUpdateSetting } from "features/settings/useUpdateSetting";
import { NewSheetRevisions } from "features/sheets/queries/useNewSheetRevisions";
import { SetSheetStatusMultiple } from "features/sheets/queries/useSetSheetStatusMultiple";
import React, { useMemo, useRef, useState } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { QueryStatus } from "react-query";
import { NameProperties } from "utils/filterItemsByName";
import {
  Fade,
  FlexContainer,
  FlexElement,
  HeaderButtonContainer,
} from "../../components/Components";
import { ContextMenu } from "../../components/ContextMenu";
import Filters, { FilterProps } from "../../components/table/Filters";
import {
  ColumnsProps,
  ControlHeaderContainer,
  ControlHeaderTitle,
} from "../../components/table/Table";
import { PlantProps } from "../plant/types";
import { HashProps } from "./Sheets";
import { VDSTaskMonitorButton } from "./VDSTaskMonitor";
import { newRevisionsFromSelected } from "./commands";
import {
  SheetTypes,
  getElementNamePlural,
  plantDependentSheets,
  sheetProperties,
  sheetTypeNames,
} from "./config/sheetConfig";
import { NewEDSOperation } from "./modals/eds/CreateEDS";
import { showSheetCopyModal } from "./sheetSlice";
import { SheetCommands, VDSTextBlock } from "./types";
import { cannotBeRevised } from "./util";

export function SheetControlHeader({
  plant,
  mainPlant,
  isCopyModal,
  sheetType,
  selectedItems,
  deleteSelected,
  deleteSheets,
  hasName,
  sheetTypeName,
  nameProperty,
  filterNameProperty,
  nameFilter,
  setNameFilter,
  isConfirmed,
  newSheetRevisions,
  setMultipleSheetStatus,
  filters,
  mainItemsSheets,
  filteredItems,
  columns,
  refetch,
  isRefetching,
  dispatch,
  filterPlaceholder,
  containerSize,
  setCreateNew,
  setShowCreateNew,
  setNewEDSOperation,
  isPCSWorkbenchCommand,
  isVDSWorkbenchCommand,
  filterKey,
  filterKeySheetType,
  applyConfirmedFilters,
  restoreConfirmedFilters,
  combinedCodelistStatuses,
  isEdit,
  doNotOpenFilters,
  sheetCommand,
  filteringDisabled,
  serverSide,
  hashParts,
}: {
  plant: PlantProps;
  mainPlant: PlantProps;
  isCopyModal: boolean;
  sheetType: SheetTypes;
  selectedItems: any[];
  deleteSelected: Function;
  deleteSheets: Function;
  hasName: boolean;
  sheetTypeName: string;
  nameProperty: string;
  filterNameProperty: NameProperties;
  nameFilter: string;
  setNameFilter: React.Dispatch<string>;
  isConfirmed: IsConfirmed;
  newSheetRevisions: NewSheetRevisions["mutate"];
  setMultipleSheetStatus: SetSheetStatusMultiple["mutate"];
  filters: FilterProps[];
  mainItemsSheets: any[];
  filteredItems: any[];
  columns: ColumnsProps[];
  refetch: Function;
  isRefetching: boolean;
  dispatch: Function;
  filterPlaceholder: string;
  containerSize: any;
  setCreateNew: React.Dispatch<boolean>;
  setShowCreateNew: React.Dispatch<boolean>;
  setNewEDSOperation: React.Dispatch<NewEDSOperation>;
  isPCSWorkbenchCommand: boolean;
  isVDSWorkbenchCommand: boolean;
  filterKey: string;
  filterKeySheetType: string;
  applyConfirmedFilters: (() => void) | undefined;
  restoreConfirmedFilters: (() => void) | undefined;
  combinedCodelistStatuses: QueryStatus;
  isEdit: boolean;
  doNotOpenFilters: boolean;
  sheetCommand: SheetCommands | undefined;
  filteringDisabled: boolean;
  serverSide: boolean;
  hashParts: HashProps;
}) {
  const filterGroupProperties = useMemo(
    () => [
      { group: "Valve Characteristics", col: 1, width: 290 },
      { group: "Valve Types", col: 1 },
      { group: "Selections", col: 2 },
      { group: "Item", col: 3 },
      {
        group: "Statuses",
        col: 5,
        info:
          sheetCommand === "replace-subsegment" ? (
            <>
              <p>
                When filtering for both Official and Working statuses in this
                workbench, sheets in status Official are only shown when a
                status Working does not exists.
              </p>
            </>
          ) : undefined,
      },
      { group: "Priority", col: 6 },
      { group: "Users", col: 6 },
      { group: "Text Blocks", col: 7 },
      {
        group: "Subsegments",
        col: 7,
        info: (
          <>
            <p>
              Subsegments are grouped for filtering.
              <ul>
                <li>
                  If all selected subsegments belong to one group, VDSs
                  containing <em>any subsegments</em> are shown.
                </li>
                <li>
                  If the selected subsegments belong to different groups, VDSs
                  containing <em>at least one subsegment from every group</em>{" "}
                  are shown.
                </li>
              </ul>
            </p>
            <p>
              In logical terms: subsegments in a group are OR-ed, groups of
              subsegments are AND-ed.
            </p>
          </>
        ),
      },
    ],
    [sheetCommand]
  );

  const { apiNoMultiAction } = sheetProperties[sheetType];

  const elementName = getElementNamePlural(sheetType);

  const { updateSetting } = useUpdateSetting();

  const nameFilterKey = settingKey([filterKeySheetType, "nameFilter"]);

  const [submittedNameFilter, setSubmittedNameFilter] = useState(nameFilter);

  const nameFilterRef = useRef<HTMLInputElement | null>(null);

  useHotkeys("ctrl+space", (e) => {
    e.preventDefault();
    nameFilterRef.current?.focus();
  });

  return (
    <ControlHeaderContainer>
      <FlexContainer>
        <FlexElement
          style={{
            textAlign: "right",
            paddingRight: "8px",
          }}
        >
          {isCopyModal || apiNoMultiAction ? (
            <></>
          ) : (
            <ContextMenu
              buttonContent={<Icon data={more_horizontal}></Icon>}
              placement="bottom-start"
              disabled={selectedItems.length === 0}
            >
              <Menu.Item
                onClick={() =>
                  deleteSelected({
                    selectedItems,
                    hasName,
                    nameProperty,
                    mainPlant,
                    deleteSheets,
                    sheetType,
                    isConfirmed,
                  })
                }
                disabled={
                  selectedItems.length === 0 ||
                  JSON.stringify(
                    Array.from(new Set(selectedItems.map((v: any) => v.Status)))
                  ) !== JSON.stringify(["W"])
                }
              >
                <Icon size={16} data={delete_multiple} />
                Delete selected {elementName}
              </Menu.Item>
              {sheetType === "pcs" && !isPCSWorkbenchCommand ? (
                <Menu.Item
                  onClick={() =>
                    newRevisionsFromSelected({
                      selectedItems,
                      hasName,
                      nameProperty,
                      mainPlant,
                      newSheetRevisions,
                      sheetType,
                      isConfirmed,
                    })
                  }
                  disabled={
                    selectedItems.length === 0 ||
                    selectedItems
                      .map((item) =>
                        cannotBeRevised(item, mainItemsSheets, sheetType)
                      )
                      .reduce((acc, curr) => acc || curr)
                  }
                >
                  <Icon size={16} data={skip_next} />
                  New (sub) revisions from selected data sheets
                </Menu.Item>
              ) : (
                <></>
              )}
              {sheetType === "vds-textblocks" && (
                <Menu.Item
                  onClick={async () =>
                    (await isConfirmed(
                      <>
                        Are you sure you want to set the following data sheets
                        to Official (O)?{" "}
                        <ConfirmListContainer>
                          {selectedItems.map((e) => (
                            <li>
                              {e.TextBlockID} rev. {e.Revision}
                            </li>
                          ))}
                        </ConfirmListContainer>
                      </>
                    )) &&
                    setMultipleSheetStatus({
                      sheetType,
                      status: "O",
                      sheets: selectedItems.map((e: VDSTextBlock) => ({
                        name: String(e.TextBlockID),
                        revision: e.Revision,
                      })),
                    })
                  }
                  disabled={selectedItems.reduce(
                    (a, c) =>
                      a || (c.Status !== "W" && c.Status !== "E")
                        ? true
                        : false,
                    false
                  )}
                >
                  <Icon size={16} data={label} />
                  Set selected {elementName} Official (O)
                </Menu.Item>
              )}
              {sheetType === "vds" && (
                <Menu.Item
                  onClick={async () =>
                    (await isConfirmed(
                      <>
                        Are you sure you want to set the following data sheets
                        to Official (O)?
                        <ConfirmListContainer>
                          {selectedItems.map((e) => (
                            <li>
                              {e.VDS} rev. {e.Revision}
                            </li>
                          ))}
                        </ConfirmListContainer>
                      </>
                    )) &&
                    setMultipleSheetStatus({
                      sheetType,
                      status: "O",
                      async: true,
                      taskDescription: "Setting selected VDS Official (O)",
                      postfix: "status-official",
                      sheets: selectedItems.map((e) => ({
                        name: e.VDS,
                        revision: e.Revision,
                      })),
                    })
                  }
                  disabled={selectedItems.reduce(
                    (a, c) => (a || c.Status !== "W" ? true : false),
                    false
                  )}
                >
                  <Icon size={16} data={label} />
                  Set selected {elementName} Official (O)
                </Menu.Item>
              )}
              {sheetType === "vds-subsegments" && (
                <Menu.Item
                  onClick={async () =>
                    (await isConfirmed(
                      <>
                        Are you sure you want to set the following data sheets
                        to Official (O)?
                        <ConfirmListContainer>
                          {selectedItems.map((e) => (
                            <li>
                              {e.SubsegmentID} rev. {e.Revision}
                            </li>
                          ))}
                        </ConfirmListContainer>
                      </>
                    )) &&
                    setMultipleSheetStatus({
                      sheetType,
                      status: "O",
                      async: true,
                      taskDescription:
                        "Setting selected VDS Subsegments Official (O)",
                      sheets: selectedItems.map((e) => ({
                        name: e.SubsegmentID,
                        revision: e.Revision,
                      })),
                    })
                  }
                  disabled={selectedItems.reduce(
                    (a, c) => (a || c.Status !== "W" ? true : false),
                    false
                  )}
                >
                  <Icon size={16} data={label} />
                  Set selected {elementName} Official (O)
                </Menu.Item>
              )}
              {sheetType === "vds" && (
                <Menu.Item
                  onClick={async () =>
                    (await isConfirmed(
                      <>
                        Are you sure you want to set the following data sheets
                        to Expired (E)?
                        <ConfirmListContainer>
                          {selectedItems.map((e) => (
                            <li>
                              {e.VDS} rev. {e.Revision}
                            </li>
                          ))}
                        </ConfirmListContainer>
                      </>
                    )) &&
                    setMultipleSheetStatus({
                      sheetType,
                      status: "E",
                      async: true,
                      taskDescription: "Setting selected VDS Expired (E)",
                      postfix: "status-expired",
                      sheets: selectedItems.map((e) => ({
                        name: e.VDS,
                        revision: e.Revision,
                      })),
                    })
                  }
                  disabled={selectedItems.reduce(
                    (a, c) => (a || c.Status !== "O" ? true : false),
                    false
                  )}
                >
                  <Icon size={16} data={label} />
                  Set selected {elementName} Expired (E)
                </Menu.Item>
              )}
              {sheetType === "vds-textblocks" && (
                <Menu.Item
                  onClick={() =>
                    setMultipleSheetStatus({
                      sheetType,
                      status: "E",
                      sheets: selectedItems.map((e: VDSTextBlock) => ({
                        name: String(e.TextBlockID),
                        revision: e.Revision,
                      })),
                    })
                  }
                  disabled={selectedItems.reduce(
                    (a, c) => (a || c.Status !== "O" ? true : false),
                    false
                  )}
                >
                  <Icon size={16} data={label} />
                  Set selected {elementName} Expired (E)
                </Menu.Item>
              )}
            </ContextMenu>
          )}
        </FlexElement>
        <FlexElement style={{ flexGrow: 2 }}>
          <FlexContainer style={{ justifyContent: "flex-start", gap: 12 }}>
            {!applyConfirmedFilters && (
              <FlexContainer style={{ marginRight: "-12px" }}>
                <FlexElement>
                  <form
                    /* This form is just here to provide the autcomplete functionality of the browser.
                       The key remounts it when the nameFilter changes, so the subsegment searches will be saved as well. */
                    onSubmit={(e) => {
                      e.preventDefault();
                      setSubmittedNameFilter(nameFilter);
                    }}
                    key={submittedNameFilter}
                  >
                    <Search
                      onChange={(e) => {
                        setNameFilter(e.target.value);
                        if (e.target.value === "") {
                          updateSetting({
                            key: nameFilterKey,
                            value: e.target.value,
                          });
                        }
                      }}
                      onBlur={() => {
                        updateSetting({
                          key: nameFilterKey,
                          value: nameFilter,
                        });
                      }}
                      placeholder={filterPlaceholder}
                      value={nameFilter}
                      style={{ width: "199px" }}
                      id={nameFilterKey}
                      ref={nameFilterRef}
                      title="Ctrl+Space to focus, Enter to save value to autocomplete."
                    />
                  </form>
                </FlexElement>
                <FlexElement>
                  <NameFilterInfo
                    nameProperty={filterNameProperty}
                    serverSide={serverSide}
                  />
                </FlexElement>
              </FlexContainer>
            )}
            <FlexElement>
              <Filters
                filters={filters}
                groupProperties={filterGroupProperties}
                maxWidth={containerSize.width}
                filterKey={filterKey}
                applyConfirmedFilters={applyConfirmedFilters}
                restoreConfirmedFilters={restoreConfirmedFilters}
                defaultOpen={
                  !!applyConfirmedFilters && !isEdit && !doNotOpenFilters
                }
                nameFilter={nameFilter}
                nameFilterKey={nameFilterKey}
                setNameFilter={setNameFilter}
                nameFilterPlaceholder={filterPlaceholder}
                combinedCodelistStatuses={combinedCodelistStatuses}
                doNotApplyFilters={sheetCommand === "replace-subsegment"}
                filteringDisabled={filteringDisabled}
                nameProperty={filterNameProperty}
                serverSide={serverSide}
                hashParts={hashParts}
              />
            </FlexElement>
            <FlexElement>
              <ControlHeaderTitle>
                {sheetTypeName}
                {plantDependentSheets.includes(sheetType) ? (
                  <>
                    <Fade> in </Fade>
                    {plant.LongDescription}
                  </>
                ) : null}
              </ControlHeaderTitle>
            </FlexElement>
          </FlexContainer>
        </FlexElement>
        <HeaderButtonContainer>
          <div>
            <HeaderProgress isRefetching={isRefetching} />
          </div>
          <Tooltip title="Reload">
            <Button variant="ghost_icon" onClick={() => refetch()}>
              <Icon data={refresh} />
            </Button>
          </Tooltip>
          <ExtendedDownloads
            columns={columns}
            sheetType={sheetType}
            plant={plant}
            items={mainItemsSheets}
            filteredItems={filteredItems}
            filename={`${sheetTypeNames[sheetType]}`}
            hideDownloadAll={
              sheetType === "vds" || isCopyModal || isPCSWorkbenchCommand
            }
          />
          {(
            ["vds", "vds-subsegments", "vds-textblocks"] as SheetTypes[]
          ).includes(sheetType) && <VDSTaskMonitorButton />}
          {isCopyModal || !plantDependentSheets.includes(sheetType) ? (
            <></>
          ) : (
            <Tooltip title="Copy data sheets to this plant">
              <Button
                variant="ghost_icon"
                onClick={() => dispatch(showSheetCopyModal())}
              >
                <Icon data={copy} />
              </Button>
            </Tooltip>
          )}
          {["vds-textblocks", "vds-subsegments", "vds"].includes(sheetType) && (
            <Tooltip title={`Create new ${sheetTypeNames[sheetType]}`}>
              <Button
                variant="ghost_icon"
                onClick={() => {
                  setCreateNew(true);
                  setShowCreateNew(true);
                }}
              >
                <Icon data={add} />
              </Button>
            </Tooltip>
          )}
          {["eds"].includes(sheetType) && (
            <Tooltip title={`Create new ${sheetTypeNames[sheetType]}`}>
              <Button
                variant="ghost_icon"
                onClick={() => {
                  setNewEDSOperation("Add");
                }}
              >
                <Icon data={add} />
              </Button>
            </Tooltip>
          )}
        </HeaderButtonContainer>
      </FlexContainer>
    </ControlHeaderContainer>
  );
}
