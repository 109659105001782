import {
  Button,
  CircularProgress,
  Snackbar as EDSSnackbar,
  Typography,
} from "@equinor/eds-core-react";
import axios, { AxiosError } from "axios";
import config from "config/config";
import { useCallback, useEffect, useState } from "react";
import Modal from "react-modal";
import styled from "styled-components";
import { Container, PreLine } from "./Components";
import {
  ModalButtonContainer,
  ModalContentContainer,
  modalStyle,
} from "./Modal";

export type Statuses = "loading" | "success" | "error" | "idle";

export type StatusResponse = {
  UserMessage: string;
  SystemMessage: string;
};

export type StatusResponseWithInfo = StatusResponse & {
  AdditionalInfo: "string";
};

export type StatusResponseWithInfoMaybe = StatusResponse & {
  AdditionalInfo?: "string";
};

const Snackbar = styled(EDSSnackbar)`
  display: flex;
  bottom: 72px;
  p {
    margin: 0;
  }
`;

export function isAxiosError<ResponseType>(
  error: unknown
): error is AxiosError<ResponseType> {
  return axios.isAxiosError(error);
}

export function LoadingModal() {
  return (
    <Modal
      isOpen={true}
      style={modalStyle}
      shouldFocusAfterRender={false}
      shouldReturnFocusAfterClose={false}
      shouldCloseOnEsc={false}
    >
      <ModalContentContainer style={{ marginTop: 20, marginBottom: 15 }}>
        <CircularProgress />
      </ModalContentContainer>
    </Modal>
  );
}

const StatusModal = ({
  status,
  onSuccess,
  onError,
  onSettled,
  onSuccessClose,
  onErrorClose,
  onSettledClose,
  successMessage,
  error,
  onlyError,
  successTitle,
  extraButton,
  buttonText,
  dontShowSuccess,
  noSnackbar,
}: {
  status: Statuses;
  onSuccess?: () => void;
  onError?: () => void;
  onSettled?: () => void;
  onSuccessClose?: () => void;
  onErrorClose?: () => void;
  onSettledClose?: () => void;
  successMessage?: string | JSX.Element;
  error: unknown;
  onlyError?: boolean;
  successTitle?: string | undefined;
  extraButton?: JSX.Element | null;
  buttonText?: string;
  dontShowSuccess?: boolean;
  noSnackbar?: boolean;
}) => {
  const [showDetails, setShowDetails] = useState(false);

  const onSuccessFuncs = useCallback(() => {
    onSuccess && onSuccess();
    onSettled && onSettled();
  }, [onSuccess, onSettled]);

  const onErrorFuncs = useCallback(() => {
    onError && onError();
    onSettled && onSettled();
  }, [onError, onSettled]);

  const onSuccessCloseFuncs = useCallback(() => {
    onSuccessClose && onSuccessClose();
    onSettledClose && onSettledClose();
  }, [onSuccessClose, onSettledClose]);

  const onErrorCloseFuncs = useCallback(() => {
    onErrorClose && onErrorClose();
    onSettledClose && onSettledClose();
  }, [onErrorClose, onSettledClose]);

  const systemMessage =
    error && isAxiosError<StatusResponse>(error)
      ? error.response?.data.SystemMessage
      : "";

  const userMessage =
    error && isAxiosError<StatusResponse>(error)
      ? error.response?.data.UserMessage
      : "";

  useEffect(() => {
    if (status === "idle" || status === "loading") {
      setShowDetails(false);
    }
    if (status === "success") {
      onSuccessFuncs();
    }
    if (status === "error") {
      onErrorFuncs();
    }
  }, [onErrorFuncs, onSuccessFuncs, status]);

  return (
    <>
      {status === "loading" && !onlyError && (
        <>
          <LoadingModal />
        </>
      )}
      {status === "success" &&
        !onlyError &&
        !dontShowSuccess &&
        (!config.statusSuccessSnackbar ||
        noSnackbar ||
        extraButton ||
        buttonText ? (
          <Modal
            isOpen={true}
            style={modalStyle}
            onRequestClose={onSuccessCloseFuncs}
          >
            <ModalContentContainer style={{ textAlign: "center" }}>
              {(successTitle || successTitle === undefined) && (
                <Typography
                  color={!successTitle ? "success" : "default"}
                  variant="h3"
                  style={{ textAlign: "center" }}
                >
                  {successTitle ?? "Success"}
                </Typography>
              )}
              <Container
                style={{ marginTop: successTitle ? "inherit" : "0.5em" }}
              >
                {successMessage ?? "Operation successful."}
              </Container>
              <ModalButtonContainer>
                {extraButton ? extraButton : null}
                <Button
                  variant="outlined"
                  onClick={onSuccessCloseFuncs}
                  style={{
                    ...(buttonText
                      ? {}
                      : { paddingLeft: 24, paddingRight: 24 }),
                  }}
                >
                  {buttonText ?? "OK"}
                </Button>
              </ModalButtonContainer>
            </ModalContentContainer>
          </Modal>
        ) : (
          <Snackbar
            open={status === "success"}
            onClose={onSuccessCloseFuncs}
            autoHideDuration={5000}
          >
            {successMessage ?? "Operation successful."}{" "}
            <Snackbar.Action>
              <Button variant="ghost" onClick={onSuccessCloseFuncs}>
                OK
              </Button>
            </Snackbar.Action>
          </Snackbar>
        ))}
      {status === "error" && (
        <Modal
          isOpen={true}
          style={modalStyle}
          onRequestClose={onErrorCloseFuncs}
        >
          <ModalContentContainer style={{ textAlign: "center" }}>
            {error instanceof Error ? (
              <>
                <Typography
                  variant="h3"
                  color="danger"
                  style={{ textAlign: "center" }}
                >
                  Error
                </Typography>
                <Container>
                  {userMessage && systemMessage ? userMessage : error.message}
                </Container>
                {userMessage && systemMessage ? (
                  showDetails ? (
                    <Container style={{ textAlign: "left" }}>
                      <PreLine>{error.message}</PreLine>
                      <PreLine>{systemMessage}</PreLine>
                    </Container>
                  ) : (
                    <Button
                      variant="ghost"
                      onClick={() => setShowDetails(true)}
                    >
                      Show details
                    </Button>
                  )
                ) : (
                  <>
                    {userMessage ? <p>{userMessage}</p> : null}
                    {systemMessage ? <pre>{systemMessage}</pre> : null}
                  </>
                )}
              </>
            ) : (
              <>An error occured.</>
            )}
            <ModalButtonContainer>
              {extraButton ? extraButton : null}
              <Button variant="outlined" onClick={onErrorCloseFuncs}>
                Close
              </Button>
            </ModalButtonContainer>
          </ModalContentContainer>
        </Modal>
      )}
    </>
  );
};

export default StatusModal;
