import { Field } from "components/form/Field";
import { Fieldset, FieldsetContainer } from "components/form/Form";
import { EditComponentProps } from "../ItemEditModal";
import { useCodelist } from "queries/useCodelist";
import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { Checkbox } from "@equinor/eds-core-react";

export function VDSMiscEdit({
  item,
  setInitializing,
}: EditComponentProps<"vds-misc">) {
  const { data: vskGroups, status: vskGroupsStatus } = useCodelist({
    codelist: "vsk-groups",
  });

  useEffect(() => {
    setInitializing && setInitializing(true);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    vskGroupsStatus === "success" && setInitializing && setInitializing(false);
  }, [vskGroupsStatus, setInitializing]);

  const [vskGroupState, setVSKGroupState] = useState([] as number[]);

  useEffect(
    () => item && setVSKGroupState(item.VSKGroups.map((e) => e.GroupID)),
    [vskGroups, item]
  );

  const { setValue, register } = useFormContext();

  useEffect(
    () =>
      setValue(
        "VSKGroups",
        vskGroupState.map((e) => ({ GroupID: e }))
      ),
    [setValue, vskGroupState]
  );

  return (
    <>
      <input type="hidden" {...register("VSKGroups")} />
      <FieldsetContainer>
        <Fieldset>
          {!item && (
            <Field
              area="vds-misc"
              prop="ItemID"
              value=""
              title="ID"
              type="text"
              style={{ width: 120 }}
              disabled={!!item}
            />
          )}
          <Field
            area="vds-misc"
            prop="Description"
            value={item ? item.Description : ""}
            title="Description"
            type="text"
            uppercase
          />
        </Fieldset>
        <Fieldset slim>
          {vskGroups &&
            vskGroups.map((vskGroup) => {
              return (
                <div key={vskGroup.GroupID}>
                  <Checkbox
                    checked={vskGroupState.includes(vskGroup.GroupID)}
                    label={vskGroup.Description}
                    onChange={() =>
                      setVSKGroupState(
                        vskGroupState.includes(vskGroup.GroupID)
                          ? vskGroupState.filter((e) => e !== vskGroup.GroupID)
                          : [...vskGroupState, vskGroup.GroupID]
                      )
                    }
                  />
                </div>
              );
            })}
        </Fieldset>
      </FieldsetContainer>
    </>
  );
}
