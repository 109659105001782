import { useQuery } from "react-query";
import { SheetStatus } from "../config/statusConfig";
import axios from "axios";
import config from "config/config";
import { getItemID } from "../util";

export type AffectedVDSByReplacementItem = {
  VDS: string;
  Revision: string;
  Status: SheetStatus;
  RevDate: string;
  LastUpdateBy: string;
  VDSDescription: string;
};

type AffectedVDSProcessedItem = AffectedVDSByReplacementItem & {
  itemID: string;
};

export type AffectedVDSApiData = {
  getVDSAffected: AffectedVDSByReplacementItem[];
};

export function useAffectedVDSByReplacementQuery({
  subsegmentId,
  subsegmentRevision,
  enabled,
}: {
  subsegmentId: number | undefined;
  subsegmentRevision: string | undefined;
  enabled: boolean;
}) {
  return useQuery<{ sheets: AffectedVDSProcessedItem[] }, Error>(
    [
      "sheets",
      "vds",
      "affected-vds-by-replacement",
      subsegmentId,
      subsegmentRevision,
    ],
    async () => {
      const url = `${config.restApiBaseUrl}vds-subsegments/${subsegmentId}/rev/${subsegmentRevision}/affected-vds-by-replacement`;
      const { data } = await axios.get<AffectedVDSApiData>(url);
      return {
        sheets: data.getVDSAffected.map((e) => ({
          itemID: getItemID(e.VDS, e.Revision),
          ...e,
        })),
      };
    },
    {
      enabled: enabled && !!subsegmentId && !!subsegmentRevision,
    }
  );
}
