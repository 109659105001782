import { Button } from "@equinor/eds-core-react";
import { useAppDispatch } from "app/hooks";
import { MultiColumnList } from "components/Components";
import useConfirm from "components/confirm/useConfirm";
import { PanelButtonContainer, PanelContainer } from "components/Panels";
import StatusModal from "components/StatusModal";
import { useVDSSubsegmentReplacement } from "features/sheets/queries/useVDSSubsegmentReplacement";
import React, { useEffect } from "react";
import { successMessage } from "utils/successMessage";
import { useFromToVDSSubsegmentSelector } from "../hooks/useFromToVDSSubsegmentSelector";
import {
  setSubsegmentRefFilter,
  setSubsegmentRefFilterRev,
} from "../sheetSlice";
import { getItemName, getItemRev } from "../util";

export const VDSReplaceSubsegment = React.forwardRef(
  (
    {
      selectedItems,
      setFilteringDisabled,
      listOfSetOfficial,
    }: {
      selectedItems: any[];
      setFilteringDisabled: React.Dispatch<boolean>;
      listOfSetOfficial: string[];
    },
    ref: React.Ref<any>
  ) => {
    const dispatch = useAppDispatch();
    const { vdsSelectorContent, fromSelection, toSelection } =
      useFromToVDSSubsegmentSelector({
        enabled: true,
      });

    useEffect(() => {
      if (fromSelection.length > 0) {
        dispatch(setSubsegmentRefFilter(getItemName(fromSelection[0])));
        dispatch(setSubsegmentRefFilterRev(getItemRev(fromSelection[0])));
        setFilteringDisabled(false);
      } else {
        setFilteringDisabled(true);
      }
    }, [fromSelection, dispatch, setFilteringDisabled]);

    useEffect(() => {
      return () => {
        dispatch(setSubsegmentRefFilter(0));
        dispatch(setSubsegmentRefFilterRev(""));
      };
      // Run on unmount
      // eslint-disable-next-line
    }, []);

    const { mutate, status, error, reset, data } =
      useVDSSubsegmentReplacement();

    const { isConfirmed } = useConfirm();

    const changeSubsegment = async () => {
      const subsegmentId = Number(getItemName(fromSelection[0]));
      const revision = getItemRev(fromSelection[0]);
      const replacedBySubsegmentId = Number(getItemName(toSelection[0]));
      const replacedByRevision = getItemRev(toSelection[0]);
      const sheets = selectedItems.map((item) => ({
        vds: getItemName(item.itemID),
        revision: getItemRev(item.itemID),
        setOfficial: listOfSetOfficial.includes(item.itemID),
      }));
      (await isConfirmed(
        <>
          Are you sure you want to replace Subsegment ID {subsegmentId} rev.{" "}
          {revision} with Subsegment ID {replacedBySubsegmentId} rev.{" "}
          {replacedByRevision} in the following VDS
          {sheets.length > 1 ? "s" : ""}?
          <MultiColumnList>
            {sheets.map((sheet) => (
              <li key={sheet.vds + sheet.revision}>
                {sheet.vds} rev. {sheet.revision}
                {sheet.setOfficial && " (set to Official)"}
              </li>
            ))}
          </MultiColumnList>
        </>
      )) &&
        mutate({
          subsegmentId,
          revision,
          replacedBySubsegmentId,
          replacedByRevision,
          sheets,
        });
    };

    return (
      <>
        <div ref={ref} style={{ paddingBottom: 16 }}>
          <PanelContainer>
            {vdsSelectorContent}
            <PanelButtonContainer>
              <Button
                onClick={changeSubsegment}
                disabled={
                  fromSelection.length === 0 ||
                  toSelection.length === 0 ||
                  selectedItems.length === 0
                }
              >
                Change Subsegment
              </Button>
            </PanelButtonContainer>
          </PanelContainer>
        </div>
        <StatusModal
          status={status}
          error={error}
          onSettledClose={reset}
          successMessage={successMessage(data)}
        />
      </>
    );
  }
);
