import { Access } from "Access";
import { useUserConfig } from "UserConfigContext";
import {
  Category,
  ExternalLink,
  MenuWrapper,
} from "components/MainMenuComponents";
import { ExamplesMenu } from "features/examples/Examples";
import { ReportsMenu } from "features/reports/ReportsMenu";
import { useAppSelector } from "./app/hooks";
import TestpageMenu from "./features/testpages/TestpageMenu";
import { selectUIState } from "./uiSlice";
import { SheetMenu } from "features/sheets/SheetMenu";
import { PMGMenu } from "features/pmg/PMGMenu";
import { AdminMenu } from "features/admin/AdminMenu";
import config from "config/config";

export function MainMenu() {
  const { isMenuOpen, showAlpha } = useAppSelector(selectUIState);

  return (
    <MenuWrapper className={isMenuOpen ? "open" : ""}>
      <SheetMenu />
      <PMGMenu />
      <AdminMenu />
      <OtherModulesMenu />
      <ReportsMenu />
      {showAlpha && <TestpageMenu />}
      {showAlpha && <ExamplesMenu />}
    </MenuWrapper>
  );
}

function OtherModulesMenu() {
  const { configParams } = useUserConfig();

  return (
    <Category title="Other Modules">
      <ExternalLink
        configParamId={1}
        configParams={configParams}
        title="Public Module"
      />
      <Access role="S" hide>
        <ExternalLink
          configParamId={27}
          configParams={configParams}
          title="EDS (Structured)"
          urlPostFix="/EDS"
        />
        <ExternalLink
          configParamId={27}
          configParams={configParams}
          title="VSK Web Module"
          urlPostFix="/VSK"
        />
        <ExternalLink
          configParamId={27}
          configParams={configParams}
          title="MDS Web Module"
          urlPostFix="/MDS"
        />
        {config.branding.solutionName === "TR2000" ? (
          <>
            <ExternalLink
              configParamId={27}
              configParams={configParams}
              title="Bolt Tension"
              urlPostFix="/bolt_tension"
            />
            <ExternalLink
              configParamId={27}
              configParams={configParams}
              title="Manufacturers"
            />
          </>
        ) : (
          <></>
        )}
      </Access>
    </Category>
  );
}
