import { Button, Icon } from "@equinor/eds-core-react";
import { check } from "@equinor/eds-icons";
import {
  ModalSideMargin,
  ModalWindow,
  ModalWindowButtonContainer,
  ModalWindowContentDefault,
} from "components/ModalWindow";
import useConfirm from "components/confirm/useConfirm";
import { MultiselectFieldFromCommaSeparated } from "components/form/EditComponents";
import { FieldContext } from "components/form/FieldContext";
import { combineQueryStatuses } from "queries/queryUtil";
import { useCodelist } from "queries/useCodelist";
import useSheet from "features/sheets/queries/useSheet";
import { useCallback, useEffect, useMemo, useReducer, useRef } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { confirmNavigation } from "utils/confirmNavigation";
import { closeEditSheet } from "../../commands";
import { sheetProperties } from "../../config/sheetConfig";
import { FormVDSSubsegmentProperties, VDSSubsegment } from "../../types";
import {
  PropertiesColumns,
  QueryStatusAction,
  VDSValidators,
  statusesReducer,
} from "../VDSCommonProperties";
import { VDSSubsegmentSubtitle } from "./EditVDSSubsegment";
import { useAttemptSaveVDSSubsegementProperties } from "./useAttemptSaveVDSSubsegementProperties";

const additionalColumns = [{ key: "inherited", title: "Inherited" }];

export function VDSSubsegmentCharacteristics({
  data,
  statusesDispatch,
  isRefetching,
}: {
  data: VDSSubsegment;
  statusesDispatch: React.Dispatch<QueryStatusAction>;
  isRefetching: boolean;
}) {
  const {
    data: vdsCharacteristicsData,
    status,
    error,
  } = useCodelist({
    codelist: "vds-characteristics",
  });

  useEffect(() => {
    statusesDispatch({
      type: "set",
      payload: { "vds-characteristics": status },
    });
  }, [status, statusesDispatch]);

  const value = data.getVDSSubsegmentCharSum
    .map((e) => e.CharacteristicID)
    .join();

  const vdsCharacteristicsOptions = useMemo(
    () =>
      vdsCharacteristicsData?.map((e) => ({
        key: String(e.CharacteristicID),
        title: e.Description,
        inherited: data.getVDSSubsegmentCharInherited
          .map((i) => i.CharacteristicID)
          .includes(e.CharacteristicID) ? (
          <Icon data={check} size={16} />
        ) : (
          ""
        ),
      })),
    [data.getVDSSubsegmentCharInherited, vdsCharacteristicsData]
  );

  return (
    <MultiselectFieldFromCommaSeparated
      options={vdsCharacteristicsOptions}
      prop="VDSCharacteristicProperty"
      title="VDS Characteristics"
      value={value}
      noneSelectedString="None"
      noneSelectedValue=""
      status={status}
      error={error}
      additionalColumns={additionalColumns}
      showTableHeader={true}
      titleTitle="Characteristics"
      isRefetching={isRefetching}
      selectedFirst={false}
    />
  );
}

export function VDSSubsegmentPropertiesFields({
  data,
  statusesDispatch,
  isRefetching,
}: {
  data: VDSSubsegment;
  statusesDispatch: React.Dispatch<QueryStatusAction>;
  isRefetching: boolean;
}) {
  return (
    <PropertiesColumns>
      <VDSSubsegmentCharacteristics
        data={data}
        statusesDispatch={statusesDispatch}
        isRefetching={isRefetching}
      />
      <VDSValidators
        data={data.getVDSSubsegmentProperties[0]}
        statusesDispatch={statusesDispatch}
        isRefetching={isRefetching}
        isEdit={true}
      />
    </PropertiesColumns>
  );
}

export function VDSSubsegmentPropertiesModal({
  name,
  revision,
  tab,
}: {
  name: string;
  revision: string;
  tab: string;
}) {
  const history = useHistory();
  const open = useMemo(
    () => !!name && !!revision && tab === "properties",
    [name, revision, tab]
  );

  const { data, status, error, isRefetching, isRefetchError, refetch } =
    useSheet({
      sheetType: "vds-subsegments",
      name,
      revision,
    });

  const closeOnSuccess = useRef(false);

  const methods = useForm<FormVDSSubsegmentProperties>({
    shouldUnregister: true,
    mode: "all",
  });

  const { reset, formState, handleSubmit } = methods;
  const { isDirty } = formState;

  const { isConfirmed } = useConfirm();

  const closeModal = async () => {
    (!isDirty || (await confirmNavigation({ isConfirmed }))) &&
      closeEditSheet({ history });
  };

  const onSuccess = useCallback(() => {
    reset(undefined, { keepDirty: false });
    if (closeOnSuccess.current) {
      vdsSubsegmentPropertiesReset();
      closeModal();
    }
    // closeModal is not useCallback-able.
    // vdsSubsegmentPropertiesReset is declared later.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reset]);

  const [statuses, statusesDispatch] = useReducer(statusesReducer, {});

  const combinedStatuses = useMemo(
    () => combineQueryStatuses(...Object.values(statuses)),
    [statuses]
  );

  const {
    attemptSaveVDSSubsegmentContent,
    onSubmit,
    vdsSubsegmentPropertiesReset,
  } = useAttemptSaveVDSSubsegementProperties({
    data,
    onSuccess,
    name,
    revision,
  });

  useEffect(() => {
    if (combinedStatuses === "success" && !isRefetching && !isRefetchError) {
      reset(undefined, { keepDirty: isDirty });
    }
  }, [isRefetching, isRefetchError, data, reset, combinedStatuses, isDirty]);

  return (
    <>
      <ModalWindow
        isOpen={open}
        closeModal={closeModal}
        title={`Properties for ${sheetProperties["vds-subsegments"].name} ${name}`}
        subtitle={
          <VDSSubsegmentSubtitle
            heading={data?.getVDSSubsegmentHeader[0]}
            status={status}
            hideName={true}
          />
        }
        layer="default"
        status={status}
        error={error}
        refetch={refetch}
      >
        {data && (
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <FieldContext.Provider value={{}}>
                <ModalWindowContentDefault
                  style={{ maxWidth: 1330, marginTop: 16, marginBottom: 32 }}
                >
                  <ModalSideMargin>
                    <VDSSubsegmentPropertiesFields
                      data={data}
                      statusesDispatch={statusesDispatch}
                      isRefetching={isRefetching}
                    />
                  </ModalSideMargin>
                </ModalWindowContentDefault>
                <ModalWindowButtonContainer>
                  <Button
                    type="submit"
                    variant="outlined"
                    disabled={combinedStatuses !== "success" || isRefetching}
                    onClick={() => {
                      closeOnSuccess.current = false;
                    }}
                  >
                    Save
                  </Button>
                  <Button
                    type="submit"
                    disabled={combinedStatuses !== "success" || isRefetching}
                    onClick={() => {
                      closeOnSuccess.current = true;
                    }}
                  >
                    Save & Close
                  </Button>
                  <Button variant="outlined" onClick={closeModal}>
                    Close
                  </Button>
                </ModalWindowButtonContainer>
              </FieldContext.Provider>
            </form>
          </FormProvider>
        )}
      </ModalWindow>
      {attemptSaveVDSSubsegmentContent}
    </>
  );
}
