import { TopBar } from "@equinor/eds-core-react";
import React from "react";
import { Link } from "react-router-dom";
import * as Space from "react-spaces";
import styled from "styled-components";
import Content from "../Content";
import { MainMenu } from "MainMenu";
import { H1Element } from "./Components";
import { breakpoints, breakpointsNumber } from "./breakpoints";
import config from "config/config";
import presightLogo from "media/presightLogo.svg";
import presightSolutionsLogo from "media/presightSolutionsLogo.svg";

export const AppContainer = styled.div`
  background: var(--fadedBg);
  inset: 0;
  position: absolute;
  min-width: 768px;
  min-height: 768px;
`;

const MenuContainer = styled(Space.LeftResizable)`
  z-index: 200;
  @media (min-width: ${breakpoints.lg}) {
    margin: 1em 0 1.5em 1em;
  }
`;

const ContentContainer = styled(Space.Fill)`
  background: var(--bg);
  position: absolute;
  inset: 0;
  border-radius: 4px;
  margin-bottom: 1.5em;
  @media (min-width: ${breakpoints.lg}) {
    margin: 1em 1em 1.5em 0;
  }
`;

export const AppHeader = styled(TopBar)`
  border-bottom: none;
  z-index: 99 !important;
  padding-left: 15px;
  padding-right: 15px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  display: flex;
  justify-content: space-between;
  line-height: inherit;
  @media (min-width: ${breakpoints.lg}) {
    padding-left: 24px;
    padding-right: 24px;
  }
`;

export const AppTitleFullContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 2em 0 1em;
`;

const AppTitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.35em;
  white-space: nowrap;
  text-decoration: none;
  line-height: 1.25;
`;

const AppSubtitleContainer = styled.div`
  font-size: 50%;
  font-weight: normal;
  text-transform: uppercase;
  border-width: 1px;
  border-radius: 4px;
  border-style: solid;
  padding: 0.2em 0.3em 0;
  @media (min-width: ${breakpoints.lg}) {
    padding: 0.1em 0.25em 0;
  }
`;

export function PresightLogo({ hasSolutions }: { hasSolutions?: boolean }) {
  const larger = hasSolutions ? true : false;
  return (
    <div
      style={{
        fontSize: larger ? 16 : 12,
        opacity: larger ? 1 : 0.6,
        display: "flex",
        paddingTop: larger ? 10 : 4,
        justifyContent: "center",
      }}
    >
      <div>
        <span
          style={{
            paddingRight: larger ? 4 : 3,
            verticalAlign: larger ? 11 : 3,
            fontStyle: "italic",
          }}
        >
          Developed by
        </span>
        <a
          href="https://presight.com/"
          target="_blank"
          rel="noreferrer"
          title="Presight Solutions AS"
        >
          <img
            src={hasSolutions ? presightSolutionsLogo : presightLogo}
            alt="Presight Solutions AS"
            style={{ width: larger ? 84 : 60, height: larger ? 42 : 25 }}
          />
        </a>
      </div>
    </div>
  );
}

export function AppSubtitle() {
  const subtitleColor =
    config.properties.env === "test"
      ? "var(--warningText)"
      : config.properties.env === "local" ||
        config.properties.env === "development" ||
        config.properties.env === "dev" ||
        config.properties.env === "demo"
      ? "var(--successText)"
      : config.properties.env === "production"
      ? "var(--neutralText)"
      : "var(--dangerText)";

  return (
    <div style={{ marginTop: -4 }}>
      <AppSubtitleContainer
        style={{
          color: subtitleColor,
          borderColor: subtitleColor,
        }}
      >
        {config.properties.env}
      </AppSubtitleContainer>
    </div>
  );
}

export function AppTitle({
  secondary,
  link,
  presightSolutionsLogo,
}: {
  secondary?: boolean;
  link?: boolean;
  presightSolutionsLogo?: boolean;
}) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <H1Element style={secondary ? { marginBottom: 0 } : {}}>
        <AppTitleContainer>
          <div>
            {link ? (
              <Link
                to="/"
                style={{ textDecoration: "none", color: "var(--text)" }}
              >
                {config.branding.appName}
              </Link>
            ) : (
              config.branding.appName
            )}
          </div>
          {config.branding.customerName && (
            <span
              style={{
                fontSize: 14,
                opacity: 0.8,
                marginLeft: -2,
                marginRight: -2,
              }}
            >
              for {config.branding.customerName}
            </span>
          )}
          <AppSubtitle />
        </AppTitleContainer>
      </H1Element>
      {presightSolutionsLogo && <PresightLogo hasSolutions />}
    </div>
  );
}

export const AppHeaderContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const AppHeaderElement = styled.div``;

export function AppWithSettingsLoaded({ pageWidth }: { pageWidth: number }) {
  return (
    <>
      <MenuContainer
        size={pageWidth >= breakpointsNumber.lg ? 270 : 0}
        minimumSize={200}
        maximumSize={pageWidth / 2}
        scrollable={true}
      >
        <Space.Fill scrollable style={{ scrollbarGutter: "stable" }}>
          <MainMenu />
        </Space.Fill>
        <Space.Bottom size={32} style={{ marginRight: "1em" }}>
          <PresightLogo />
        </Space.Bottom>
      </MenuContainer>
      <ContentContainer scrollable={true}>
        <Content />
      </ContentContainer>
    </>
  );
}
