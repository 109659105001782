import { Button, EdsProvider, Icon } from "@equinor/eds-core-react";
import { remove_outlined } from "@equinor/eds-icons";
import { useAppSelector } from "app/hooks";
import {
  FlexContainer,
  FlexElement,
  RevisionMark,
  SimpleButtonContainer,
} from "components/Components";
import { ExtendedDownloads } from "components/Downloads";
import {
  ModalSideMargin,
  ModalWindow,
  ModalWindowButtonContainer,
  ModalWindowContentFill,
} from "components/ModalWindow";
import StatusModal from "components/StatusModal";
import { RevisionMarkWithPreviewButtons } from "components/table/Columns";
import Table, {
  ColumnsProps,
  SmallTableContainer,
} from "components/table/Table";
import { useFiltersReducer } from "components/table/useFiltersReducer";
import { useTableSelect } from "components/table/useTableSelect";
import { SheetPane } from "features/sheets/SheetPane";
import {
  sheetDocumentWidth,
  vdsWidth,
} from "features/sheets/config/sheetConfig";
import { AffectedVDSByReplacementItem } from "features/sheets/queries/useAffectedVDSByReplacementQuery";
import useVDSAffectedVDSAction, {
  AffectedVDSActionCodes,
} from "features/sheets/queries/useVDSSubsegmentAffectedVDSAction";
import useVDSSubsegmentPropertiesCommand, {
  AffectedVDSByPropertiesChangeItem,
  SubsegmentSubstitution,
} from "features/sheets/queries/useVDSSubsegmentPropertiesCommand";
import useVDSSubsegmentReplacementValidation, {
  SubsegmentReplacementSubmitItem,
} from "features/sheets/queries/useVDSSubsegmentReplacementValidation";
import { useVDSSubsegmentSubstitutionForExpiration } from "features/sheets/queries/useVDSSubsegmentSubstitutionForExpiration";
import useVDSSubsegmentVDSContentValidation from "features/sheets/queries/useVDSSubsegmentVDSContentValidation";
import { SaveVDSSubsegmentProperties } from "features/sheets/types";
import {
  WithItemId,
  getItemID,
  getItemName,
  getItemRev,
} from "features/sheets/util";
import { Codelists } from "queries/useCodelist";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import * as Space from "react-spaces";
import useMeasure from "react-use-measure";
import { selectUIState } from "uiSlice";
import { filterItemsByProps } from "utils/filterItemsByProps";
import { Filter } from "../../../../components/table/Filter";

type ActionTypes = "replace" | "delete" | "remove_filter" | "none";

export type AffectedProgress =
  | "idle"
  | "processing"
  | "waiting-for-user"
  | "ready-to-save"
  | "done";

const actionTitles = {
  none: "",
  delete: "Delete",
  replace: "Replace",
  remove_filter: "Remove Filter",
};

const actionCodes = {
  none: "",
  delete: "D",
  replace: "R",
  remove_filter: "N",
};

type Actions = {
  [itemId: string]: { action: ActionTypes; replacementItemId: string };
};

type AffectedItemWithId =
  | (AffectedVDSByPropertiesChangeItem & { itemId: string })
  | (AffectedVDSByReplacementItem & { itemId: string });

const vdsListColumns: ColumnsProps[] = [
  {
    key: "VDS",
    title: "VDS",
    width: vdsWidth,
  },
  {
    key: "Revision",
    title: "Revision",
    Component: RevisionMarkWithPreviewButtons,
    type: "with-context",
    componentProps: {
      sheetType: "vds",
      previewLocation: "affectedVDSModal",
    },
  },
  {
    key: "Status",
    title: "Status",
  },
  {
    key: "RevDate",
    title: "Revision Date",
    width: 100,
  },
  {
    key: "LastUpdateBy",
    title: "Last Update By",
    width: 90,
  },
];

const vdsListColumnsActions: ColumnsProps[] = [
  {
    key: "ReplacementSubsegmentItemId",
    title: "Replacement Subsegment",
    type: "with-context",
    Component: VDSSubsegmentReplacement,
  },
  {
    key: "Action",
    title: "Action",
    type: "with-context",
    Component: VDSSubsegmentAction,
    width: "100%",
  },
];

const vdsListColumnsForPropertiesChange: ColumnsProps[] = vdsListColumns.concat(
  [
    {
      key: "ValveTypeID",
      title: "Valve Type",
      codelist: "valve-types",
      width: 150,
    },
    {
      key: "RatingClassID",
      title: "Rating Class",
      codelist: "rating-classes",
      width: 90,
    },
    {
      key: "MaterialGroupID",
      title: "Mat. Group",
      longTitle: "Material Group",
      codelist: "material-groups",
      width: 90,
    },
    {
      key: "EndConnectionID",
      title: "End Connection",
      codelist: "end-connections",
      width: 190,
    },
    {
      key: "BoreID",
      title: "Bore",
      codelist: "bores",
      width: 90,
    },
    {
      key: "VDSSizeID",
      title: "Size",
      codelist: "vds-sizes",
    },
  ],
  vdsListColumnsActions
);

const vdsListColumnsForReplacement: ColumnsProps[] = vdsListColumns.concat(
  [
    {
      key: "VDSDescription",
      title: "Description",
      width: "100%",
    },
  ],
  vdsListColumnsActions
);

const subsegmentListColumns: ColumnsProps[] = [
  { key: "SubsegmentID", title: "ID", width: 90 },
  {
    key: "Revision",
    title: "Revision",
    Component: RevisionMarkWithPreviewButtons,
    type: "with-context",
    componentProps: {
      sheetType: "vds-subsegments",
      previewLocation: "affectedVDSModal",
    },
  },
  { key: "Status", title: "Status" },
  { key: "SubsegmentName", title: "Name", width: "100%" },
];

const duplicatesColumns: ColumnsProps[] = [
  { key: "VDSAffected", title: "Affected VDS", width: 90 },
  {
    key: "VDSAffectedRevision",
    title: "Revision",
    Component: RevisionMark,
    width: 35,
  },
  { key: "VDSDuplicate", title: "Duplicate VDS", width: 90 },
  {
    key: "VDSDuplicateRevision",
    title: "Revision",
    Component: RevisionMark,
    width: 35,
  },
];

function VDSSubsegmentAction({
  item,
  actions,
  setActions,
  subsegmentSubstitutionList,
}: {
  item: AffectedItemWithId;
  actions: Actions;
  setActions: React.Dispatch<Actions>;
  subsegmentSubstitutionList: WithItemId<SubsegmentSubstitution>[];
}) {
  const currentAction = actions[item.itemId];
  return (
    <FlexContainer gap style={{ flexWrap: "nowrap" }}>
      <FlexElement style={{ whiteSpace: "nowrap" }}>
        {actionTitles[currentAction.action]}
      </FlexElement>
      <FlexElement>
        {currentAction.action !== "none" && (
          <>
            <Button
              onClick={() =>
                setActions({
                  ...actions,
                  ...{
                    [item.itemId]: { action: "none", replacementItemId: "" },
                  },
                })
              }
              variant="ghost_icon"
              title="Delete this Action"
            >
              <Icon data={remove_outlined} />
            </Button>
          </>
        )}
      </FlexElement>
    </FlexContainer>
  );
}

function VDSSubsegmentReplacement({
  item,
  actions,
  setActions,
  subsegmentSubstitutionList,
}: {
  item: AffectedItemWithId;
  actions: Actions;
  setActions: React.Dispatch<Actions>;
  subsegmentSubstitutionList: WithItemId<SubsegmentSubstitution>[];
}) {
  return <>{actions[item.itemId]?.replacementItemId}</>;
}

export function AffectedVDS({
  name,
  revision,
  changeType,
  affectedVDS,
  setShowAffectedVDSModal,
  properties,
  setAffectedProgress,
  onSettled,
}: {
  name: string;
  revision: string;
  changeType: "properties" | "expiry";
  affectedVDS:
    | AffectedVDSByPropertiesChangeItem[]
    | AffectedVDSByReplacementItem[];
  setShowAffectedVDSModal: React.Dispatch<boolean>;
  properties?: SaveVDSSubsegmentProperties;
  setAffectedProgress: React.Dispatch<AffectedProgress>;
  onSettled?: () => void;
}) {
  const [filters, filtersDispatch] = useFiltersReducer();

  const items = useMemo<AffectedItemWithId[]>(
    () =>
      affectedVDS.map((e) => ({
        ...e,
        itemId: getItemID(e.VDS, e.Revision),
      })),
    [affectedVDS]
  );

  const filteredItems = useMemo<AffectedItemWithId[]>(
    () =>
      filterItemsByProps({
        items,
        filters,
      }),
    [filters, items]
  );

  const { selection, selectionDispatch, selectionMode } = useTableSelect({
    selectionMode: "multi",
  });

  const {
    selection: substituteSelection,
    selectionDispatch: substituteSelectionDispatch,
    selectionMode: substituteSelectionMode,
  } = useTableSelect({ selectionMode: "single" });

  const [actions, setActions] = useState<Actions>(
    Object.fromEntries(
      items.map((e) => [
        e.itemId,
        {
          action: "none",
          replacementItemId: "",
        },
      ])
    )
  );

  const { paneModes } = useAppSelector(selectUIState);

  const [containerRef, containerSize] = useMeasure();

  const {
    mutate: subsegmentPropertiesSubstitutesMutate,
    data: subsegmentPropertiesSubstitutesData,
    status: subsegmentPropertiesSubstitutesStatus,
    error: subsegmentPropertiesSubstitutesError,
  } = useVDSSubsegmentPropertiesCommand({
    command: "subsegment-substitution",
  });

  const {
    data: subsegmentExpiryData,
    status: subsegmentExpiryStatus,
    error: subsegmentExpiryError,
  } = useVDSSubsegmentSubstitutionForExpiration({
    name,
    revision,
  });

  const subsegmentSubstitutesData:
    | WithItemId<SubsegmentSubstitution>[]
    | undefined = useMemo(
    () =>
      changeType === "properties"
        ? subsegmentPropertiesSubstitutesData?.data.getSubstituteSubsegments.map(
            (e) => ({
              ...e,
              itemId: getItemID(e.SubsegmentID, e.Revision),
            })
          )
        : subsegmentExpiryData?.map((e) => ({
            SubsegmentID: String(e.SubSegmentID),
            Revision: e.Revision,
            Status: e.Status,
            SubsegmentName: e.Description,
            itemId: getItemID(e.SubSegmentID, e.Revision),
          })),
    [
      changeType,
      subsegmentExpiryData,
      subsegmentPropertiesSubstitutesData?.data.getSubstituteSubsegments,
    ]
  );

  const subsegmentSubstitutesStatus =
    changeType === "properties"
      ? subsegmentPropertiesSubstitutesStatus
      : subsegmentExpiryStatus;
  const subsegmentSubstitutesError =
    changeType === "properties"
      ? subsegmentPropertiesSubstitutesError
      : subsegmentExpiryError;

  const subsegmentSubstitutesListRef = useRef<
    WithItemId<SubsegmentSubstitution>[]
  >([]);

  useEffect(() => {
    changeType === "properties" &&
      properties &&
      subsegmentPropertiesSubstitutesMutate({
        name,
        revision,
        content: properties,
      });
    // Do it on mount.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [isRefSet, setIsRefSet] = useState(false);

  const sheetListRef = useRef<SubsegmentReplacementSubmitItem[]>([]);

  useEffect(() => {
    if (subsegmentSubstitutesData) {
      subsegmentSubstitutesListRef.current = subsegmentSubstitutesData;
      setIsRefSet(true);
    }
  }, [subsegmentSubstitutesData]);

  useEffect(() => {
    selectionDispatch({ type: "reset", payload: items.map((e) => e.itemId) });
  }, [items, selectionDispatch]);

  const {
    mutate: validateReplacement,
    status: validateReplacementStatus,
    error: validateReplacementError,
    data: validateReplacementData,
    reset: validateReplacementReset,
  } = useVDSSubsegmentReplacementValidation();

  const {
    mutate: validateVDSContent,
    status: validateVDSContentStatus,
    error: validateVDSContentError,
    data: validateVDSContentData,
    reset: validateVDSContentReset,
  } = useVDSSubsegmentVDSContentValidation();

  const validateVDSContentProps = useMemo(
    () => ({
      name,
      revision,
      content: {
        sheets: Object.keys(actions)
          .filter(
            (itemId) =>
              actions[itemId].action === "replace" ||
              actions[itemId].action === "delete"
          )
          .map((itemId) => ({
            VDS: getItemName(itemId),
            Revision: getItemRev(itemId),
            SubsegmentID:
              actions[itemId].action === "delete"
                ? ""
                : getItemName(actions[itemId].replacementItemId),
            SubsegmentRevision:
              actions[itemId].action === "delete"
                ? ""
                : getItemRev(actions[itemId].replacementItemId),
          })),
      },
    }),
    [actions, name, revision]
  );

  const {
    mutate: affectedVDSAction,
    status: affectedVDSActionStatus,
    error: affectedVDSActionError,
    reset: affectedVDSActionReset,
  } = useVDSAffectedVDSAction();

  const affectedVDSActionCall = useCallback(
    () =>
      affectedVDSAction({
        name,
        revision,
        content: {
          sheets: Object.keys(actions).map((itemId) => {
            const action = actions[itemId];
            return {
              VDS: getItemName(itemId),
              Revision: getItemRev(itemId),
              Action: actionCodes[action.action] as AffectedVDSActionCodes,
              ReplaceWithSubSegmentID: getItemName(action.replacementItemId),
              ReplaceWithSubsegmentRevision: getItemRev(
                action.replacementItemId
              ),
            };
          }),
        },
      }),
    [actions, affectedVDSAction, name, revision]
  );

  useEffect(() => {
    if (validateReplacementStatus === "success") {
      if (validateReplacementData.data.getReplaceSubsegmentValidation) {
        const validReplacements = sheetListRef.current.filter((listItem) =>
          validateReplacementData.data.getReplaceSubsegmentValidation
            .filter((e) => e.ValidationStatus === "1")
            .map((e) => e.VDS)
            .includes(listItem.VDS)
        );
        setActions({
          ...actions,
          ...Object.fromEntries(
            validReplacements.map((replacement) => [
              getItemID(replacement.VDS, replacement.Revision),
              {
                action: "replace" as ActionTypes,
                replacementItemId: getItemID(
                  replacement.ReplaceWithSubSegmentID,
                  replacement.ReplaceWithSubsegmentRevision
                ),
              },
            ])
          ),
        });
        selectionDispatch({
          type: "deselect",
          payload: validReplacements.map((replacement) =>
            getItemID(replacement.VDS, replacement.Revision)
          ),
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validateReplacementStatus]);

  const successMessage = useMemo(() => {
    const numberOfActions =
      validateReplacementData?.data.getReplaceSubsegmentValidation.filter(
        (e) => e.ValidationStatus === "1"
      ).length;
    return !numberOfActions
      ? "No new Replacement Actions added."
      : numberOfActions +
          ` Replacement Action${
            numberOfActions && numberOfActions > 1 ? "s" : ""
          } added.`;
  }, [validateReplacementData?.data.getReplaceSubsegmentValidation]);

  const needsActionCount = useMemo(
    () =>
      Object.keys(actions).filter((itemId) => actions[itemId].action === "none")
        .length,
    [actions]
  );

  const hasActionCount = useMemo(
    () =>
      Object.keys(actions).filter((itemId) => actions[itemId].action !== "none")
        .length,
    [actions]
  );

  const duplicates = useMemo(
    () =>
      !!validateVDSContentData?.data.getVDSDuplicates &&
      validateVDSContentData?.data.getVDSDuplicates,
    [validateVDSContentData?.data.getVDSDuplicates]
  );

  const validateVDSContentMessage = useMemo(() => {
    return duplicates && duplicates.length > 0 ? (
      <>
        <p>
          The replacements on the following VDSs are invalid, as they would lead
          to duplicate VDSs.
        </p>
        <SmallTableContainer>
          <Table
            items={duplicates}
            itemIdProp="VDSAffected"
            columns={duplicatesColumns}
            density="compact"
            style={{ width: "auto" }}
          />
        </SmallTableContainer>
      </>
    ) : (
      <>The actions are valid, no duplicates found.</>
    );
  }, [duplicates]);

  const shouldSave = useRef(false);

  const itemsWithActions = useMemo(
    () =>
      items.map((item) => ({
        ...item,
        ReplacementSubsegmentItemId: actions[item.itemId].replacementItemId,
        Action: actionTitles[actions[item.itemId].action],
      })),
    [items, actions]
  );

  const filteredItemsWithActions = useMemo(
    () =>
      filteredItems.map((item) => ({
        ...item,
        ReplacementSubsegmentItemId: actions[item.itemId].replacementItemId,
        Action: actionTitles[actions[item.itemId].action],
      })),
    [filteredItems, actions]
  );

  useEffect(() => {
    if (
      validateVDSContentStatus === "success" &&
      Array.isArray(duplicates) &&
      duplicates.length === 0 &&
      shouldSave.current
    ) {
      affectedVDSActionCall();
    }
  }, [affectedVDSActionCall, duplicates, validateVDSContentStatus]);

  const filterConfigs = useMemo<Codelists[]>(
    () =>
      changeType === "properties"
        ? [
            "valve-types",
            "rating-classes",
            "material-groups",
            "end-connections",
            "bores",
            "vds-sizes",
          ]
        : [],
    [changeType]
  );

  const closeModal = useCallback(() => {
    onSettled && onSettled();
    setShowAffectedVDSModal(false);
  }, [onSettled, setShowAffectedVDSModal]);

  return (
    <>
      <ModalWindow
        title={
          changeType === "properties"
            ? `VDS affected by change in Properties of VDS Subsegment ${name}`
            : `VDS affected by Expiry of VDS Subsegment ${name}`
        }
        closeModal={closeModal}
        isOpen={true}
      >
        <ModalSideMargin>
          <ModalWindowContentFill
            style={{
              width: "90vw",
              height: "75vh",
              overflow: "visible" /* reason: select outline" */,
            }}
            ref={containerRef}
          >
            <Space.Fill>
              <Space.Fill>
                <Space.Fill>
                  <Table
                    controlHeader={
                      <EdsProvider density="comfortable">
                        <div style={{ padding: "12px 0" }}>
                          <FlexContainer>
                            <FlexElement>
                              <FlexContainer gap={16}>
                                {filterConfigs.map((filterConfig) => (
                                  <FlexElement key={filterConfig}>
                                    <Filter
                                      items={items}
                                      filtersDispatch={filtersDispatch}
                                      codelist={filterConfig}
                                    />
                                  </FlexElement>
                                ))}
                              </FlexContainer>
                            </FlexElement>
                            <FlexElement>
                              <ExtendedDownloads
                                columns={
                                  changeType === "properties"
                                    ? vdsListColumnsForPropertiesChange
                                    : vdsListColumnsForReplacement
                                }
                                items={itemsWithActions}
                                filteredItems={filteredItemsWithActions}
                                filename={`Subsegment-${name}-${revision}-Affected-VDS-Actions`}
                              />
                            </FlexElement>
                          </FlexContainer>
                        </div>
                      </EdsProvider>
                    }
                    codelistsToQuery={filterConfigs}
                    columns={
                      changeType === "properties"
                        ? vdsListColumnsForPropertiesChange
                        : vdsListColumnsForReplacement
                    }
                    items={filteredItems}
                    itemIdProp="itemId"
                    density="compact"
                    selection={selection}
                    selectionDispatch={selectionDispatch}
                    selectionMode={selectionMode}
                    infoline={true}
                    totalCount={items.length}
                    contextData={{
                      actions,
                      setActions,
                      subsegmentSubstitutionList:
                        subsegmentSubstitutesListRef.current,
                    }}
                    infoLineExtraInfo={[`Needs action: ${needsActionCount}`]}
                    resizable
                    sortable
                    fullRowSelect
                    footer={
                      <EdsProvider density="comfortable">
                        <div
                          style={{
                            width: "100%",
                            flexGrow: 1,
                            alignSelf: "flex-start",
                          }}
                        >
                          <FlexContainer gap>
                            <SimpleButtonContainer
                              style={{ alignItems: "center" }}
                            >
                              <div>Set Action for selected:</div>
                              <Button
                                variant="outlined"
                                disabled={
                                  selection.length === 0 ||
                                  substituteSelection.length === 0
                                }
                                onClick={() => {
                                  sheetListRef.current = selection.map((e) => ({
                                    VDS: getItemName(e),
                                    Revision: getItemRev(e),
                                    ReplaceWithSubSegmentID: getItemName(
                                      substituteSelection[0]
                                    ),
                                    ReplaceWithSubsegmentRevision: getItemRev(
                                      substituteSelection[0]
                                    ),
                                  }));
                                  validateReplacement({
                                    name,
                                    revision,
                                    content: {
                                      sheets: sheetListRef.current,
                                    },
                                  });
                                }}
                              >
                                Replace With Subsegment
                              </Button>
                              <Button
                                variant="outlined"
                                disabled={selection.length === 0}
                                onClick={() => {
                                  setActions({
                                    ...actions,
                                    ...Object.fromEntries(
                                      selection.map((selected) => [
                                        selected,
                                        {
                                          action: "delete" as ActionTypes,
                                          replacementItemId: "",
                                        },
                                      ])
                                    ),
                                  });
                                  selectionDispatch({
                                    type: "deselect",
                                    payload: selection,
                                  });
                                }}
                              >
                                Delete Subsegment
                              </Button>
                              <Button
                                variant="outlined"
                                disabled={selection.length === 0}
                                onClick={() => {
                                  setActions({
                                    ...actions,
                                    ...Object.fromEntries(
                                      selection.map((selected) => [
                                        selected,
                                        {
                                          action:
                                            "remove_filter" as ActionTypes,
                                          replacementItemId: "",
                                        },
                                      ])
                                    ),
                                  });
                                  selectionDispatch({
                                    type: "deselect",
                                    payload: selection,
                                  });
                                }}
                              >
                                Remove Filter
                              </Button>
                              <Button
                                variant="outlined"
                                disabled={selection.length === 0}
                                color="danger"
                                onClick={() => {
                                  setActions({
                                    ...actions,
                                    ...Object.fromEntries(
                                      selection.map((selected) => [
                                        selected,
                                        {
                                          action: "none" as ActionTypes,
                                          replacementItemId: "",
                                        },
                                      ])
                                    ),
                                  });
                                  selectionDispatch({
                                    type: "deselect",
                                    payload: selection,
                                  });
                                }}
                              >
                                Revert Action
                              </Button>
                            </SimpleButtonContainer>
                            <SimpleButtonContainer>
                              <Button
                                variant="outlined"
                                onClick={() => {
                                  selectionDispatch({
                                    type: "reset",
                                    payload: Object.keys(actions)
                                      .filter(
                                        (itemId) =>
                                          actions[itemId].action === "none"
                                      )
                                      .filter((e) =>
                                        filteredItems
                                          .map((e) => e.itemId)
                                          .includes(e)
                                      ),
                                  });
                                }}
                              >
                                Select Items in Need of Action
                              </Button>
                            </SimpleButtonContainer>
                          </FlexContainer>
                        </div>
                      </EdsProvider>
                    }
                  />
                </Space.Fill>
                <Space.BottomResizable size={containerSize.height * 0.33}>
                  <Table
                    columns={subsegmentListColumns}
                    items={isRefSet ? subsegmentSubstitutesListRef.current : []}
                    itemIdProp="itemId"
                    density="compact"
                    selection={substituteSelection}
                    selectionDispatch={substituteSelectionDispatch}
                    selectionMode={substituteSelectionMode}
                    status={
                      !isRefSet && subsegmentSubstitutesStatus === "success"
                        ? "loading"
                        : subsegmentSubstitutesStatus
                    }
                    error={subsegmentSubstitutesError}
                    resizable
                    sortable
                    fullRowSelect
                  />
                </Space.BottomResizable>
              </Space.Fill>
              {paneModes.affectedVDSModal && (
                <Space.RightResizable size={sheetDocumentWidth}>
                  <SheetPane location="affectedVDSModal" />
                </Space.RightResizable>
              )}
            </Space.Fill>
          </ModalWindowContentFill>
        </ModalSideMargin>

        <ModalWindowButtonContainer>
          <Button
            variant="outlined"
            disabled={hasActionCount === 0}
            onClick={() => {
              shouldSave.current = false;
              validateVDSContent(validateVDSContentProps);
            }}
          >
            Validate
          </Button>
          <Button
            disabled={needsActionCount > 0}
            onClick={() => {
              shouldSave.current = true;
              validateVDSContent(validateVDSContentProps);
            }}
          >
            Submit
          </Button>
          <Button variant="outlined" onClick={() => closeModal()}>
            Cancel
          </Button>
        </ModalWindowButtonContainer>
      </ModalWindow>
      <StatusModal
        status={validateReplacementStatus}
        error={validateReplacementError}
        onSettledClose={() => {
          validateReplacementReset();
        }}
        successMessage={successMessage}
        successTitle=""
      />
      <StatusModal
        status={validateVDSContentStatus}
        error={validateVDSContentError}
        onSettledClose={() => {
          validateVDSContentReset();
        }}
        successTitle={
          validateVDSContentData &&
          validateVDSContentData?.data.getVDSDuplicates.length > 0
            ? "Invalid"
            : undefined
        }
        successMessage={validateVDSContentMessage}
        onSuccess={() => {
          if (
            shouldSave.current &&
            Array.isArray(duplicates) &&
            duplicates.length === 0
          ) {
            validateVDSContentReset();
          }
        }}
      />
      <StatusModal
        status={affectedVDSActionStatus}
        error={affectedVDSActionError}
        onSettledClose={() => {
          affectedVDSActionReset();
        }}
        onSuccess={() => {
          setAffectedProgress("ready-to-save");
          setShowAffectedVDSModal(false);
        }}
      />
    </>
  );
}
