import { Button, Icon } from "@equinor/eds-core-react";
import { gps_fixed } from "@equinor/eds-icons";
import { getStatusChips } from "components/Components";
import { getServerSideFilters } from "components/table/Filters";
import { useTable } from "components/table/useTable";
import { idWidth } from "features/sheets/config/sheetConfig";
import {
  StructuredPipeElementCodelistItem,
  useCodelist,
} from "queries/useCodelist";
import { useEffect, useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { UsageInPCS } from "./UsageInPCS";
import { ColumnsProps } from "components/table/Table";

const columns: ColumnsProps<StructuredPipeElementCodelistItem>[] = [
  { key: "MaterialGroup", title: "Material Group" },
  { key: "ElementGroup", title: "Element Group" },
  { key: "ElementID", title: "Element ID", width: idWidth },
  { key: "Revision", title: "Revision" },
  { key: "RevDate", title: "Rev. Date", longTitle: "Revision Date" },
  { key: "Status", title: "Status" },
  { key: "LastUpdate", title: "Last Update" },
  { key: "LastUpdateBy", title: "Last Update By" },
  { key: "Priority", title: "Priority" },
  {
    key: "DimStandard",
    title: "Dim. Standard",
    longTitle: "Dimension Standard",
  },
  { key: "ProductForm", title: "Product Form" },
  { key: "MaterialGrade", title: "Material Grade" },
  { key: "MDS", title: "MDS", width: 105 },
  { key: "MDSRevision", title: "MDS Rev.", longTitle: "MDS Revision" },
  { key: "Note", title: "Note", width: 80 },
];

function RowMenu({ item }: { item: StructuredPipeElementCodelistItem }) {
  const history = useHistory();
  return (
    <Button
      variant="ghost_icon"
      onClick={() => {
        history.push({ search: `?item=${item.ElementID}` });
      }}
      title="Usage in PCS"
    >
      <Icon data={gps_fixed} />
    </Button>
  );
}

export function StructuredPipeElementsList() {
  const [extraParams, setExtraParams] = useState({});
  const query = useCodelist({
    codelist: "pmg/structured-pipe-elements",
    extraParams,
    enabled: Object.keys(extraParams).length > 0,
  });
  const { data } = query;

  const { content, filtersDispatch, filters } = useTable({
    query,
    columns: columns as ColumnsProps<unknown>[],
    title: "Structured Pipe Elements",
    itemIdProp: "ElementID",
    nameProperty: "ElementID",
    nameFilterPlaceholder: "Filter IDs",
    filterGroupProperties: [
      { col: 1, group: "Selections" },
      { col: 2, group: "Statuses" },
    ],
    RowMenu,
  });

  useEffect(() => setExtraParams(getServerSideFilters(filters)), [filters]);

  const [materialGroupFilter, setMaterialGroupFilter] = useState("");
  const [elementGroupFilter, setElementGroupFilter] = useState("");
  const [priorityFilter, setPriorityFilter] = useState("");
  const [statusFilter, setStatusFilter] = useState(["O", "W"]);

  const { data: materialGroupsData } = useCodelist({
    codelist: "material-groups",
  });
  const { data: pipeElementGroupsData } = useCodelist({
    codelist: "pipe-element-groups",
  });

  const materialGroupOptions = useMemo(
    () =>
      materialGroupsData?.map((e) => ({
        key: String(e.MaterialGroupID),
        title: e.MaterialGroup,
      })),
    [materialGroupsData]
  );

  const pipeElementGroupOptions = useMemo(
    () =>
      pipeElementGroupsData?.map((e) => ({
        key: String(e.ElementGroupID),
        title: e.ElementGroup,
      })),
    [pipeElementGroupsData]
  );

  const priorityOptions = useMemo(
    () => Array.from(new Set(data?.map((e) => String(e.Priority)).sort())),
    [data]
  );

  useEffect(() => {
    filtersDispatch({
      type: "update",
      payload: {
        type: "select",
        title: "Material Group",
        prop: "MaterialGroup",
        filterState: materialGroupFilter,
        setFilterState: setMaterialGroupFilter,
        filterOptions: materialGroupOptions,
        serverSide: true,
        apiFilterPorp: "MaterialGroupID",
        group: "Selections",
      },
    });
  }, [filtersDispatch, materialGroupFilter, materialGroupOptions]);

  useEffect(() => {
    filtersDispatch({
      type: "update",
      payload: {
        type: "select",
        title: "Element Group",
        prop: "ElementGroup",
        filterState: elementGroupFilter,
        setFilterState: setElementGroupFilter,
        filterOptions: pipeElementGroupOptions,
        serverSide: true,
        apiFilterPorp: "ElementGroupID",
        group: "Selections",
      },
    });
  }, [elementGroupFilter, filtersDispatch, pipeElementGroupOptions]);

  useEffect(() => {
    filtersDispatch({
      type: "update",
      payload: {
        type: "select",
        title: "Priority",
        prop: "Priority",
        filterState: priorityFilter,
        setFilterState: setPriorityFilter,
        filterOptions: priorityOptions,
        group: "Selections",
      },
    });
  }, [filtersDispatch, priorityFilter, priorityOptions]);

  useEffect(() => {
    filtersDispatch({
      type: "update",
      payload: {
        type: "checkboxes",
        title: "Statuses",
        prop: "Status",
        filterOptions: getStatusChips(["O", "W", "R", "E", "D"]),
        filterState: statusFilter,
        defaultFilterState: ["O", "W"],
        setFilterState: setStatusFilter,
        commaSeparated: false,
        group: "Statuses",
        isArray: true,
        apiFilterPorp: "statusFilter",
        serverSide: true,
      },
    });
  }, [filtersDispatch, statusFilter]);

  const { search } = useLocation();
  const parsedSearch = useMemo(() => new URLSearchParams(search), [search]);
  const item = Number(parsedSearch.get("item")) ?? 0;

  return (
    <>
      {content}
      <UsageInPCS speId={item} />
    </>
  );
}
