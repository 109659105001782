import { MessageModal } from "components/MessageModal";
import StatusModal from "components/StatusModal";
import useVDSSubsegmentPropertiesCommand, {
  AffectedVDSByPropertiesChangeItem,
} from "features/sheets/queries/useVDSSubsegmentPropertiesCommand";
import { useCallback, useEffect, useRef, useState } from "react";
import { SubmitHandler } from "react-hook-form";
import { successMessage } from "utils/successMessage";
import { Nullable } from "utils/types";
import {
  FormVDSSubsegmentProperties,
  SaveVDSSubsegmentProperties,
  VDSSubsegment,
} from "../../types";
import { AffectedProgress, AffectedVDS } from "./AffectedVDS";

const processVDSSubsegmentPropertiesData = (
  formData: FormVDSSubsegmentProperties,
  data: Partial<VDSSubsegment>
): SaveVDSSubsegmentProperties => ({
  IncludeCharProperty: formData.VDSCharacteristicProperty.split(",")
    .filter((c) =>
      !data.getVDSSubsegmentCharInherited
        ? ""
        : !data.getVDSSubsegmentCharInherited
            .map((i) => String(i.CharacteristicID))
            .includes(c)
    )
    .join(","),
  ExcludeCharProperty: !data.getVDSSubsegmentCharInherited
    ? ""
    : data.getVDSSubsegmentCharInherited
        .map((i) => String(i.CharacteristicID))
        .filter(
          (i) => !formData.VDSCharacteristicProperty.split(",").includes(i)
        )
        .join(","),
  ...formData,
});

export function useAttemptSaveVDSSubsegementProperties({
  data,
  name,
  revision,
  onSuccess,
  onlyError,
}: {
  data: VDSSubsegment | undefined;
  name: string | undefined;
  revision: string | undefined;
  onSuccess?: () => void;
  onlyError?: boolean;
}): {
  onSubmit: SubmitHandler<FormVDSSubsegmentProperties>;
  attemptSaveVDSSubsegmentContent: React.ReactFragment;
  vdsSubsegmentPropertiesReset: () => void;
  affectedProgress: AffectedProgress;
} {
  const [showAlertModal, setShowAlertModal] = useState(false);
  const [showAffectedVDSModal, setShowAffectedVDSModal] = useState(false);
  const [affectedProgress, setAffectedProgress] =
    useState<AffectedProgress>("idle");

  const {
    mutate: vdsSubsegmentAffectedVDS,
    status: vdsSubsegmentAffectedVDSStatus,
    error: vdsSubsegmentAffectedVDSError,
    data: vdsSubsegmentAffectedVDSData,
    reset: vdsSubsegmentAffectedVDSReset,
  } = useVDSSubsegmentPropertiesCommand({ command: "affected-vds" });

  const {
    mutate: vdsSubsegmentProperties,
    status: vdsSubsegmentPropertiesStatus,
    error: vdsSubsegmentPropertiesError,
    data: vdsSubsegmentPropertiesData,
    reset: vdsSubsegmentPropertiesReset,
  } = useVDSSubsegmentPropertiesCommand({ command: "properties" });

  const submitData = useRef({} as SaveVDSSubsegmentProperties);
  const affectedVDSList = useRef([] as AffectedVDSByPropertiesChangeItem[]);

  const onSubmit: SubmitHandler<Nullable<FormVDSSubsegmentProperties>> =
    useCallback(
      (formData) => {
        if (data && name && revision) {
          if (
            Object.keys(formData)
              .map((e) => formData[e as keyof typeof formData])
              .includes("-")
          ) {
            setShowAlertModal(true);
          } else {
            setAffectedProgress("processing");
            submitData.current = processVDSSubsegmentPropertiesData(
              formData as FormVDSSubsegmentProperties,
              data
            );
            vdsSubsegmentAffectedVDS({
              name,
              revision,
              content: submitData.current,
            });
          }
        }
      },
      [data, name, revision, vdsSubsegmentAffectedVDS]
    );

  useEffect(() => {
    if (vdsSubsegmentAffectedVDSStatus === "success" && name && revision) {
      if (vdsSubsegmentAffectedVDSData.data.getVDSAffected.length === 0) {
        vdsSubsegmentProperties({
          name,
          revision,
          content: submitData.current,
        });
      } else {
        setAffectedProgress("waiting-for-user");
        setShowAffectedVDSModal(true);
        affectedVDSList.current =
          vdsSubsegmentAffectedVDSData.data.getVDSAffected;
      }
    }
    // This should only be triggered when the affecetedVDSStatus is changed, so other dependencies are not needed.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vdsSubsegmentAffectedVDSStatus]);

  useEffect(() => {
    if (affectedProgress === "ready-to-save" && name && revision) {
      vdsSubsegmentAffectedVDS({ name, revision, content: submitData.current });
    }
  }, [
    affectedProgress,
    name,
    revision,
    showAffectedVDSModal,
    vdsSubsegmentAffectedVDS,
  ]);

  useEffect(() => {
    if (vdsSubsegmentPropertiesStatus === "success") {
      setAffectedProgress("done");
    }
  }, [vdsSubsegmentPropertiesStatus]);

  const attemptSaveVDSSubsegmentContent = (
    <>
      <MessageModal
        isOpen={showAlertModal}
        onClose={() => setShowAlertModal(false)}
        title="Modify selection"
        shouldCloseOnOverlayClick={true}
      >
        Either All/None or at least one element should be selected.
      </MessageModal>
      {showAffectedVDSModal && data && name && revision && (
        <AffectedVDS
          name={name}
          revision={revision}
          changeType="properties"
          affectedVDS={affectedVDSList.current}
          setShowAffectedVDSModal={setShowAffectedVDSModal}
          properties={submitData.current}
          setAffectedProgress={setAffectedProgress}
        />
      )}
      <StatusModal
        status={vdsSubsegmentAffectedVDSStatus}
        error={vdsSubsegmentAffectedVDSError}
        onSuccess={vdsSubsegmentAffectedVDSReset}
        onErrorClose={() => {
          vdsSubsegmentAffectedVDSReset();
          setAffectedProgress("idle");
        }}
        onlyError={onlyError}
      />
      <StatusModal
        status={vdsSubsegmentPropertiesStatus}
        error={vdsSubsegmentPropertiesError}
        onSettledClose={vdsSubsegmentPropertiesReset}
        successMessage={successMessage(vdsSubsegmentPropertiesData)}
        onSuccess={onSuccess}
        onlyError={onlyError}
      />
    </>
  );
  return {
    onSubmit,
    attemptSaveVDSSubsegmentContent,
    vdsSubsegmentPropertiesReset,
    affectedProgress,
  };
}
